import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const creditApi = createApi({
  reducerPath: "creditApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin/",
  }),
  tagTypes: ["Credit"],
  endpoints: (builder) => ({
    getCredit: builder.query<any, any>({
      query: (params) => `credit?${new URLSearchParams(params)}`,
    }),
  }),
});

export const { useGetCreditQuery } = creditApi;
