import { runtimeEnv } from "@/lib/runtime-utils";
import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AutomationProps } from "@/types/type";

export const automationApi = createApi({
  reducerPath: "automationApi",
  refetchOnFocus: false,
  
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/automation`,
  }),
  tagTypes: ["automation"],
  
  endpoints: (builder) => ({
    createProject: builder.mutation<RestApiResponse<any>, AutomationProps>({
      query: (body) => ({
        url: "/create",
        method: "POST",
        body,
      }),
    }),
    getTemplatePost: builder.mutation<RestApiResponse<any>, { category: string, automation?: boolean, screenType?: string, orientation?: string, language?: string, useMyAvatar?: string }>({
      query: ({ category, automation, screenType, orientation, language, useMyAvatar }) => ({
        url: `/templates`,
        method: 'POST',
        body: { category, automation, screenType, orientation, language, useMyAvatar }
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return {
            success: false,
            error: res.error,
          };
        }
        return {
          success: true,
          data: res.data,
        };
      },
    }),
    createAutomationLog: builder.mutation<RestApiResponse<any>, ({
      projectId: string;
      eventName: string;
      [key: string]: any;
    })> ({
      query: (body) => ({
        url: '/automation-log',
        method: 'POST',
        body
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    createSeriesProject: builder.mutation<RestApiResponse<any>, AutomationProps>({
      query: (body) => ({
        url: "/create_series",
        method: "POST",
        body,
      }),
    }),
    tiktokAuthentication : builder.mutation ({
      query: () => ({
        url: "/tiktok/authenticate",
        method: "GET",
      }),
    }),
    tiktokDisconnect : builder.mutation ({
      query: (body) => ({
        url: "/tiktok/disconnect",
        method: "POST",
        body
      }),
    }),
    tiktokGetToken : builder.mutation<RestApiResponse<any>, any>({
      query: (body) => ({
        url: "/tiktok/get-token",
        method: "POST",
        body
      }),
    }),
    tiktokCreatorInfo : builder.mutation<RestApiResponse<any>, any>({
      query: (body) => ({
        url: "/tiktok/creator-info",
        method: "POST",
        body
      }),
    }),
    tiktokPost : builder.mutation<RestApiResponse<any>, any>({
      query: (body) => ({
        url: "/tiktok/direct-post",
        method: "POST",
        body
      }),
    }),
    tiktokGetPostStatus : builder.mutation<RestApiResponse<any>, any>({
      query: (body) => ({
        url: "/tiktok/get-post-status",
        method: "POST",
        body
      }),
    })
  }),
});

export const {
  useCreateProjectMutation,
  useGetTemplatePostMutation,
  useCreateAutomationLogMutation,
  useCreateSeriesProjectMutation,
  useTiktokAuthenticationMutation,
  useTiktokDisconnectMutation,
  useTiktokGetTokenMutation,
  useTiktokCreatorInfoMutation,
  useTiktokPostMutation,
  useTiktokGetPostStatusMutation
} = automationApi;

export default automationApi;
