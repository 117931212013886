import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const workspaceApi = createApi({
  reducerPath: "workspaceApi",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({ baseUrl: "/api/workspace" }),
  tagTypes: ["workspace"],
  endpoints: (build) => ({
    setUserVideoPermission: build.mutation({
      query: (body) => ({
        url: "/user-project-share-permission",
        method: "POST",
        body,
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),

    getUserVideoPermission: build.query<
      RestApiResponse<any>,
      {
        projectId: string;
        workspaceId: string;
        companyId?: string;
        isPrivate?: string;
      }
    >({
      query: ({ projectId, workspaceId, companyId, isPrivate }) =>
        `/user-project-share-permission?projectId=${projectId}&workspaceId=${workspaceId}&companyId=${companyId}&isPrivate=${isPrivate}`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    setProjectPublic: build.mutation({
      query: (body) => ({
        url: "/set-project-public",
        method: "POST",
        body,
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    setInviteVideoSharePermission: build.mutation({
      query: (body) => ({
        url: "/invite-video-share-permission",
        method: "POST",
        body,
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),

    // 모든사용자 권한 리스트가져옴
    getInviteVideoSharePermission: build.query<
      RestApiResponse<any>,
      {
        projectId: string;
        workspaceId: string;
        companyId?: string;
        isPrivate?: string;
      }
    >({
      query: ({ projectId, workspaceId, companyId, isPrivate }) =>
        `/invite-video-share-permission?projectId=${projectId}&workspaceId=${workspaceId}&companyId=${companyId}&isPrivate=${isPrivate}`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    // 사용자한명 권한
    getUserSharePermission: build.query<
      RestApiResponse<any>,
      { projectId: string; userId: string }
    >({
      query: ({ projectId, userId }) =>
        `/user-share-permission?projectId=${projectId}&userId=${userId}`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data.data;
      },
    }),
  }),
});

export const {
  useSetUserVideoPermissionMutation,
  useGetUserVideoPermissionQuery,
  useSetProjectPublicMutation,
  useSetInviteVideoSharePermissionMutation,
  useGetInviteVideoSharePermissionQuery,
  useGetUserSharePermissionQuery
} = workspaceApi;
export default workspaceApi;
