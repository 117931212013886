import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const modelApi = createApi({
  reducerPath: "modelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/common/",
  }),
  tagTypes: ["Aimodel"],
  endpoints: (builder) => ({
    getAiModel: builder.query<any, any>({
      query: (params) => {
        return { url: `aimodel?${new URLSearchParams(params)}` };
      },
      providesTags: ["Aimodel"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const { useGetAiModelQuery } = modelApi;
