import { Clip } from "@/types/type";
import { Clothes, Model } from "@/types/models";

// 프로젝트 내용중 base64 데이터를 제거한다.
export function removeBase64InProject(project: any) {
  const data = {
    ...project,
    thumbnailUrl: null,
    scenes: project?.scenes.map((scene: any) => {
      if (!scene) return scene;
      const retData = {
        ...scene,
        clips: scene.clips.map((clip: any) => {
          if (clip.type === "aiModel") {
            let pjt = { ...clip };
            if (clip.headOnly)
              pjt = { ...pjt, headOnly: { ...clip.headOnly, src: null } };
            if (pjt.beforHeadOnly)
              pjt = {
                ...pjt,
                beforHeadOnly: { ...clip.beforHeadOnly, src: null },
              };
            if (pjt?.src) pjt = { ...pjt, src: null };
            if (pjt?.thumbnailUrl) delete pjt.thumbnailUrl;
            return pjt;
          }
          return clip;
        }),
        thumbnailUrl: null,
      };
      return retData;
    }),
  };
  return data;
}

export function getAIAvatarPosition(props: {
  clip: Clip;
  avatar: Model & Clothes;
  canvasWidth: number;
  canvasHeight: number;
  avatarType: "original" | undefined;
}): Clip {
  const { clip, avatar, canvasWidth, canvasHeight, avatarType } = props;
  const { imageWidth, imageHeight, sourceUrl } = (() => {
    return {
      imageWidth: avatar.deploySize.edit_width,
      imageHeight: avatar.deploySize.edit_height,
      sourceUrl: avatar.deployImage.edit_src,
    }
  })();
  const scale = (() => {
    const x = (() => {
      if (canvasWidth < clip.width * (clip.scaleX || 1)) {
        return canvasWidth / imageWidth;
      }
      return clip.width * (clip.scaleX || 1) / imageWidth;
    })();
    const y = (() => {
      if (canvasHeight - clip.top < clip.height * (clip.scaleY || 1)) {
        return (canvasHeight - clip.top) / imageHeight;
      }
      return clip.height * (clip.scaleY || 1) / imageHeight;
    })();

    if (x > y) {
      return x;
    }
    return y;
  })();

  const left = (() => {
    return (
      clip.left
      + (clip.width * (clip.scaleX || 1) / 2)
      - (imageWidth * scale / 2)
    );
  })();

  return {
    ...clip,
    avatarType,
    width: imageWidth,
    height: imageHeight,
    scaleX: scale,
    scaleY: scale,
    left,
    model: {
      ...clip.model,
      source_url: sourceUrl,
    },
  };
}