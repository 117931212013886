import {
  createApi,
  fetchBaseQuery,
  defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/query/react";
import { runtimeEnv } from "@/lib/runtime-utils";
import { HYDRATE } from "next-redux-wrapper";
import type { RestApiResponse } from "@/types/common.api";
import type {
  GetAvatarsReq,
  GetAvatarsRes,
  TemplatePostData,
  AccountUserInfoType,
} from "@/types/dashboard.api";
import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import type { QueryArgs } from "@/types/common.api";

export type GetTemplateResItem = {
  _id: string;
  name: string;
  type: "custom";
  category: string;
  projectId: string;
  screenType: "web" | "mobile";
  display_index: number;
  createdAt: string;
  thumbnailUrl: string;
  download_url: string;
};
export type GetTemplateResCategory = {
  value: string;
  label: string;
};
type GetTemplateRes = {
  templates: GetTemplateResItem[];
  categoryList: GetTemplateResCategory[];
  chatgptTemplates: (GetTemplateResItem & { category: "chatgpt" })[];
};

export const dashboardApi = createApi({
  reducerPath: "dashboard_data",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/dashboard`,
  }),
  tagTypes: ['folder', 'myProject', 'exportHistory', 'videoSet'],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (build) => ({
    getStartTemplate: build.query<RestApiResponse<any>, {isMobile: boolean}> ({
      query: ({isMobile = false}) => {
        return `/startTemplates?isMobile=${isMobile}`
      },
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getTemplate: build.query<GetTemplateRes, { category: string | undefined }> ({
      query: ({ category }) => {
        const queryString = category ? `?category=${category}` : '';
        return `/templates${queryString}`;
      },
      transformResponse: async (res: RestApiResponse<GetTemplateRes>) => {
        if (!res.success || !res.data) {
          return {
            templates: [],
            categoryList: [],
            chatgptTemplates: [],
          };
        }
        return res.data;
      },
    }),    
    deleteTemplate: build.mutation<RestApiResponse<any>, {templateId: string}>({
      query: ({ templateId }) => ({
        url: `/templates-delete`,
        method: 'POST',
        body: { templateId }
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getTemplatePost: build.mutation<RestApiResponse<any>, {perPage: number, currentPage: number, category: string, automation?: boolean, screenType?: string, orientation?: string, language?: string, aiType?: string}>({
      query: ({perPage, currentPage, category, automation, screenType, orientation, language, aiType}) => ({
        url:  `/templates`,
        method: 'POST',
        body: {perPage, currentPage, category, automation, screenType, orientation, language, aiType}
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return {
            success: false,
            error: res.error,
          };
        }
        return {
          success: true,
          data: res.data,
        };
      },
    }),
    getTemplateProjectPost: build.mutation<RestApiResponse<{ template: TemplatePostData }>, { id: string }>({
      query: ({ id }) => ({
        url: `/templateProject`,
        method: 'POST',
        body: { id }
      }),
    }),
    getTemplateParentProjectPost: build.mutation<RestApiResponse<any>, { id: string }>({
      query: ({ id }) => ({
        url: `/templateProject`,
        method: 'POST',
        body: { id }
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return {
            success: false,
            error: res.error,
          };
        }
        return {
          success: true,
          data: res.data,
        };
      },
    }),
    getMyProject: build.query<RestApiResponse<any>, { page: number, searchVal?: string, workspaceId: string, isPrivate?: string, recentProject?: string }>({
      // query: ({page}) => `/project?page=${page}`,
      query: ({ page, searchVal, workspaceId, isPrivate, recentProject }) => ({
        url: `/project?page=${page}&search=${searchVal}&workspaceId=${workspaceId}&isPrivate=${isPrivate}&recentProject=${recentProject}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{type: 'myProject'}],
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getProjectInfo: build.query<any, {projectId: string | string[] | undefined}> ({
      query: ({projectId}) =>  `/projectInfo?projectId=${projectId}`,
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getFolder: build.query<RestApiResponse<any>, void> ({
      query: () => ({url: `/folder/folder`}),
      providesTags: (result, error, arg) => {
        return [{type: 'folder'}]
      }
    }),
    folderCreateAndUpdate: build.mutation<any, {folderName: string, modalType: string, folderDetail?: any, workspaceId?: string}> ({
      query: ({folderName, modalType, folderDetail, workspaceId}) => ({
        url: `/folder/folder`,
        method: 'POST',
        body: {folderName, modalType, folderDetail, workspaceId}
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
      invalidatesTags: (result, error, arg) => {
        // result ? [{type: 'folder'}] : []
        return [{type: 'folder'}]
      }
    }),
    deleteFolder: build.mutation<any, {folderName: string, isDelete: boolean}> ({
      query: ({folderName, isDelete}) => ({
        url: `/folder/folder`,
        method: 'POST',
        body: {folderName, isDelete}
      }),
      invalidatesTags: (result, error, arg) => result ? [{type: 'folder'}] : []
    }),
    moveProjectToFolder: build.mutation<any, {selectedFolder: string, selectedProjectId: string} >({
      query: ({selectedFolder, selectedProjectId}) => ({
        url: '/folder/moveFolders',
        method: 'POST',
        body: {selectedFolder, selectedProjectId}
      }),
    invalidatesTags: (result, error, arg) => result ? [{type: 'folder'}, {type: 'myProject'}] : []
    }),
    getFolderInfo: build.mutation<any, {folderId: string}>({
      query: ({folderId}) => ({
        url: '/folder/getFolderInfo',
        method: 'POST',
        body: {folderId}
      }),
      invalidatesTags: (result, error, arg) => result ? [{type: 'folder'}] : []
    }),
    duplicateProject: build.mutation<any, {projectId: string, workspaceId?: string, baseInfoId?: string}> ({
      query: ({ projectId, workspaceId, baseInfoId }) => ({
        url: '/duplicate-project',
        method: 'POST',
        body: {projectId, workspaceId, baseInfoId}
      }),
      invalidatesTags: (result, error, arg) => result ? [{type: 'myProject'}, {type: 'folder'}] : []
    }),
    deleteProject: build.mutation<any, {projectId: any}> ({
      query: ({ projectId }) => ({
        url: '/delete-project',
        method: 'POST',
        body: { projectId }
      }),
      invalidatesTags: (result) => result ? [{type: 'myProject'}, {type: 'folder'}]: []
    }),
    exportHistory: build.query<any, {currentPage:number, perPage: number, workspaceId: string, searchText: string}> ({
      query: ({currentPage, perPage, workspaceId, searchText}) => ({
        url: `/exportHistory?currentPage=${currentPage}&perPage=${perPage}&workspaceId=${workspaceId}&searchText=${searchText}`
      }),
      providesTags: () => [{ type : "exportHistory" }],
    }),
    editWebLinkTargetUrl: build.mutation<RestApiResponse<void>, { projectId: string; path: string; }> ({
      query: (body) => ({
        url: "/webLinkTargetUrl",
        method: "POST",
        body,
      }),
      invalidatesTags: () => [{ type : "exportHistory" }],
    }),
    videoSet: build.query<any, {currentPage:number, perPage: number, workspaceMember: string, id: string, searchText: string}> ({
      query: ({currentPage, perPage, workspaceMember, id, searchText}) => ({
        url: `/videoSet?currentPage=${currentPage}&perPage=${perPage}&id=${id}&searchText=${searchText}&${workspaceMember}`
      }),
      providesTags: () => [{ type : "videoSet" }],
    }),
    getAvatars: build.query<GetAvatarsRes, (QueryArgs & GetAvatarsReq | void)>({
      query: (queryArgs?: QueryArgs & GetAvatarsReq) => {
        const query: FetchArgs = {
          url: "/avatars",
          method: "GET",
        };

        if (!queryArgs) {
          return query;
        }

        const { headers, ...args } = queryArgs;

        if (headers) {
          query.headers = headers;
        }
        if (Object.keys(args).length > 0) {
          query.url = `${query.url}?${new URLSearchParams(args).toString()}`
        }
 
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition
        })
      },
      transformResponse: async(res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    uploadPPT: build.mutation<RestApiResponse<any>, File[]>({
      query(files) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row);
        })
        return {
          url: '/uploads',
          method: "POST",
          body: formData,
          formData: true,
        }
      }
    }),
    uploadAutomation: build.mutation<RestApiResponse<any>, File[]>({
      query(files) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row);
        })
        return {
          url: '/uploads-automation',
          method: "POST",
          body: formData,
          formData: true,
        }
      }
    }),
    uploadPublicAutomation: build.mutation<RestApiResponse<any>, File[]>({
      query(files) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row);
        })
        return {
          url: '/uploads-public-automation',
          method: "POST",
          body: formData,
          formData: true,
        }
      }
    }),
    makePPTProject: build.mutation<any, {pptName: any, pptSource: string, src: string, workspaceId?: string}> ({
      query: ({pptName, pptSource, src, workspaceId}) => ({
        url: '/makePPTProject',
        method: 'POST',
        body: { pptName, pptSource, src, workspaceId }
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    setCreateChatGPTLog: build.mutation<any, {log: chatGPTLog}> ({
      query: ({log}) => ({
        url: '/chatGPTLog',
        method: 'POST',
        body: { log }
      }),
      transformResponse: async(res: RestApiResponse<any>) => {
        if(!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    exportTemplate: build.mutation<RestApiResponse<void>, { templateId: string; thumbnail?: string }>({
      query: (data) => {
        return {
          url: `/exportTemplate`,
          method: "POST",
          body: data,
        };
      },
    }),
    deployTemplate: build.mutation({
      query: (data) => {
        return {
          url: `/deployTemplate`,
          method: "POST",
          body: data,
        };
      },
    }),
    getUserInfo: build.mutation({
      query: () => {
        return {
          url: `/userInfo`,
          method: 'POST',
        }
      }
    }),
    getAccountUserInfo: build.query<AccountUserInfoType | null, QueryArgs | void>({
      query: (args?: QueryArgs) => {
        const query: FetchArgs = {
          url: "/userInfo",
          method: "GET",
        };
        if (args?.headers) {
          query.headers = args.headers;
        }
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition
        })
      },
      transformResponse: async(res: RestApiResponse<AccountUserInfoType | null>) => {
        if (!res.success || !res.data) {
          return null;
        }
        return res.data;
      },
    }),
    createBaseInfo: build.mutation<any, void>({
      query: () => ({
        url: "/create-baseInfo",
        method: "POST",
        body: {}
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  })
});

export const { 
  useGetStartTemplateQuery, 
  useGetTemplateQuery, 
  useDeleteTemplateMutation,
  useGetTemplatePostMutation,
  useGetTemplateProjectPostMutation,
  useGetTemplateParentProjectPostMutation,
  useGetMyProjectQuery, 
  useGetFolderQuery, 
  useFolderCreateAndUpdateMutation, 
  useGetFolderInfoMutation,
  useMoveProjectToFolderMutation,
  useDuplicateProjectMutation, 
  useDeleteProjectMutation, 
  useGetProjectInfoQuery, 
  useExportHistoryQuery,
  useEditWebLinkTargetUrlMutation,
  useVideoSetQuery,
  useGetAvatarsQuery,
  useMakePPTProjectMutation,
  useSetCreateChatGPTLogMutation,
  useUploadPPTMutation,
  useUploadAutomationMutation,
  useUploadPublicAutomationMutation,
  useExportTemplateMutation,
  useDeployTemplateMutation,
  useGetUserInfoMutation,
  useGetAccountUserInfoQuery,
  useCreateBaseInfoMutation,
  // useExportHistoryQuery,
  util: { getRunningQueriesThunk },
} = dashboardApi;
export default dashboardApi;

export const { getAvatars, getAccountUserInfo } = dashboardApi.endpoints;