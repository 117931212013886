import { Clip, CreateMaskImageProps, CroppingImageProps, RemoveCropButtonProps } from "@/types/type";
import { Canvas } from "fabric/fabric-impl";
import { fabric } from 'fabric';
import axios from "axios";
import { insertObject } from "./canvas-utils";

export function removeCropButton({
  canvas,
}: RemoveCropButtonProps) {
  const cropButton = canvas.getObjects().find((object) => object.type === 'crop-button');
  const cancelButton = canvas.getObjects().find((object) => object.type === 'cancel-button');
  console.log('>>>>> removeCropButton object ', {cropButton}, {cancelButton})
  if(!cropButton || !cancelButton) return;
  canvas.remove(cropButton);
  canvas.remove(cancelButton);

  setTimeout(() => {
    canvas.renderAll();
  })
}

/**
 * Crop Image 위해 mask image 생성  
 */
export function createMaskImage({
  canvas, 
  image,
  id,
}: CreateMaskImageProps) {
  // 1. addSelectionRect
  const currentImage = canvas.getObjects().filter((object) => object.name === image.id)[0];

  currentImage.set('lockMovementX', true);
  currentImage.set('lockMovementY', true);
  currentImage.set('lockRotation', true);
  currentImage.set('lockScalingX', true);
  currentImage.set('lockScalingY', true);
  currentImage.set('lockSkewingX', true);
  currentImage.set('lockSkewingY', true);
  currentImage.set('lockUniScaling', true);

  const selectionRect = new fabric.Rect({
    type: 'media',
    name: "crop" + id,
    fill: "rgba(0,0,0,0.3)",
    originX: "left",
    originY: "top",
    opacity: 1,
    width: currentImage.getScaledWidth(),
    height: currentImage.getScaledHeight(),
    hasRotatingPoint: false,
    transparentCorners: true,
    borderColor: "black",
    padding: 0,
    borderScaleFactor: 1,
    left: currentImage.left || 0, // Set left to 0
    top: currentImage.top,  // Set top to 0
    selectable: true, 
  });

  const buttonWidth = 200;
  const gap = 10;
  const startX = selectionRect.left + (selectionRect.width - buttonWidth + gap) / 2;

  const cropButton = new fabric.Textbox('Crop', {
    name: id,
    // width: 150,
    width: selectionRect.width * selectionRect.scaleX / 10,
    height: selectionRect.height * selectionRect.scaleY / 10,
    left: startX,
    top: selectionRect.top + 30,
    fontSize: 25,
    backgroundColor: '#ffffff',
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    lockUniScaling: true,
    textAlign: 'center',
    originX: 'center',
    originY: 'center',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 16,
    fill: '#222222',
    strokeWidth: 2,
    editable: false,
    selectable: true, 
    type: 'crop-button',
    cropImageId: image.id,
    padding: 5,
    hasControls: false, // Disable controls
    hasBorders: false, // Disable borders
    hoverCursor: 'pointer',
  });

  const cancelButton = new fabric.Textbox('Cancel', {
    name: id,
    width: selectionRect.width * selectionRect.scaleX / 10,
    height: selectionRect.height * selectionRect.scaleY / 10,
    left: startX + buttonWidth + gap,
    top: selectionRect.top + 30,
    fontSize: 25,
    backgroundColor: '#ffffff',
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    lockUniScaling: true,
    textAlign: 'center',
    originX: 'center',
    originY: 'center',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 16,
    fill: '#222222',
    strokeWidth: 2,
    editable: false,
    selectable: true, 
    type: 'cancel-button',
    cropImageId: image.id,
    padding: 5,
    hasControls: false, // Disable controls
    hasBorders: false, // Disable borders
    hoverCursor: 'pointer',
  })
  canvas.add(selectionRect);
  canvas.add(cancelButton);
  canvas.add(cropButton);
  canvas.bringToFront(cancelButton);
  canvas.bringToFront(cropButton);

  setTimeout(() => {
    canvas.setActiveObject(selectionRect);
    canvas.renderAll();
  });
}

export function setBoundaries(canvas: Canvas, rectImage: Object, targetImage: Object) {
  const selectionRect = canvas.getObjects().find((object) => object.name === rectImage.name);
  const originImage = canvas.getObjects().find((object) => object.name === targetImage.name);
  if(!selectionRect || !originImage) return;
  const selectionRectBoundaries = selectionRect.getBoundingRect();
  const originImageBoundaries = originImage.getBoundingRect();

  // const zoom = (originImage.width / originImageBoundaries.width) * 1920 / canvas.getWidth() ;
  // const zoom = (originImageBoundaries.width * canvas.getWidth()) / 1920 / originImage.getScaledWidth();
  const zoom = canvas.getWidth() / 1920;

  console.log('>>>> setBoundaries ', originImage.left, {selectionRectBoundaries}, {originImageBoundaries}, {zoom}, {originImage}, canvas.getWidth(), canvas.getZoom())
  if (selectionRectBoundaries.left < originImageBoundaries.left) {
    selectionRect.set({ left: originImage.left  });
  }

  if (selectionRectBoundaries.top < originImageBoundaries.top) {
    selectionRect.set({ top: originImage.top });
  }

  if(selectionRectBoundaries.left + selectionRectBoundaries.width > originImageBoundaries.width + originImageBoundaries.left) {
    selectionRect.set({ left: (originImageBoundaries.width + originImage.left) - selectionRectBoundaries.width })
  }

  if(selectionRectBoundaries.top + selectionRectBoundaries.height > originImageBoundaries.height + originImageBoundaries.top) {
    console.log('>>>> setBoundaries bottom top');
    selectionRect.set({ top: originImage.top + originImageBoundaries.height - selectionRectBoundaries.height })
  }

  // if (selectionRectBoundaries.left > (originImageBoundaries.width + originImageBoundaries.left)) {
  //   console.log('>>>> setBoundaries 22222 ', {selectionRectBoundaries}, {originImageBoundaries}, {zoom} , 
  //   {width: (originImageBoundaries.width + originImageBoundaries.left - selectionRectBoundaries.width)})
  //   selectionRect.set({ left: (originImage.width + originImage.left - selectionRect.width) });
  // }

  // if (selectionRectBoundaries.left + selectionRectBoundaries.width > originImageBoundaries.left + originImageBoundaries.width) {
  //   selectionRect.set({ left: (originImageBoundaries.left + originImageBoundaries.width - selectionRectBoundaries.width) });
  // }

  // if (selectionRectBoundaries.top + selectionRectBoundaries.height > selectionRectBoundaries.top + selectionRectBoundaries.height) {
  //   selectionRect.set({ top: selectionRectBoundaries.top + selectionRectBoundaries.height - selectionRectBoundaries.height });
  // }

  // canvas.renderAll();
}

async function uploadImage(base64Img: string) {
  try {
    const formData = new FormData();
    const blob = await fetch(base64Img).then((res) => res.blob());
    formData.append('image', blob, `image_${new Date()}.png`);

    const res = await axios.post('/api/project/assets/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if(res.data && res.data.success) {
      return { data: res.data?.data[0]?.src || ''}
    } else {
      return { data: null }
    }

  } catch(e) {
    console.log('>>>> uploadImage error ', {e});
    return { data: null }
  }
}


/**
 * 자르고자 하는 이미지 영역과 자르고 난 후 이미지 url 얻어오기 
 */
async function getCropUrl({ originImage, selectionRect, source_url }: any) {
  const originImageInfo = originImage.getBoundingRect();

  const timestamp: number = Date.now();
  const random: number = Math.floor(Math.random() * 1000);
  const id = `image-${timestamp.toString(32)}${random.toString(32)}`;

  const scaleX = originImage.scaleX || 1;
  const scaleY = originImage.scaleY || 1;
  const scaleX2 = selectionRect.scaleX || 1;
  const scaleY2 = selectionRect.scaleY || 1;

  const thumbnailCanvas = new fabric.StaticCanvas(null, { backgroundColor: 'transparent'});
  thumbnailCanvas.setWidth(originImage.width);
  thumbnailCanvas.setHeight( originImage.height);

  const getImageInfo = (src: string) => new Promise<fabric.Image>((resolve) => {
    fabric.Image.fromURL(src, (image: any) => {
      image.crossOrigin = "Anonymous";
      resolve(image);
    },
    { crossOrigin: "anonymous" });
  });
  const image = await getImageInfo(source_url);
  image.name = id;

  const clipPath = new fabric.Rect({
    // ...selectionRect,
    // width: originImageInfo.width / scaleX * scaleX2,
    // height: originImageInfo.height / scaleY * scaleY2,

    width: originImage.getScaledWidth() / scaleX * scaleX2,
    height: originImage.getScaledHeight() / scaleY * scaleY2,
    top: (selectionRect.top - originImage.top) / scaleY,
    left: (selectionRect.left - originImage.left) / scaleX,
    absolutePositioned: true,
    fill: 'transparent',
  })

  // image.scaleX = scaleX;
  // image.scaleY = scaleY;
  // image.width = originImageInfo.width / scaleX * scaleX_2;
  // image.height = originImageInfo.height / scaleY * scaleY_2;
  // image.top = selectionRect.top / scaleY_2;
  // image.left = selectionRect.left / scaleX_2;
  // image.top = (selectionRect.top - ( 1 - scaleY_2 ) * selectionRect.top ) / scaleY_2;
  // image.left = (selectionRect.left - ( 1 - scaleX_2 ) * selectionRect.left) / scaleX_2;

  thumbnailCanvas.add(image);
  thumbnailCanvas.clipPath = clipPath;
  image.clipPath = clipPath;

  console.log("!@#!@#!@# new ", image);
  thumbnailCanvas.renderAll();

  let data = '';

  fabric.Image.fromURL(originImage.src, (_image) => {
    // 선택 영역 생성
    const clipPath2 = new fabric.Rect({
      width: originImage.getScaledWidth()  / scaleX * scaleX2,
      height: originImage.getScaledHeight() / scaleY * scaleY2,
      top: (selectionRect.top - originImage.top) / scaleY,
      left: (selectionRect.left - originImage.left) / scaleX,
      absolutePositioned: true,
      fill: 'transparent',
    });
  
    // 이미지를 선택 영역으로 자르기
    _image.clipPath = clipPath2;
  
    // 자른 부분을 다시 캔버스에 추가
    thumbnailCanvas.add(_image);
  
    // 렌더링
    thumbnailCanvas.renderAll();
  
    // 썸네일 이미지 생성
    data = thumbnailCanvas.toDataURL();
    // 캔버스 해제
  }, { crossOrigin: 'anonymous' });
  

  console.log("!@#!@#!@# data ", {data});

  thumbnailCanvas.dispose();

  const cropCanvas  = new fabric.StaticCanvas(null, { backgroundColor: 'transparent'});

  cropCanvas.setWidth(originImage.getScaledWidth() / scaleX * scaleX2);
  cropCanvas.setHeight(originImage.getScaledHeight() / scaleY * scaleY2);

  const cropImage = await getImageInfo(data);
  cropImage.top = -(selectionRect.top - originImage.top) / scaleY;
  cropImage.left = -(selectionRect.left - originImage.left) / scaleX;


  console.log("!@#!@#!@# cropImage ", {cropImage},);
  console.log("!@#!@#!@# cropCanvas ", {cropCanvas},);

  cropCanvas.add(cropImage);
  cropCanvas.renderAll();
  data = cropCanvas.toDataURL();
  console.log("!@#!@#!@# cropCanvas ", {data});
  cropCanvas.dispose();
  const uploadUrl = await uploadImage(data);
  if(uploadUrl && uploadUrl.data) {
    return uploadUrl.data;
  } else {
    return data;
  }
  // return data;
}

/**
 * canvas : 실제 화면에서 보여지는 canvas
 * imageName: 크롭하고자 하는 이미지 정보를 가져오기 위한 name
 * source_url: 새롭게 크롭하고자 하는 이미지를 그리기 위한 image src url
 * key: crop, cancel 2개로 나눠짐 
 */
export async function setCropImage({canvas, imageName, source_url, key}: CroppingImageProps) {
  const selectionRect = canvas.getObjects().find((object) => object.name?.startsWith('crop'));
  const originImage = canvas.getObjects().find((object) => object.name === imageName);  

  if(!originImage || !key) return;

  if(key === 'crop') {
    /**
     * Thumbnail 그리기 위한 캔버스 
     */
    const cropUrl = await getCropUrl({ originImage, selectionRect, source_url });
    console.log('>>>>> cropImage setCropImage cropUrl ', {cropUrl});
    /**
     * Thumbnail 얻고 나면 실제 crop 하고자 하는 영역을 보여주는 부분은 canvas에서 지운다.
     * 원래 있던 이미지도 삭제한다 
     */
    canvas.remove(selectionRect);
    canvas.remove(originImage);

    setTimeout(() => {
      canvas.renderAll();
    });

    return { cropUrl, selectionRect, originImage };

  } else {
    originImage.lockMovementX = false;
    originImage.lockMovementY = false;
    originImage.lockRotation = false;
    originImage.lockScalingX = false;
    originImage.lockScalingY = false;
    originImage.lockSkewingX = false;
    originImage.lockSkewingY = false;
    originImage.lockUniScaling = false;

    originImage.moveCursor = undefined;
    originImage.hoverCursor = undefined;
    
    originImage.opacity = 1;

    canvas.remove(selectionRect);
    setTimeout(() => {
      canvas.setActiveObject(originImage);
      canvas.renderAll();
    });
  }

}

export async function addNewCropImage(newObj: Clip, canvas: Canvas) {
  await insertObject({
    ...newObj,
    canvas,
    id: newObj.id,
    type: newObj.type,
    evented: true,
  });
} 