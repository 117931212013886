/**
 * 상수를 정의한다.
 */

export const VIDEO_WIDTH = 1920;
export const VIDEO_HEIGHT = 1080;

export const ERR_CODE_9999 = "9999";
export const ERR_CODE_1100 = "1100";
export const ERR_CODE_1101 = "1101";
export const ERR_CODE_1102 = "1102";
export const ERR_CODE_1103 = "1103";
export const ERR_CODE_2000 = "2000";
export const ERR_CODE_2001 = "2001";
export const ERR_CODE_2002 = "2002";
export const ERR_CODE_2003 = "2003";
export const ERR_CODE_2004 = "2004";
export const ERR_CODE_3000 = "3000";
export const ERR_CODE_3001 = "3001";
export const ERR_CODE_3010 = "3010";
export const ERR_CODE_4000 = "4000";
export const ERR_CODE_4001 = "4001";
export const ERR_CODE_4002 = "4002";
export const ERR_CODE_4003 = "4003";
export const ERR_CODE_4004 = "4004";
export const ERR_CODE_5000 = "5000";

export const ERR_MSG:Record<string, {ko: string, en: string, zh: string}> = {
  [ERR_CODE_9999] : { ko : "알수 없는 오류가 발생하였습니다.", en: "Unknown Err.", zh: "Unknown Err."},
  [ERR_CODE_1100] : { ko : "호출정보가 누락되었습니다.", en: "Invalide argments", zh: "Invalide argments"},
  [ERR_CODE_1101] : { ko : "지원되지 않는 Method 입니다.", en: "Invalide method.", zh: "Invalide method."},
  [ERR_CODE_1102] : { ko : "존재하지 않은 데이터 처리 요청입니다.", en: "Invalide method.", zh: "Invalide method."},
  [ERR_CODE_1103] : { ko : "입력 파라메터 오류.", en: "Invalide method.", zh: "Invalide method."},
  [ERR_CODE_2000] : { ko : "존재하지 않은 사용자 입니다.", en: "Not found user.", zh: "Not found user."},
  [ERR_CODE_2001] : { ko : "패스워드가 올바르지 않습니다.", en: "Invalide password.", zh: "Invalide password."},
  [ERR_CODE_2002] : { ko : "로그인 후 사용가능 합니다. 로그인 후 이용하시기 바랍니다.", en: "You didn't login.", zh: "You didn't login."},
  [ERR_CODE_2003] : { ko : "로그인 후 이용이 가능합니다.", en: "You didn't login.", zh: "You didn't login."},
  [ERR_CODE_2004] : { ko : "권한이 부족하여 이용이 불가합니다. 관리자에게 문의하시기 바랍니다.", en: "You didn't login.", zh: "You didn't login."},
  [ERR_CODE_3000] : { ko : "데이터베이스 처리 중 오류가 발생하였습니다.", en: "You didn't login.", zh: "You didn't login."},
  [ERR_CODE_3001] : { ko : "외부 시스템 연동에 실패하였습닏.", en: "You didn't login.", zh: "You didn't login."},
  [ERR_CODE_3010] : { ko : "전처리 시스템 연동에 실패하였습니다.", en: "You didn't login.", zh: "You didn't login."},
  [ERR_CODE_4000] : { ko : "크레딧이 부족합니다.", en: "Not enough credits.", zh: "Not enough credits."},
  [ERR_CODE_4001] : { ko : "priceInfo 정보가 없습니다.", en: "missing priceInfo.", zh: "missing priceInfo."},
  [ERR_CODE_4002] : { ko : "productInfo 정보가 없습니다.", en: "missing productInfo.", zh: "missing productInfo"},
  [ERR_CODE_4003] : { ko : "낮은 구독 단계로 내릴 수 없습니다.", en: "can't downgrade subscription.", zh: "can't downgrade subscription."},
  [ERR_CODE_4004] : { ko : "구독 권한이 부족합니다.", en: "can't downgrade subscription.", zh: "can't downgrade subscription."},
  [ERR_CODE_5000] : { ko : "외부 연동에 실패하였습니다.", en: "can't downgrade subscription.", zh: "can't downgrade subscription."},
};