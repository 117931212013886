import { useMemo, useCallback } from "react";
import { getCookie } from "cookies-next";
import { useTranslation } from "next-i18next";
import { disassembleHangul as dh } from "es-hangul";
import { VoiceCloneProvider } from "@/constants/constants";

function getId(voice: Tts, t: (str: string) => string) {
  if (!voice.id) {
    return "";
  }

  return voice.id.toLowerCase()
    .replace("Female".toLowerCase(), "")
    .replace("Male".toLowerCase(), "")
    .replace(t("Female").toLowerCase(), "")
    .replace(t("Male").toLowerCase(), "");
}

function getType(voice: Tts) {
  try {
    if (!voice.type) {
      throw new Error();
    }
    if (VoiceCloneProvider.includes(voice.type)) {
      return "voice cloning";
    }
    return voice.type.toLowerCase();
  } catch (e) {
    return "";
  }
}

function getGender(voice: Tts, capital?: boolean) {
  try {
    if (!voice.gender) {
      throw new Error();
    }
    if (!capital) {
      return voice.gender.toLowerCase();
    }
    return [...voice.gender]
      .map((string, index) => {
        if (index === 0) {
          return string.toUpperCase();
        }
        return string.toLowerCase();
      })
      .join("");
  } catch (e) {
    return "";
  }
}

function getTextIntl(
  voice: Tts,
  localeCode: string,
  t: (str: string) => string,
) {
  try {
    if (!voice.text_intl[localeCode]) {
      throw new Error();
    }
    return voice.text_intl[localeCode].toLowerCase()
      .replace("Female".toLowerCase(), "")
      .replace("Male".toLowerCase(), "")
      .replace(t("Female").toLowerCase(), "")
      .replace(t("Male").toLowerCase(), "");
  } catch (e) {
    return "";
  }
}

function getIntl(
  intlList: Intl.DisplayNames | null,
  code: string,
  type: "language" | "region"
) {
  try {
    if (!intlList || !code) {
      throw new Error();
    }
    const intlText = (() => {
      if (type === "language") {
        return intlList.of(code.split("-")[0].toLowerCase());
      }
      if (type === "region") {
        return intlList.of(code.split("-")[1].toUpperCase());
      }
      return null;
    })();
    if (!intlText) {
      throw new Error();
    }
    return intlText.toLowerCase();
  } catch (e) {
    return "";
  }
}

function getEnglishPriority(code: string) {
  const list = ["en-us", "en-gb", "en-au", "en-in"];
  const index = list.findIndex((item) => item === code.toLowerCase());

  if (index === -1) {
    return list.length;
  }
  return index;
}

export const sort = {
  locale: (a: Tts, b: Tts, locale: string) => {
    if (
      a.language !== b.language &&
      a.language?.split("-")[0].toLowerCase() === locale
    ) {
      return -1;
    }
    return 0;
  },
  language: (a: Tts, b: Tts) => {
    if (a.language === b.language) {
      return -1;
    }
    return 0;
  },
  englishPriority: (a: Tts, b: Tts) => {
    if (
      a.language?.split("-")[0].toLowerCase() === "en" &&
      b.language?.split("-")[0].toLowerCase() === "en"
    ) {
      return getEnglishPriority(a.language) - getEnglishPriority(b.language);
    }
    return 0;
  },
  gender: (a: Tts, b: Tts, gender: string) => {
    if (
      a.language === b.language &&
      a.gender !== b.gender &&
      a.gender === gender
    ) {
      return -1;
    }
    return 0;
  },
};

export function useFilterVoices(props: { list: Tts[] }) {
  const { list } = props;
  const { t } = useTranslation("common");
  const locale = (getCookie("NEXT_LOCALE") || "en") as string;

  const engLanguageNames = useMemo(
    () => new Intl.DisplayNames(["en"], { type: "language" }),
    []
  );
  const localeLanguageNames = useMemo(
    () =>
      locale === "en"
        ? null
        : new Intl.DisplayNames([locale], { type: "language" }),
    [locale]
  );
  const engRegionNames = useMemo(
    () => new Intl.DisplayNames(["en"], { type: "region" }),
    []
  );
  const localeRegionNames = useMemo(
    () =>
      locale === "en"
        ? null
        : new Intl.DisplayNames([locale], { type: "region" }),
    [locale]
  );

  const searchData = useMemo(() => {
    return list.map((voice) => ({
      id: voice.id.normalize("NFC"),
      searchId: getId(voice, t).normalize("NFC"),
      type: getType(voice).normalize("NFC"),
      localeType: dh(t(getType(voice)).normalize("NFC")),
      gender: getGender(voice).normalize("NFC"),
      localeGender: dh(t(getGender(voice, true)).normalize("NFC")),
      name: getTextIntl(voice, "en", t).normalize("NFC"),
      localeName: dh(getTextIntl(voice, locale, t).normalize("NFC")),
      language: getIntl(engLanguageNames, voice.language, "language").normalize("NFC"),
      localeLanguage: dh(
        getIntl(localeLanguageNames, voice.language, "language").normalize("NFC")
      ),
      region: getIntl(engRegionNames, voice.language, "region").normalize("NFC"),
      localeRegion: dh(getIntl(localeRegionNames, voice.language, "region").normalize("NFC")),
    }));
  }, [
    engRegionNames,
    engLanguageNames,
    list,
    locale,
    localeRegionNames,
    localeLanguageNames,
    t,
  ]);

  const filter = useCallback((keyword: string) => {
    const search = dh(keyword.toLowerCase());
    const resultList = searchData
      .filter((item) => (
        Object.entries(item).some(([key, value]) => {
          if (key !== "id") {
            if (key === "gender" || key === "localeGender") {
              return value.startsWith(search);
            }
            return value.includes(search);
          }
          return false;
        })
      ))
      .map((item) => item.id);

      const result = list.filter((voice) => {
        if (!voice) {
          return false;
        }
        return resultList.includes(voice.id);
      });

      return result;
    },
    [list, searchData]
  );

  const filterVoices = useCallback(
    (keyword: string) => {
      if (keyword.length === 0) {
        return list;
      }

      return filter(keyword);
    },
    [filter, list]
  );

  return { filterVoices };
}
