import { runtimeEnv } from "@/lib/runtime-utils";
import {
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import type { RestApiResponse } from "@/types/common.api";
import { VideoMessagesTemplate, ProjectCreateParam } from "@/types/videomessages";
import type { Model } from "@/types/models";

export const videomessagesApi = createApi({
  reducerPath: "videomessagesApi",
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/videomessages`,
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    // template 목록을 조회한다.
    getTemplates: builder.query<VideoMessagesTemplate[], void>({
      query: () => "/templates",
      transformResponse: async (res: RestApiResponse<{
        templates: VideoMessagesTemplate[]
      }>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data.templates;
      }
    }),
    // default template 목록을 조회한다.
    getDefaultTemplates: builder.query<VideoMessagesTemplate[], void>({
      query: () => "/templates?type=default",
      transformResponse: async (res: RestApiResponse<{
        templates: VideoMessagesTemplate[]
      }>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data.templates;
      }
    }),
    createProject: builder.mutation<RestApiResponse<any>, ProjectCreateParam>({
      query: (body) => ({
        url: "/createProject",
        method: "POST",
        body,
      }),
    }),
    getDefaultAvatars: builder.query<Model[], void>({
      query: () => "/defaultAvatars",
      transformResponse: async (res: RestApiResponse<{
        models: Model[]
      }>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data.models;
      }
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetDefaultTemplatesQuery,
  useCreateProjectMutation,
  useGetDefaultAvatarsQuery,
  util: { getRunningQueriesThunk },
} = videomessagesApi;

// export const {} = videomessagesApi.endpoints;