import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RestApiResponse } from '@/types/common.api'

type VideoDownloadJob = {
  jobId: string
  videoTitle: string
  publicUrl: string
  originalFilename: string
}

type VideoDownloadResult = {
  jobId: string
  videoTitle: string
  thumbnailUrl: string
  /**
   * units: seconds
   */
  duration: number
}

export const videoDownloaderApi = createApi({
  reducerPath: 'tools/videoDownloader',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/tools/video-downloader',
  }),
  endpoints: (build) => ({
    getJob: build.query({
      query: (jobId: string) => `/jobs/${jobId}`,
      transformResponse: (res: RestApiResponse<VideoDownloadJob>) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch video download job')
        }

        return res.data
      },
    }),
    requestDownload: build.mutation<VideoDownloadResult, { url: string }>({
      query: ({ url }) => ({
        url: '/download',
        method: 'POST',
        body: {
          url,
        },
      }),
      transformResponse: (res: RestApiResponse<VideoDownloadResult>) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to download video')
        }

        return res.data
      },
    }),
  }),
})
