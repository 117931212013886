import { runtimeEnv } from '../lib/runtime-utils';
import memoize from "lodash.memoize";
import axios from 'axios';

const getSVGPath = memoize(async (fileName: string) => {
    try {
        // const response = await fetch(`${runtimeEnv.BK_STUDIO_URL}/api/utils/readFile`, {method: "POST",headers: { "Content-Type": "application/json" }, body: JSON.stringify({fileName}),})
        // const temp = await response.text();
        // const res = await JSON.parse(temp);
        const response = await axios.post(`${runtimeEnv.BK_STUDIO_URL}/api/utils/readFile`, { fileName });
        const res = response.data;

        if(res.success !== true) {
            return new Error("error reading file", {cause: res.msg})
        } else {
            return res.path;
        }
        
    } catch(e) {
        return new Error("error extracting svg path", {cause: e})
    }
})
 
export async function getSVGPathDefinition(
    fileName: string
): Promise<string> {
    try {
        const definitionRegex = /d="([^"]*)"/;
    
        const res = await getSVGPath(fileName);
       
        if(res instanceof Error) {
            console.error(res);
            return "";
        } else {
            const match = res.match(definitionRegex);
            if(match) {
                return match?.[1];
            } else {
                return "";
            }
        }
    } catch(e) {
        console.log(e)
        return "";
    }

}
  
 
