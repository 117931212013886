import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RestApiResponse } from '@/types/common.api'
import {
  AiImageGeneratorGenerateState,
  ImageSize,
  ImageStyle,
} from '@/constants/tools/ai-image-generator'

type AiImageGeneratorGetJobResponse = {
  topic: string
  imageStyle: string
  imageSize: string
  generatedImage: {
    url: string
    width: number
    height: number
  }
  generateState: AiImageGeneratorGenerateState
  projectId?: string
  downloadedImage?: {
    url: string
    width: number
    height: number
  }
  error?: string
}

type AiImageGeneratorCreateJobParams = {
  topic: string
  imageStyle: ImageStyle
  imageSize: ImageSize
  generatedImage: {
    url: string
    width: number
    height: number
  }
}

type AiImageGeneratorCreateJobResponse = {
  jobId: string
}

type AiImageGeneratorUpdateStateJobParams = {
  jobId: string
  state: AiImageGeneratorGenerateState
  projectId?: string
  downloadedImage?: {
    url: string
    width: number
    height: number
  }
  error?: string
}

type AiImageGeneratorUpdateStateJobResponse = void

export const aiImageGeneratorApi = createApi({
  reducerPath: 'tools/aiImageGenerator',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/tools/ai-image-generator',
  }),
  endpoints: (build) => ({
    getJob: build.query<AiImageGeneratorGetJobResponse, string>({
      query: (jobId) => `/jobs/${jobId}`,
      transformResponse: (
        res: RestApiResponse<AiImageGeneratorGetJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch AI image job')
        }

        return res.data
      },
    }),

    createJob: build.mutation<
      AiImageGeneratorCreateJobResponse,
      AiImageGeneratorCreateJobParams
    >({
      query: ({ topic, imageStyle, imageSize, generatedImage }) => ({
        url: '/jobs',
        method: 'POST',
        body: {
          topic,
          imageStyle,
          imageSize,
          generatedImage,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiImageGeneratorCreateJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to create AI image job')
        }

        return res.data
      },
    }),

    updateStateJob: build.mutation<
      AiImageGeneratorUpdateStateJobResponse,
      AiImageGeneratorUpdateStateJobParams
    >({
      query: ({ jobId, state, projectId, downloadedImage, error }) => ({
        url: `/jobs/${jobId}`,
        method: 'PUT',
        body: {
          state,
          projectId,
          downloadedImage,
          error,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiImageGeneratorUpdateStateJobResponse>,
      ) => {
        if (!res.success) {
          throw new Error('Failed to update AI image job')
        }

        return res.data
      },
    }),
  }),
})
