import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'

import { RestApiResponse } from '@/types/common.api'
import { AiScriptGeneratorGenerateState } from '@/constants/tools/ai-script-generator'

type AiScriptGeneratorGenerateScriptResponse = {
  topic: string
  script: string
}

type AiScriptGeneratorCreateJobParams = {
  topic: string
  script: string
}

type AiScriptGeneratorCreateJobResponse = {
  jobId: string
}

type AiScriptGeneratorUpdateStateJobParams = {
  jobId: string
  state: AiScriptGeneratorGenerateState
  projectId?: string
  error?: string
}

type AiScriptGeneratorUpdateStateJobResponse = void

export const aiScriptGeneratorApi = createApi({
  reducerPath: 'tools/aiScriptGenerator',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/tools/ai-script-generator',
  }),
  endpoints: (build) => ({
    generateScript: build.query({
      query: (topic: string) => `/scripts?topic=${encodeURIComponent(topic)}`,
      transformResponse: (
        res: RestApiResponse<AiScriptGeneratorGenerateScriptResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch AI script')
        }

        return res.data
      },
    }),

    createJob: build.mutation<
      AiScriptGeneratorCreateJobResponse,
      AiScriptGeneratorCreateJobParams
    >({
      query: ({ topic, script }) => ({
        url: '/jobs',
        method: 'POST',
        body: {
          topic,
          script,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiScriptGeneratorCreateJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to create AI script job')
        }

        return res.data
      },
    }),

    updateStateJob: build.mutation<
      AiScriptGeneratorUpdateStateJobResponse,
      AiScriptGeneratorUpdateStateJobParams
    >({
      query: ({ jobId, state, projectId, error }) => ({
        url: `/jobs/${jobId}`,
        method: 'PUT',
        body: {
          state,
          projectId,
          error,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiScriptGeneratorUpdateStateJobResponse>,
      ) => {
        if (!res.success) {
          throw new Error('Failed to update AI script job')
        }

        return res.data
      },
    }),
  }),
})
