import { ERR_CODE_9999, ERR_MSG } from "./api-constants";

/**
 * API 에러 정의
 * code + msg
 */
export class ApiError extends Error {
  code: string;

  msg: string;
  
  constructor(code: string, msg: string) {
    super(`[${code}] ${msg}`);
    this.code = code;
    this.msg = msg;
  }

  toJson() {
    const code = this.code ? this.code : ERR_CODE_9999;
    const msg = this.msg ? this.msg : ERR_MSG[code];
    return { code, msg };
  }
}