
export const sleep = (time:number) => new Promise<void>((resolve)=>{ setTimeout(()=> resolve(), time)});

export function setData(proxy: any, path: string[], value: any) {
  if (!proxy) return;
  const [first, ...rest] = path;

  if (rest.length > 0) {
    setData(proxy[first], rest, value);
    return;
  }

  proxy[first] = value;
}

export function getData(proxy: any, path: string[]): any {
  if (!proxy) return undefined;
  const [first, ...rest] = path;
  if (rest.length > 0) {
    return getData(proxy[first], rest);
  }
  return proxy[first];
}

export function getListDefault(
  list: any[] | null,
  path: any[],
  defaultValue?: any
): any {
  if (!list) return undefined;
  return (
    list.reduce((acc, cur) => {
      if (acc === undefined) return undefined;
      if (acc === null) return getData(cur, path);
      if (acc !== getData(cur, path)) return undefined;
      return acc;
    }, null) ?? defaultValue
  );
}
