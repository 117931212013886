import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RestApiResponse } from '@/types/common.api'
import { AiVideoGeneratorGenerateState } from '@/constants/tools/ai-video-generator'

type AiVideoGeneratorGetJobResponse = {
  topic: string
  generatedVideo: {
    videoUrl: string
    thumbnailUrl: string
    width: number
    height: number
  }
  generateState: AiVideoGeneratorGenerateState
  projectId?: string
  downloadedVideo?: {
    videoUrl: string
    thumbnailUrl: string
    width: number
    height: number
  }
  error?: string
}

type AiVideoGeneratorCreateJobParams = {
  topic: string
  generatedVideo: {
    videoUrl: string
    thumbnailUrl: string
    width: number
    height: number
  }
}

type AiVideoGeneratorCreateJobResponse = {
  jobId: string
}

type AiVideoGeneratorUpdateStateJobParams = {
  jobId: string
  state: AiVideoGeneratorGenerateState
  projectId?: string
  downloadedVideo?: {
    videoUrl: string
    thumbnailUrl: string
    width: number
    height: number
  }
  error?: string
}

type AiVideoGeneratorUpdateStateJobResponse = void

export const aiVideoGeneratorApi = createApi({
  reducerPath: 'tools/aiVideoGenerator',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/tools/ai-video-generator',
  }),
  endpoints: (build) => ({
    getJob: build.query<AiVideoGeneratorGetJobResponse, string>({
      query: (jobId) => `/jobs/${jobId}`,
      transformResponse: (
        res: RestApiResponse<AiVideoGeneratorGetJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch AI video job')
        }

        return res.data
      },
    }),

    createJob: build.mutation<
      AiVideoGeneratorCreateJobResponse,
      AiVideoGeneratorCreateJobParams
    >({
      query: ({ topic, generatedVideo }) => ({
        url: '/jobs',
        method: 'POST',
        body: {
          topic,
          generatedVideo,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiVideoGeneratorCreateJobResponse>,
      ) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to create AI video job')
        }

        return res.data
      },
    }),

    updateStateJob: build.mutation<
      AiVideoGeneratorUpdateStateJobResponse,
      AiVideoGeneratorUpdateStateJobParams
    >({
      query: ({ jobId, state, projectId, downloadedVideo, error }) => ({
        url: `/jobs/${jobId}`,
        method: 'PUT',
        body: {
          state,
          projectId,
          downloadedVideo,
          error,
        },
      }),
      transformResponse: (
        res: RestApiResponse<AiVideoGeneratorUpdateStateJobResponse>,
      ) => {
        if (!res.success) {
          throw new Error('Failed to update AI video job')
        }

        return res.data
      },
    }),
  }),
})
