import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const periodApi = createApi({
  reducerPath: "periodApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin/analysis",
  }),
  tagTypes: ["Period"],
  endpoints: (builder) => ({
    getPeriod: builder.query<any, any>({
      query: (params) => {
        console.log('searchpa params: ', params)
        return { url: `period?${new URLSearchParams(params)}` };
      },
      providesTags: ["Period"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const { useGetPeriodQuery } = periodApi;
