import { RestApiResponse } from "@/types/common.api";
import { Model } from "@/types/models";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const objectApi = createApi({
  reducerPath: "editor_object",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({ baseUrl: "/api/project" }),
  tagTypes: ["media", "assets", "brandKit"],
  endpoints: (builder) => ({
    // media
    getAllMedia: builder.query<RestApiResponse<EditorAllMedia>, void>({
      query: () => "/media/all",
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    getAudios: builder.query<RestApiResponse<Array<EditorMedia>>, void>({
      query: () => "/media/audios",
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    searchAudios: builder.mutation<
      RestApiResponse<Array<EditorMedia>>,
      EditorMediaParams
    >({
      query: ({ search, searchapi }) => ({
        url: `/media/audios`,
        method: "POST",
        body: { search, searchapi },
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getVideos: builder.query<
      RestApiResponse<Array<EditorMedia>>,
      EditorMediaParams
    >({
      query: (args) => `/media/videos?search=${args.search}`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    searchVideos: builder.query<
      RestApiResponse<Array<EditorMedia>>,
      EditorMediaParams
    >({
      query: ({ search, searchapi }) => ({
        url: `/media/videos`,
        method: 'POST',
        body: { search, searchapi },
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    getImages: builder.query<
      RestApiResponse<Array<EditorMedia>>,
      EditorMediaParams
    >({
      query: (args) => `/media/images?search=${args.search}`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    searchImages: builder.mutation<
      RestApiResponse<Array<EditorMedia>>,
      EditorMediaParams
    >({
      query: ({ search, searchapi }) => ({
        url: `/media/images`,
        method: "POST",
        body: { search, searchapi },
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    uploadMedia: builder.mutation<RestApiResponse<any>, File[]>({
      query(files) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row);
          if(row?.workspaceId) {
            formData.append(`workspaceId`, row.workspaceId);
          }
        });
        return {
          url: "/assets/upload",
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: [{ type: "assets" }],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    uploadScreenRecording: builder.mutation<RestApiResponse<any>, Blob[]>({
      query(files) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row, "screen_recording.webm");
        });
        return {
          url: "/assets/upload",
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: [{ type: "assets" }],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    downloadExtMedia: builder.mutation<
      RestApiResponse<any>,
      { source_type: string; source_url: string; site: string }
    >({
      query(args) {
        console.log("objectApi args", { args });
        return {
          url: "/media/download",
          method: "POST",
          body: args,
        };
      },
    }),
    downloadExtMediaThumbnail: builder.mutation<
      RestApiResponse<any>,
      { source_type: string; source_url: string; site: string; count: number }
    >({
      query(args) {
        console.log("objectApi args", { args });
        return {
          url: "/media/thumbnails",
          method: "POST",
          body: args,
        };
      },
    }),
    getAllAsset: builder.query<EditorAllMedia, void>({
      query: () => "/assets",
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
      providesTags: () => [{ type: "assets" }],
    }),
    deleteAsset: builder.mutation<
      RestApiResponse<any>,
      { type: string; key: string }
    >({
      query(args) {
        console.log("!@#!@#!@# delete args : ", args);
        return {
          url: "/assets",
          method: "POST",
          body: args,
        };
      },
      invalidatesTags: [{ type: "assets" }],
    }),

    // find models
    getModels: builder.query<Model[], { languageCode: string }>({
      query: ({languageCode = "en"}) => `models?language=${languageCode}`,
      transformResponse: async (res: RestApiResponse<Model[]>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getBrandKit: builder.query<RestApiResponse<any>, 
    { 
      workspaceId: string, 
      projectWorkspaceId?: string, 
      projectId?: string,
    }>({
      query: ({ 
        workspaceId = '', 
        projectWorkspaceId = '',
        projectId = '',
      }) => `/brandKit?workspaceId=${workspaceId}&projectWorkspaceId=${projectWorkspaceId}&projectId=${projectId}`,
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
      providesTags: () => [{ type: "assets" }],
    }),
    getBrandFontKit: builder.query<RestApiResponse<any>, void>({
      query: () => "/brandKit/brandKitFont",
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
      providesTags: () => [{ type: "assets" }],
    }),
    uploadBrandKit: builder.mutation<RestApiResponse<any>, {files: File[], workspaceId: string}>({
      query({files, workspaceId}) {
        const formData = new FormData();
        files.map((row, index) => {
          formData.append(`file_${index}`, row);
        });
        formData.append('workspaceId', workspaceId);
        return {
          url: "/brandKit/upload",
          method: "POST",
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: [{ type: "assets" }],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getShutterStockLicensed: builder.mutation<
      RestApiResponse<any>,
      {
        id: string;
        type: string;
        projectId?: string;
        selectedSceneIdx?: number;
      }
    >({
      query: ({ id, type, projectId, selectedSceneIdx }) => ({
        url: "/media/shutterStock/getLicense",
        method: "POST",
        body: { id, type, projectId, selectedSceneIdx },
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getShutterStockProductLicensed: builder.mutation<
      RestApiResponse<any>,
      {
        id: string;
        type: string;
        projectId?: string;
        selectedSceneIdx?: number;
      }
    >({
      query: ({ id, type, projectId, selectedSceneIdx }) => ({
        url: "/media/shutterStock/getProductLicense",
        method: "POST",
        body: { id, type, projectId, selectedSceneIdx },
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getVideoDuration: builder.mutation<RestApiResponse<any>, { url: string }>({
      query: ({ url }) => ({
        url: "/trim/videoDuration",
        method: "POST",
        body: { url },
      }),
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    getPreviewVideoUrl: builder.mutation<RestApiResponse<any>, { src: string }>(
      {
        query: ({ src }) => ({
          url: "/media/shutterStock/previewVideoUrl",
          method: "POST",
          body: { src },
        }),
        transformResponse: async (res: RestApiResponse<any>) => {
          if (!res.success || !res.data) {
            return [];
          }
          return res.data;
        },
      }
    ),
  }),
});



export const {
  useGetAllMediaQuery,
  useGetAudiosQuery,
  useGetVideosQuery,
  useSearchVideosMutation,
  useGetImagesQuery,
  useSearchImagesMutation,
  useUploadMediaMutation,
  useUploadScreenRecordingMutation,
  useDownloadExtMediaMutation,
  useDownloadExtMediaThumbnailMutation,
  useGetAllAssetQuery,
  useDeleteAssetMutation,
  useGetModelsQuery,
  useGetBrandKitQuery,
  useUploadBrandKitMutation,
  useGetBrandFontKitQuery,
  useSearchAudiosMutation,
  useGetShutterStockLicensedMutation,
  useGetShutterStockProductLicensedMutation,
  useGetVideoDurationMutation,
  useGetPreviewVideoUrlMutation,
} = objectApi;

export default objectApi;
