import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const init = (AMPLITUDE_API_KEY: string) => {
  if (typeof AMPLITUDE_API_KEY === "string" && AMPLITUDE_API_KEY !== "") {
    const sessionReplayTracking = sessionReplayPlugin({
      forceSessionTracking: false,
      sampleRate: 0.1,
    });
    amplitude.add(sessionReplayTracking);
    amplitude.init(AMPLITUDE_API_KEY, "", {
      defaultTracking: {
        sessions: true,
      },
    });
    
    // amplitude.init(AMPLITUDE_API_KEY)
  } else {
    console.log("AMPLITUDE_API_KEY error ", AMPLITUDE_API_KEY);
  }
};

const track = async (event: string, props = {}, properties = {}) => {
  try {
    amplitude.track(event, props, properties);
  } catch (error) {
    console.error("Amplitude track error:", error);
  }
};

const revenue = async (payment: any) => {
  try {
    const {
      item_id,
      item_name,
      item_category,
      currency,
      price,
      quantity,
      user_id,
    } = payment;

    const event = new amplitude.Revenue()
      .setProductId(item_id)
      .setPrice(price)
      .setQuantity(quantity);

    amplitude.revenue(event, {
      user_id,
    });
  } catch (error) {}
};

const flush = () => {
  amplitude.flush();
};

const amplitudeClient = {
  init,
  track,
  revenue,
  flush,
};

export default amplitudeClient;
