import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const templatesApi = createApi({
  reducerPath: "templatesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin/",
  }),
  tagTypes: ["Templates"],
  endpoints: (builder) => ({
    getTemplates: builder.query<RestApiResponse<any>, any>({
      query: (params) => {
        return { url: `templates?${new URLSearchParams(params)}` };
      },
      providesTags: ["Templates"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const { useGetTemplatesQuery } = templatesApi;
