import { createSlice } from "@reduxjs/toolkit";
import { NextRouter } from "next/router";

export type MainMenuItem = {
    isSelected: boolean,
    title: string,
    url: string,
}
  
const defMenuList:Array<MainMenuItem>= [
    { title: "Home", isSelected:true, url: "/dashboard" },
    { title: "Templates", isSelected:false, url: "/template" },
    { title: "Avatars", isSelected:false, url: "/avatars" },
    { title: "Voices", isSelected:false, url: "/voices" },
    { title: "Projects", isSelected:false, url: "/projects" },
    { title: "Videos", isSelected:false, url: "/history" },
    // { title: "sample", isSelected:false, url: "/sample" },
]

type SelectMenuWithRouterActionType = {
    payload: NextRouter;
    type: string;
}

const mainMenuSlice = createSlice({
    name: 'mainMenu',
    initialState: defMenuList,
    reducers: {
        selectMenu : (state, action) => {
            state.forEach((row, index)=> {
                row.isSelected = index == action.payload;
            });
        },
        selectMenuWithRouter : (state, action : SelectMenuWithRouterActionType) => {
            state.forEach((row) => {
                row.isSelected = action.payload.asPath.includes(row.url);
            });
        }
    }
});

export const { selectMenu, selectMenuWithRouter } = mainMenuSlice.actions;
export default mainMenuSlice.reducer;