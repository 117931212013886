import {
  createApi,
  fetchBaseQuery,
  defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/query/react";
import { RestApiResponse } from "@/types/common.api";
import { runtimeEnv } from "@/lib/runtime-utils";
import { HYDRATE } from "next-redux-wrapper";
import { sort } from "@/hooks/useFilterVoices";
import { VoiceCloneProvider } from "@/constants/constants";
import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import type { QueryArgs } from "@/types/common.api";

export const backendApi = createApi({
  reducerPath: "backend",
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/backend`,
  }),
  tagTypes: ["Backend"],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getTtsListProject: builder.query({
      query: (args?: QueryArgs & { sort?: string; } | null) => {
        const query: FetchArgs = {
          url: `/ttsList`,
          method: "POST",
          body: {
            apiUrl: "/api/v2/tts/models",
            method: "GET",
            params: { output: "list" },
          },
        };
        if (args?.headers) {
          query.headers = args.headers;
        }
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition
        })
      },
      transformResponse: async (res: RestApiResponse<Tts[]>, meta, args) => {
        if (!res.success || !res.data) {
          return [];
        }

        let result = res.data;

        // 딥브레인 ai / 보이스클론 tts 제외
        result = result.filter((voice: Tts) => (
          voice.type !== "deepbrainai" &&
          !VoiceCloneProvider.includes(voice.type)
        ));

        // 정렬 요청 시 정렬
        if (args?.sort) {
          const locale = args.sort;
          result = result
            .sort((a, b) => sort.locale(a, b, locale))
            .sort((a, b) => sort.language(a, b))
            .sort((a, b) => sort.englishPriority(a, b));
        }

        return result;
      },
    }),
  }),
});

export const {
  useGetTtsListProjectQuery,
  util: { getRunningQueriesThunk },
} = backendApi;

export const { getTtsListProject } = backendApi.endpoints;

export default backendApi;
