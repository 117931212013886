import { Clip, ClipFonts, Scene, Scripts, ProjectType, Caption, LogoType, FontType, BrandKitItemType } from "@/types/type";
/* eslint-disable no-param-reassign */

import {
  addBackground,
  animateObject,
  canvasHolder,
  deleteObjects,
  editObject,
  genId,
  initCanvasScene,
  initCanvasSize,
  insertObject,
  modelChangeIamge,
  sortLayer,
  getCommonBoundingBox,
  discardForAudioObject,
  convertHeadOnlyPoint,
  previewSceneTransition,
  logoImageChange,
  thumbnailScene,
  toggleModelBackground,
  setClipSize,
} from "@/lib/canvas-utils";
import { setData } from "@/lib/proxy-utils";
import { ProjectState } from "@/types/type";
import {
  AnyAction,
  Dispatch,
  PayloadAction,
  createAsyncThunk,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import { MODAL_NAME } from "@/components/editor/stripe/constants";
import { removeBase64InProject } from "@/lib/project-utils";
import { WritableDraft } from "immer/dist/internal";
import {
  addNewCropImage,
  createMaskImage,
  removeCropButton,
  setBoundaries,
  setCropImage,
} from "@/lib/crop-utils";
import axios from "axios";
import { removeByTagName } from "@/lib/text-utils";
import { Script } from "vm";
import { ImageSize, ImageStyle } from "@/constants/tools/ai-image-generator";
import { MyAvatarTypes } from "@/constants/constants";
import { normalizeClipId, normalizeClipLayer } from "@/lib/normalize-utils";

const initialState: ProjectState = {
  loading: false,
  /**
   * 에디터 최초 로딩 시 메인 캔버스의 초기화 여부
   */
  isInitializedCanvas: false,
  project: null,
  history: [],
  selectedSceneIdx: 0,
  editorKey: 0,

  // left
  selectedLeftTab: null,
  LeftSiderCollapsed: true,
  selectedLeftSubTab: null,
  selectedModelTab: "aiAvatar",
  selectedMedia: "All",
  // top
  selectedObjectIdList: null,
  selectedObjectTypeList: [],
  selectedAvatarType: null,

  // modal
  openedModal: "none",
  automationPrevention:{
    openCheckModal: false,
    alertModal: false,
    okText: "Continue to stay",
    cancelText: "Discard",
    alertText: "The video hasn't been completed yet."
  },
  openExitPreventModal: false,
  callbackAfterLogin: false,
  modalTitle: "",
  modalLogoUrl: "",
  noticeTitle: "Unknown Error",
  noticeExplains: [""],
  decryptParams: {},
  buttonText: '',
  currentPlan: "none",
  planStep : {
    personalPlanStep: 0,
    teamPlanStep: 0,
    proStep: 0,
    reco: true
  },
  clientSecret: "",
  checkoutData: {
    productName: "",
    price: "",
    checkoutBillingPeriod: "",
  },
  openDictionaryPopup: false,
  openPausePopupId: null,
  currentDictionaryWordVal: "",
  lastSelection: "",
  // Templates ThumbnailUrl
  selectedTemplates: [],
  //context menu
  showCanvasContextMenu: false,
  clipBoard: {
    selectedSceneIdx: null,
    selectedObjectIdList: null,
  },
  //undo / redo
  undoStack: [],
  redoStack: [],
  detectedLanguage: null,
  subscriptionDataLayer: {
    plan: "personal",
    period: "monthly",
    steps: 0,
  },
  cropImageInfo: {
    targetImage: {},
    rectImage: {},
    crop: false,
    cropImage: {},
    error: null,
  },
  deleteModel: {
    show: false,
  },
  checkClipExist: {
    show: false,
  },
  backgroundAudio: {
    audio: {},
    showModal: false,
  },
  captionActions: {
    mouseOver: false,
    index: 0,
    type: "",
    originalCaptions: [],
    checkCaptionChange: false,
    closeSubtitleAlert: false,
    hideCaptions: false,
    isLoading: false,
    audio: {
      url: "",
      status: "idle",
    },
    preview: {
      selectedCaptionIdx: 0,
    },
  },
  listenButtonAndAudioInfo :[],
  previewButton: {
    isPreviewButtonDisable: false
  },
  // generate:{
  //   selectedTab: "Image",
  //   imageHints:['Dawn unveils a picturesque natural landscape, featuring waterfalls',
  //     'Muted colors envelop a young boy lost in the depths of a jungle',
  //     'Pastel hues create a magical garden with bees and blooming flowers',
  //     'Interior lighting accentuates the cuteness of a little dog',
  //     'A vintage motorcycle poster captures the essence of days gone by',
  //     'Synthwave colors illuminate the sky'
  //   ],
  //   videoHints:['Dawn unveils a picturesque natural landscape, featuring waterfalls',
  //   'Muted colors envelop a young boy lost in the depths of a jungle',
  //   'Pastel hues create a magical garden with bees and blooming flowers',
  //   'Interior lighting accentuates the cuteness of a little dog',
  //   'A vintage motorcycle poster captures the essence of days gone by',
  //   'Synthwave colors illuminate the sky'],
  //   generateImages: [],
  //   generateVideo: {},
  //   from: ""
  // },

  generate: {
    selectedTab: "Image",
    image: {
      isProgress: false,
      progress: 0,
      images: [],
      hints: [
        "Dawn unveils a picturesque natural landscape, featuring waterfalls",
        "Muted colors envelop a young boy lost in the depths of a jungle",
        "Pastel hues create a magical garden with bees and blooming flowers",
      ],
    },
    video: {
      isProgress: false,
      progress: 0,
      hints: [
        "Dawn unveils a picturesque natural landscape, featuring waterfalls",
        "Muted colors envelop a young boy lost in the depths of a jungle",
        "Pastel hues create a magical garden with bees and blooming flowers",
      ],
      videos: {},
    },
  },
  trimAudio: {
    show: false,
    asset: {
      author: "",
      label: "",
      src: "",
      thumbnail: "",
      link: "",
      type: "",
      source_url: "",
    },
    isLoading: false,
    trimAsset: {
      url: "",
      error: "",
      originalDuration: 0,
    },
    from: "",
  },
  trimVideo: {
    active: false,
    target: null,
  },
  defaultFontFamily: [],
  reduxCustomFontList: [],
  checkGenerateBtnState: {
    btnState: 'idle',
  },
  automationModalOpen: false,
  automationInfo: {
    type: "",
    content: ""
  },
  scriptEditorTab: null,
  brandKitList: {
    font: [],
    logo: [],
    modal: {
      open: false,
    }
  }
};

const fetchPreprocessText = createAsyncThunk(
  "project/fetchPreprocessText",
  async ({ value, language, sceneIdx, clipId }: any) => {
    const res = await fetch("/api/project/preprocess_text", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        value,
        language,
      }),
    });
    return { sceneIdx, clipId, data: await res.json() };
  }
);

/**
 * 프로젝트를 업데이트한다.
 */
const fetchUpdateProject = createAsyncThunk(
  "project/fetchUpdateProject",
  async (project: any) => {
    //불필요한 정보 삭제
    const sendData = (() => {
      let newProject = project;
      newProject = removeBase64InProject(newProject);
      newProject = {
        ...newProject,
        scenes: newProject.scenes.map((scene: Scene) => {
          return {
            ...scene,
            scripts: scene.scripts.map((script: Scripts) => {
              return {
                ...script,
                org: script.org.replaceAll("\ufeff", "")
              }
            })
          }
        })
      }
      return newProject;
    })();
    const res = await fetch(`/api/project/${project._id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(sendData),
    });
    return { data: await res.json() };
  }
);

/**
 * 프로젝트를 삭제한다.
 */
const fetchDeleteProject = createAsyncThunk(
  "project/fetchDeleteProject",
  async (project: string | ProjectType) => {
    const projectId = typeof project === "string"
      ? project
      : project._id;
    const res = await fetch(`/api/project/${projectId}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });
    return { data: await res.json() };
  }
);

const addObjectAsync = createAsyncThunk(
  "project/addObjectAsync",
  async (payload: any, { getState, dispatch }) => {
    const { canvas } = canvasHolder;
    const { project, selectedSceneIdx, selectedObjectIdList } = (
      getState() as any
    ).projectReducer;
    const { sceneIdx = selectedSceneIdx } = payload;

    const scene: Scene = project?.scenes[sceneIdx];
    const topLayer = [...scene.clips].sort((a, b) => b.layer - a.layer)[0]
      .layer;

    if (scene) {
      const {
        type,
        id = genId(type, scene.clips),
        tts,
        ...props
      } = payload;
      // console.log("!@#!@#!@# addObjectAsync", {payload})
      if (type === "aiModel") {
        dispatch(projectActions.saveCurrentProjectState(project));
        if (typeof props.avatarType === "undefined") {
          props.avatarType = "original";
        }
      }
      // scene.clips always sorted
      const layer = type !== "audio" ? topLayer + 1 : 0;

      const { left, top, width, height, scaleX, scaleY } = await insertObject({
        ...props,
        canvas,
        id,
        type,
        evented: true,
      });

      if (type === "aiModel") {
        let selectedModelId =
          selectedObjectIdList !== null &&
          selectedObjectIdList.length === 1 &&
          selectedObjectIdList[0].startsWith("aiModel")
            ? scene.clips.find((clip: Clip) => clip.id === selectedObjectIdList[0])?.id
            : scene.clips.find((clip: Clip) => clip.id.startsWith("aiModel"))?.id;
        if(!selectedModelId) selectedModelId = scene.clips.find((clip: Clip) => clip.type === "aiModel")?.id;

        const modelClips = scene.clips.filter((clip: Clip) =>
          clip.id.startsWith("aiModel")
        );
        const newModelObj = {
          clipId: id,
          modelId: props.model.ai_name,
          clothId: props.model.emotion,
          gender: props.model.gender,
          language: props.model.language,
          tts: tts || null,
        };
        // console.log("!@#!@#!@# newModelObj ", newModelObj);
        if (scene.isDualAvatar) {
          //dual Avatar
          if (modelClips.length > 1) {
            dispatch(projectActions.deleteObjectById(selectedModelId));
            dispatch(
              projectActions.changeScriptsByModelChange({
                selectedModelId,
                newModelObj,
              })
            );
          }
          // dispatch(projectActions.objectSelected([{name: id, type}]));
        } else {
          //single Avatar
          if (modelClips.length > 0) {
            dispatch(projectActions.deleteObjectById(selectedModelId));
            dispatch(
              projectActions.changeScriptsByModelChange({
                selectedModelId,
                newModelObj,
              })
            );
            // dispatch(projectActions.objectSelected([{name: id, type}]));
          }
        }
      }

      if (type === "audio") {
        dispatch(projectActions.objectSelected([{ name: id, type }]));
      }

      return {
        sceneIdx,
        id,
        type,
        layer,
        ...props,
        left,
        top,
        width,
        height,
        scaleX,
        scaleY,
      };
    }
  }
);

// const transitionAsync = createAsyncThunk(
//   "project/transitionAsync",
//   async (payload: any, { getState, dispatch }) => {
//     const { project, selectedSceneIdx, selectedObjectIdList } = (
//       getState() as any
//     ).projectReducer;
//     const { ids = selectedObjectIdList } = payload;

//     const scene: Scene = project?.scenes[selectedSceneIdx];
//     // 현재 clip들 중 최상단 layer를 선택
//     const topLayer = [...scene.clips].sort((a, b) => b.layer - a.layer)[0].layer;
//     if (scene && ids) {
//       let i = 1;
//       scene.clips.forEach((clip) => {
//         if (ids.includes(clip.id)) {
//           const { left, top, id, layer, ...props } = clip;
//           dispatch(
//             addObjectAsync({
//               left: left + 10,
//               top: top + 10,
//               lock: false,
//               layer: topLayer + i, // 복제한 clip의 layer는 최상단으로 올림
//               ...props,
//             })
//           );
//           i++;
//         }
//       });
//     }
//     return null;
//   }
// );

const objectDuplicateAsync = createAsyncThunk(
  "project/objectDuplicateAsync",
  async (payload: any, { getState, dispatch }) => {
    const { project, selectedSceneIdx, selectedObjectIdList } = (
      getState() as any
    ).projectReducer;
    const { ids = selectedObjectIdList } = payload;

    const scene: Scene = project?.scenes[selectedSceneIdx];
    // 현재 clip들 중 최상단 layer를 선택
    const topLayer = [...scene.clips].sort((a, b) => b.layer - a.layer)[0]
      .layer;
    if (scene && ids) {
      let i = 1;
      scene.clips.forEach((clip) => {
        if (ids.includes(clip.id)) {
          const { left, top, id, layer, ...props } = clip;
          dispatch(
            addObjectAsync({
              left: left + 10,
              top: top + 10,
              lock: false,
              layer: topLayer + i, // 복제한 clip의 layer는 최상단으로 올림
              ...props,
            })
          );
          i++;
        }
      });
    }
    return null;
  }
);

const objectMoveForRatioAsync = createAsyncThunk(
  "project/objectMoveForRatioAsync",
  async (payload: any, { getState, dispatch }) => {
    const { project, selectedSceneIdx, selectedObjectIdList } = (
      getState() as any
    ).projectReducer;
    const { canvas } = canvasHolder;
    const { scenes, orientation } = project;

    console.log("!@#!@#!@# objectMoveForRatioAsync", scenes);
    function changeObjectTopLeftPositionForRatio({ clip }) {
      console.log("!@#!@#!@# clip orientation", clip, orientation);
      if (orientation === "landscape") {
        if (clip.top <= 420) {
          clip.top = (clip.top * 9) / 16;
          clip.left = (clip.left * 16) / 9;
          // setData(clip, ['top'], clip.top * 9 / 16);
          // setData(clip, ['left'], clip.left * 16 / 9);
        } else if (clip.top > 420 && clip.top <= 1500) {
          clip.top = clip.top - 420;
          clip.left = clip.left + 420;
          // setData(clip, ['top'], clip.top - 420);
          // setData(clip, ['left'], clip.left + 420);
        } else {
          clip.top = ((clip.top - 1080) * 2 * 1080) / 820;
          clip.left = (clip.left * 16) / 9;
          // setData(clip, ['top'], (clip.top - 1080) * 2 * 1080 / 820);
          // setData(clip, ['left'], clip.left * 16 / 9);
        }
      } else {
        if (clip.left <= 420) {
          clip.top = (clip.top * 16) / 9;
          clip.left = (clip.left * 9) / 16;
          // setData(clip, ['top'], clip.top * 16 / 9);
          // setData(clip, ['left'], clip.left * 9 / 16);
        } else if (clip.left > 420 && clip.left <= 1500) {
          clip.top = clip.top + 420;
          clip.left = clip.left - 420;
          // setData(clip, ['top'], clip.top + 420);
          // setData(clip, ['left'], clip.left - 420);
        } else {
          clip.top = (clip.top * 16) / 9;
          clip.left = ((clip.left - 1080) * 2 * 820) / 1080;
          // setData(clip, ['top'], clip.top * 16 / 9);
          // setData(clip, ['left'], (clip.left - 1080) * 2 * 820 / 1080);
        }
      }
    }
    scenes.forEach((S: Scene) => {
      console.log("!@#!@#!@# scene", S);
      S.clips.forEach((clip) => {
        console.log("!@#!@#!@# scene", clip);
        changeObjectTopLeftPositionForRatio({ clip });
      });
    });

    return null;
  }
);

/**
 * 캡션 삽입
 */
const addCaptionAsync = createAsyncThunk(
  'project/addCaptionAsync',
  async ({
    captionClip,
    sceneIdForGeneratingCaption,
  }: {
    captionClip: {
      type: string
      text: string
      captions: Caption[]
      fill: string
      backgroundColor: string
      fontSize: number
      padding: number
      align: string
      fontFamily: string
      width: number
      height: number
      top: number
      left: number
    }
    sceneIdForGeneratingCaption: string
  }, { getState }) => {
    const { canvas } = canvasHolder
    const { project, selectedSceneIdx } = (
      getState() as any
    ).projectReducer

    const foundSceneIndex = (
      project?.scenes.findIndex((scene: Scene) => (
        scene.sceneIdForGeneratingCaption === sceneIdForGeneratingCaption
      )) ?? -1
    ) as number
    const foundScene: Scene = project?.scenes[foundSceneIndex]

    if (foundSceneIndex < 0 || !foundScene) {
      return
    }

    const isShowingScene = foundSceneIndex === selectedSceneIdx

    const captionClipId = genId(captionClip.type, foundScene.clips)
    const topLayer = [...foundScene.clips].sort((a, b) => b.layer - a.layer)[0].layer
    const captionClipLayer = topLayer + 1

    const createdCaptionClip: Clip = {
      ...captionClip,
      captions: captionClip.captions.map((caption) => ({
        ...caption,
        /**
         * 타입 오류 해결하려고 임의로 처리함
         * Caption.name은 옵션이고
         * Clip.captions[].name은 필수임
         * 그래서 name이 없는 경우에 빈 문자열을 채우도록 함
         */
        name: caption.name ?? '',
      })),
      id: captionClipId,
      layer: captionClipLayer,
      name: captionClipId,
      /**
       * 타입 오류 해결하려고 임의로 처리함
       * textPreProcess는 aiModel에서만 사용되는 값인 것 같은데
       * 타입 상으로는 필수로 되어 있음
       * 정의되어 있지 않으면 오류가 발생하므로 undefined로 채워둠
       */
      textPreProcess: undefined,
    }

    if (isShowingScene) {
      await insertObject({
        ...createdCaptionClip,
        canvas,
        evented: true,
      })
    }

    return {
      sceneIndex: foundSceneIndex,
      captionClip: createdCaptionClip,
    }
  },
)

const uploadThumbnail = createAsyncThunk(
  "project/uploadThumbnail",
  async (payload: any, { getState, dispatch }) => {
    const { project }: {
      project: ProjectType | null
    } = (getState() as any).projectReducer;
    const { width = 480, height = 270, loading = true } = payload;

    if (loading) {
      dispatch(projectActions.setLoading(true));
    }

    if (!project) return;

    const { _id: id, scenes, orientation } = project;
    
    const thumbnail = await thumbnailScene<string>({
      scene: scenes[0],
      width,
      height,
      orientation,
    });

    const res = await fetch("/api/project/uploadThumbnail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        projectId: id,
        thumbnail,
      }),
    });

    return { data: await res.json() };
  }
);
const shareBrandKitItem = createAsyncThunk
// <
// null | undefined, 
// {
//   type: string,
//   object: BrandKitItemType
// },
// {
//   state?: unknown; 
//   dispatch?: Dispatch<AnyAction> | undefined;
// }>
(
  'project/shareBrandKitItem',
  async({
    type,
    object
  }: {
    type: string,
    object?: BrandKitItemType
  }, { getState, dispatch }) => {
    const { project, brandKitList } = (getState() as any).projectReducer; 
    let imgSrc = '';

    if(type === 'fontShare') {
      const ownFontList: FontType[] = brandKitList.font.filter((val: FontType) => val?.isOwn) ?? [];

      if(ownFontList.length === 0) return;
      const findFont: FontType | undefined = ownFontList.find((val: FontType) => val.src === object?.src);

      if(findFont && !findFont?.workspaceId && project?.workspaceId) {
        const res = await fetch('/api/project/brandKit/update', {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            item: findFont,
            workspaceId: project?.workspaceId[0],
          }),
        });

        const data = await res.json();
        if(data?.success) {
          const shareFont = {...findFont, workspaceId: project.workspaceId}
          dispatch(projectActions.setBrandKitList({ open: true, type: 'modal', shareFont }))
        }
      }
    };

    if(type === 'logoShare') {
      if(object instanceof fabric.Image) {
        const image = object as fabric.Image;
        imgSrc = image.getSrc();
      }

      const ownLogoList: LogoType[] = brandKitList.logo.filter((val: LogoType) => val?.isOwn) ?? [];

      if(ownLogoList.length === 0) return;   
        const findLogo: LogoType | undefined = ownLogoList.find((val: LogoType) => val.src === imgSrc);
        if(findLogo && !findLogo?.workspaceId && project?.workspaceId) {
          const res = await fetch('/api/project/brandKit/update', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              item: findLogo,
              workspaceId: project?.workspaceId[0],
            }),
          });
  
          const data = await res.json();
          if(data?.success) {
            const shareLogo = {...findLogo, workspaceId: project.workspaceId}
            dispatch(projectActions.setBrandKitList({ open: true, type: 'modal', shareLogo }))
          }
        }
    }
    return null;
  }
)

function toEditorType(clipType: string): string {
  switch (clipType) {
    case "aiModel":
      return "aiModel";
    case "textImage":
      return "text";
    case "image":
    case "videoImage":
    case "video":
      return "media";
    case "background":
      return "background";
    case "shape":
      return "shape";
    case "generate":
      return "generate";
    default:
      return "base";
  }
}

const getStepFromCurrentPlan = (currentPlan: string) => {
  const steps = [0, 0];
  const regex = /[^0-9]/g;
  const stepNum = parseInt(currentPlan?.replace(regex, ""));
  const starterNumArray = [15,30,60];
  const creatorNumArray = [30,60,90];

  if (currentPlan?.includes("Personal")) {
    steps[0] = starterNumArray.indexOf(stepNum);
  }
  if (currentPlan?.includes("Team")) {
    steps[1] = creatorNumArray.indexOf(stepNum);
  }
  
  return steps;
};

function toNumber(dir: "front" | "up" | "down" | "bottom"): number {
  switch (dir) {
    case "front":
      return 100;
    case "up":
      return 2;
    case "down":
      return -2;
    case "bottom":
      return -100;
  }
}

function nextLayer(
  clip: Clip | WritableDraft<Clip>,
  ids: string[],
  dir: "front" | "up" | "down" | "bottom"
): number {
  if (ids.includes(clip.id) && !clip.lock) {
    return clip.layer + toNumber(dir);
  }

  return clip.layer;
}

function updateLayerById(clips: Clip[], targetId: string, dir: string) {
  // layer 순서에 따라 정렬 초기화
  clips.sort((a, b) => a.layer - b.layer);

  const topLayer = clips.sort((a, b) => b.layer - a.layer)[0].layer;
  const currLayer = clips.find((clip) => clip.id === targetId)?.layer;

  // 이미 맨 위에 있을 경우 return
  if (dir === "front" || dir === "up") {
    if (currLayer === topLayer) return clips;
  }

  // 이미 맨 아래에 있을 경우 return
  if (dir === "bottom" || dir === "down") {
    if (currLayer === 1) return clips;
  }

  if (dir === "front") {
    // 현재 topLayer에 + 1 한 뒤 재정렬
    const updatedLayer = clips
      .map((clip) => {
        if (clip.id === targetId) return { ...clip, layer: topLayer + 1 };
        else return { ...clip };
      })
      .sort((a, b) => a.layer - b.layer);

    updatedLayer.forEach((clip, index) => (clip.layer = index + 1));

    return updatedLayer;
  }

  if (dir === "bottom") {
    // 맨 아래(-1)로 위치시킨 후 재정렬
    const updatedLayer = clips
      .map((clip) => {
        if (clip.id === targetId) return { ...clip, layer: -1 };
        else return { ...clip };
      })
      .sort((a, b) => a.layer - b.layer);

    updatedLayer.forEach((clip, index) => (clip.layer = index + 1));

    return updatedLayer;
  }

  if (dir === "up" || dir === "down") {
    const dirToNumber = dir === "up" ? +1 : -1;

    const updated = clips.map((clip) => {
      const { id, layer } = clip;

      // top 일 때, 현재 layer를 +1
      // down 일 때, 현재 layer를 -1
      if (id === targetId) {
        const newObj = { ...clip, id, layer: layer + dirToNumber };
        return newObj;
        // return { ...obj, id, layer: layer + dirToNumber };
      }

      // top 일 때, 현재 layer 보다 하나 위의 layer를 -1
      // down 일 때, 현재 layer 보다 하나 위의 layer를 +1
      if (layer === (currLayer as number) + dirToNumber) {
        const newObj = { ...clip, id, layer: layer - dirToNumber };
        return newObj;
        // return { ...obj, id, layer: layer - dirToNumber };
      }

      return { ...clip };
    });

    return updated;
  }
}


function changeScriptByModelChange(
  script: any,
  newModelObj: any
) {
  //newModelObj 는 script 가 들어오거나, modelClip 이 들어옴
  setData(script, ["org"], removeByTagName(script.org, "gesture"));
  setData(script, ["clipId"], newModelObj.clipId ?? newModelObj.id);
  setData(script, ["modelId"], newModelObj.modelId ?? newModelObj.model.ai_name);
  setData(script, ["clothId"], newModelObj.clothId ?? newModelObj.model.emotion);
  setData(script, ["gender"], newModelObj.gender ?? newModelObj.model.gender);
  setData(script, ["modelSupportLanguage"], newModelObj.language ?? newModelObj.model.language);
  if (newModelObj.tts) {
    setData(script, ["isTTS"], true);
    setData(script, ["tts"], newModelObj.tts);
  } else {
    setData(script, ["isTTS"], false);
    setData(script, ["tts"], null);
  }
}

// not Single Source fo Truth... but.
export const projectSlice = createSlice({
  name: `project`,
  initialState,
  reducers: {
    /**
     * 메인 캔버스의 최초 초기화 여부 세팅
     */
    setIsInitializedCanvas: (state, action: PayloadAction<boolean>) => {
      state.isInitializedCanvas = action.payload;
    },
    setGenerateHint: (state, action) => {
      const { type, hints } = action.payload;
      switch (type) {
        case "image":
          state.generate.image.hints = hints;
        case "video":
          state.generate.video.hints = hints;
      }
    },
    /**
     * 이미지 생성 기능을 통해 진입 시 초기값을 세팅함
     */
    setInitialGenerateImageValues: (state, action: PayloadAction<{
      topic: string
      imageStyle: ImageStyle
      imageSize: ImageSize
      downloadedImage: {
        url: string
        width: number
        height: number
      }
    }>) => {
      const { topic, imageStyle, imageSize, downloadedImage } = action.payload

      state.generate.selectedTab = 'Image'
      state.generate.image.initialValues = {
        topic,
        imageStyle,
        imageSize,
        downloadedImage,
      }
    },
    /**
     * 이미지 생성 기능을 통해 진입 후 패널 탭이 열린 이후 초기 값을 제거함
     */
    clearInitialGenerateImageValues: (state) => {
      delete state.generate.image.initialValues
    },
    /**
     * 비디오 생성 기능을 통해 진입 시 초기값을 세팅함
     */
    setInitialGenerateVideoValues: (state, action: PayloadAction<{
      topic: string
      downloadedVideo: {
        videoUrl: string
        thumbnailUrl: string
        width: number
        height: number
      }
    }>) => {
      const { topic, downloadedVideo } = action.payload

      state.generate.selectedTab = 'Video'
      state.generate.video.initialValues = {
        topic,
        downloadedVideo,
      }
    },
    /**
     * 비디오 생성 기능을 통해 진입 후 패널 탭이 열린 이후 초기 값을 제거함
     */
    clearInitialGenerateVideoValues: (state) => {
      delete state.generate.video.initialValues
    },
    setAutomationModalOpen: (state, action) => {
      const { open } = action.payload;
      state.automationModalOpen = open;
    },

    trimVideo: (state, action) => {
      const { type, data } = action.payload;
      const { project, selectedSceneIdx } = state;

      switch (type) {
        case "init":
          {
            const targetId = data.target;
            const targetClip = project?.scenes[selectedSceneIdx].clips.find((clip) => {
              if (clip.id === targetId) {
                return true;
              }
              return false;
            });

            if (targetClip) {
              state.trimVideo = {
                active: true,
                target: current(targetClip),
              };
            }
          }
          break;
        case "trim":
          {
            const {
              target: targetId,
              result: [trimStartTime, trimEndTime],
            } = data;
            const targetClip = project?.scenes[selectedSceneIdx].clips.find((clip) => {
              if (clip.id === targetId) {
                return true;
              }
              return false;
            });

            if (targetClip) {
              targetClip.trimStartTime = trimStartTime;
              targetClip.trimEndTime = trimEndTime;
              state.trimVideo = {
                active: false,
                target: null,
              };
            }
          }
          break;
        case "cancel":
          {
            state.trimVideo = {
              active: false,
              target: null,
            };
          }
        default:
          break;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCanvas: (state, action) => {
      canvasHolder.canvas = action.payload;
      initCanvasSize({
        orientation: state.project?.orientation,
        canvas: canvasHolder.canvas,
        width: 1920,
        height: 1080,
      });
      state.undoStack = [];
      state.redoStack = [];
    },
    drawCanvas: (state, action: PayloadAction<{
      onRenderedAll?: () => void
    } | undefined>) => {
      const { canvas } = canvasHolder;
      const { project, selectedSceneIdx, defaultFontFamily } = state;

      // console.log("selectedSceneIdx: ", selectedSceneIdx);
      const scene = project?.scenes?.[selectedSceneIdx];
      // console.log("project: ", project);
      // console.log("init scene: ", scene);
      console.log(">>>>> drawCanvas defaultFontFamily ", {
        defaultFontFamily: current(defaultFontFamily) as any,
      });
      if (scene)
        initCanvasScene({
          canvas,
          scene: current(scene) as Scene,
          defaultFontFamily,
          callback: {
            onRenderedAll: action.payload?.onRenderedAll,
          },
        });
    },
    setCanvasSize: (state, action) => {
      const { canvas } = canvasHolder;
      const { project, captionActions } = state;
      const { width, height } = action.payload;
      const { orientation = "landscape" } = project || {};

      let captionHeight = height;

      const caption = canvas
        ?.getObjects()
        .find((object) => object.type === "captions");

      // console.log('>>>>> setCanvasSize height ', {height}, {captionActions: current(captionActions) as any}, {caption})

      initCanvasSize({ canvas, width, height, orientation });
      // if(caption) {
      //   captionHeight = captionHeight - 120;
      //   initCanvasSize({ canvas, width, height: captionHeight, orientation });
      // } else {
      //   initCanvasSize({ canvas, width, height, orientation });
      // }
    },

    objectPositionChange: (
      state,
      action: PayloadAction<{
        ids?: string[];
        dir: "front" | "up" | "down" | "bottom";
      }>
    ) => {
      const { selectedObjectIdList, project, selectedSceneIdx } = state;
      const { ids = selectedObjectIdList, dir } = action.payload;
      const scene = project?.scenes[selectedSceneIdx];
      if (scene && ids) {
        const updatedClips = updateLayerById(
          scene.clips as Clip[],
          ids[0],
          dir
        );

        updatedClips?.sort((a, b) => a.layer - b.layer);

        scene.clips = updatedClips as WritableDraft<Clip>[];

        const { canvas } = canvasHolder;
        if (canvas) sortLayer({ canvas, scene: current(scene) as Scene });
      }
    },
    updateClipVideoURL: (state, action) => {
      const { url, clipId } = action.payload;
      const { selectedSceneIdx, project } = state;

      const scene = project?.scenes[selectedSceneIdx];
      if (scene) {
        scene.clips = scene?.clips?.map((clip: any) => {
          if (clip.id !== clipId) {
            return clip;
          }
          return {
            ...clip,
            video_url: url,
          };
        });
      }
    },
    // fabric event
    objectModified: (state, action) => {
      const { canvas } = canvasHolder;
      const { project, selectedSceneIdx } = state;
      const { clips } = project?.scenes[selectedSceneIdx] || {};
      const objects = canvas?.getActiveObjects();
      console.log(
        "modified objects: ",
        clips.map((clip) => {
          return { ...clip };
        })
      );

      function changeClip(clip: any, object: any) {
        console.log("object: 1", object, clip?.type);
        if (clip) {
          // object의 layer를 변경된 clip의 layer와 동기화
          const copiedObject = { ...object };
          copiedObject.layer = clip.layer;

          const {
            dirty,
            dynamicMinWidth,
            isWrappingn,
            ownCachingn,
            cacheWidth,
            cacheHeight,
            selected,
            isMoving,
            inCompositionMode,
            selectionStart,
            selectionEnd,
            objectCaching,
            source,
            cacheKey,
            selectable,
            evented,
            crossOrigin,
            hasRotatingPoint,
            type,
            ...props
          } = copiedObject;

          // console.log("inside changeClip", props)
          Object.entries(props).forEach(([key, value]) => {
            // console.log("full list", {key}, {value})
            if (
              !["object", "function"].includes(typeof value) &&
              !key.startsWith("_")
            ) {
              // console.log("changing", {key}, {value})
              if (key === "opacity") {
                clip[key] = parseFloat(value) * 100;
              } else {
                clip[key] = value;
              }
            }
          });
        }
      }
      if (action.payload._objects) {
        action.payload._objects.forEach((groupObject: any) => {
          const clip = clips?.find(({ id }) => id === groupObject.name);
          // @ts-ignore
          const object = canvas._toObject(groupObject, "toObject", ["id"]);
          // console.log('457', {...object});
          changeClip(clip, object);
        });
      } else {
        /**
         * crop 영역이 원래 이미지에서 벗어나지 못하게
         * object moving 할때마다 잡아줘야 함
         */
        if (action.payload.name.includes("crop")) {
          // cropping image here
          const targetImage = current(state.cropImageInfo.targetImage) as any;
          const selectionRect = action.payload;
          // croppingImage({canvas, rectImage: action.payload, targetImage});
          setBoundaries(canvas, selectionRect, targetImage);
        }
        const clip = clips?.find(({ id }) => id === action.payload.name);
        // console.log("459", {...clip})
        changeClip(clip, action.payload);
        // console.log("new Clip!", {...clip})
      }
    },
    onPlaySetScaleVideoImage: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { clips } = project?.scenes[selectedSceneIdx] || {};
      const object = action.payload;

      const targetClip = clips?.find(({ id }) => id === object.name);

      if (targetClip && project) {
        project.scenes[selectedSceneIdx].clips = project.scenes[
          selectedSceneIdx
        ].clips.map((clip) => {
          if (clip.id === targetClip.id) {
            return {
              ...targetClip,
              width: object.width,
              height: object.height,
              scaleX: object.scaleX,
              scaleY: object.scaleY,
              prevDimension: object.prevDimension,
            };
          }
          return clip;
        });
      }
    },

    objectDeselected: (state) => {
      const { canvas } = canvasHolder;

      /**
       * cropImage 관련된거 있으면 삭제한다.
       */
      const cropObject = canvas
        ?.getObjects()
        .find((object) => object.name?.includes("crop"));
      const cropButton = canvas
        ?.getObjects()
        .find((object) => object.type === "crop-button");
      const cancelButton = canvas
        ?.getObjects()
        .find((object) => object.type === "cancel-button");

      if (cropObject) {
        canvas?.remove(cropObject);
        const targetImageClip = current(state.cropImageInfo.targetImage) as any;
        editObject({
          canvas,
          name: targetImageClip.id,
          key: "lock",
          value: false,
        });
        editObject({
          canvas,
          name: targetImageClip.id,
          key: "opacity",
          value: 100,
        });
        state.cropImageInfo = {
          ...state.cropImageInfo,
          targetImage: {},
        };
      }

      if (cropButton && cancelButton) {
        canvas?.remove(cropButton);
        canvas?.remove(cancelButton);
      }

      canvas?.discardActiveObject();
      canvas?.requestRenderAll();
      state.selectedObjectTypeList = [];
      state.selectedAvatarType = null;
      state.selectedLeftSubTab = null;
    },

    objectSelected: (state, action) => {
      if (action.payload.length === 1) {
        const [selected] = action.payload;
        state.selectedObjectIdList = [selected?.name];
        state.selectedObjectTypeList = [selected?.type];
      } else if (action.payload.length > 1) {
        state.selectedObjectIdList = action.payload.map(
          ({ name }: { name: string }) => name
        );
      }
      const { project, selectedSceneIdx } = state;
      let selectedClips = project?.scenes[selectedSceneIdx]?.clips.filter(
        ({ id }) => state.selectedObjectIdList?.includes(id)
      );

      if (state.selectedObjectIdList?.every((id) => id === "background")) {
        state.selectedObjectTypeList = ["background"];
      } else {
        state.selectedObjectTypeList =
          selectedClips?.map(({ type }) => toEditorType(type)) || [];

        if (
          state.selectedObjectTypeList.length === 0 &&
          action.payload[0].name.startsWith("aiModel")
        ) {
          state.selectedObjectTypeList = ["aiModel"];
        }

        /**
         * Crop Rect 영역을 잡았을때는 Clip에 추가하지 않으므로
         * 위에 TopToolbar를 보여주기 위해 셋팅 필요함
         * - canvas에는 그리므로 e.target에 잡힌다
         * - Crop Rect name은 crop으로 시작하게 만들었음
         * - selectedObjectTypeList = ['media']
         * - selectedObjectIdList = [object.name]
         */
        if (
          state.selectedObjectTypeList.length === 0 &&
          action.payload[0].name.startsWith("crop")
        ) {
          state.selectedObjectTypeList = ["media"];
        }

        if (action.payload[0].name.startsWith("audio")) {
          const { canvas } = canvasHolder;
          discardForAudioObject({ canvas });
          state.selectedObjectTypeList = ["media"];
        }

        if (
          !state.LeftSiderCollapsed &&
          state.selectedObjectTypeList &&
          state.selectedObjectTypeList.length > 0
        ) {
          state.selectedLeftTab = state.selectedObjectTypeList[0];
          state.selectedLeftSubTab = null;
        }

        if (
          action.payload[0].name.startsWith("captions") ||
          (state.selectedObjectIdList &&
            state.selectedObjectIdList.length > 0 &&
            state.selectedObjectIdList[0].startsWith("captions"))
        ) {
          state.selectedObjectTypeList = ["captions"];
          state.selectedLeftTab = "subtitle";
          state.selectedLeftSubTab = null;
        }

        if (action.payload[0].subType === "generate") {
          // const type = action.payload[0].type ==='image' ? 'image'  : 'videoImage';
          // console.log("generate !!! type: ",type)
          // state.selectedObjectTypeList = [type];
          // state.selectedLeftTab = 'generate';
          // state.selectedLeftSubTab = null;
          console.log("generate action.payload[0]: ", action.payload[0]);

          console.log("action.payload[0].type: ", action.payload[0].type);
          // if(action.payload[0]?.video_url){
          state.selectedObjectTypeList = ["media"];
          // }else{
          //   state.selectedObjectTypeList = ['image'];
          // }
          state.selectedLeftTab = "generate";
          state.selectedLeftSubTab = null;
        }

        if (
          selectedClips?.some(({ animation }) => animation && animation.type)
        ) {
          state.selectedLeftTab = state.selectedObjectTypeList[0];
          state.selectedLeftSubTab = "animation";
          state.LeftSiderCollapsed = false;
          
          if(state.selectedLeftTab === 'captions') {
            state.selectedLeftTab = "subtitle";
          }
        }

        if(action.payload[0]?.tag === 'logo') {
          state.selectedLeftTab = 'asset';
          state.selectedLeftSubTab = null;
        }
        if(action.payload[0]?.asset) {
          state.selectedLeftTab = 'asset';
          state.selectedLeftSubTab = null;
        }

        if(action.payload[0]?.afterCrop) {
          const { project, selectedSceneIdx } = state;
          const scene = project?.scenes[selectedSceneIdx];
          const { canvas } = canvasHolder;
          if(scene) {
            const canvasCropObject = canvas?.getObjects().find((object) => object.type === 'image' && object?.afterCrop);
            console.log('canvasCropObject object ', {canvasCropObject}, {action: action.payload[0]})
            // const newObject = {
            //   type: 'image',
            //   source_url: newObject
            // }
            // scene.clips.push(action.payload[0]);
          }
        }

        if (
          action.payload.length === 1
          && action.payload[0].name.startsWith("aiModel")
        ) {
          state.selectedLeftTab = "aiModel";
          state.selectedLeftSubTab = null;

          if (
            ["dreamAvatar"].includes(
              action.payload[0].avatarType
            )
          ) {
            state.selectedModelTab = "photoAvatar";
            state.selectedAvatarType = action.payload[0].avatarType;
          } else {
            state.selectedModelTab = "aiAvatar";
            state.selectedAvatarType = action.payload[0].avatarType ?? "original";
          }
        }
      }
    },
    cropObjectAdd: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const [selected] = action.payload;
      const scene = project?.scenes[selectedSceneIdx];

      const { canvas } = canvasHolder;

      console.log('cropObjectAdd object ', {selected})
      const canvasObject = canvas?.getObjects().find((object) => object.type === 'image' && object?.afterCrop);
      const newObj = {
        type: 'image',
        source_url: selected?.cropUrl,
        video_url: '',
        width: selected?.width,
        height: selected?.height,
        scaleX: selected?.scaleX,
        scaleY: selected?.scaleY,
        top: selected?.top,
        left: selected?.left,
        layer: selected?.layer,
        id: selected?.name,
        name: selected?.name,
        lockMovementX: selected?.lockMovementX || false,
        lockMovementY: selected?.lockMovementX || false,
        lockRotation: selected?.lockRotation || false,
        lockScalingX: selected?.lockScalingX || false,
        lockScalingY: selected?.lockScalingY || false,
        lockSkewingX: selected?.lockSkewingX || false,
        lockSkewingY: selected?.lockSkewingY || false,
        lockUniScaling: selected?.lockUniScaling || false,
      }

      if(scene) {
        scene.clips.push(newObj);
      }
      
    },
    objectsAlignChange: (state, action) => {
      const { align } = action.payload;
      const { project, selectedSceneIdx } = state;
      const selectedClips = project?.scenes[selectedSceneIdx]?.clips.filter(
        ({ id }) => state.selectedObjectIdList?.includes(id)
      );
      const { left, top, width, height, center, middle } =
        getCommonBoundingBox(selectedClips);
      console.log("!@#!@#!@#", {
        align,
        left,
        top,
        width,
        height,
        center,
        middle,
      });
      const { canvas } = canvasHolder;
      selectedClips?.forEach((clip) => {
        console.log("!@#!@#!@#", { left: clip.left, top: clip.top });
        switch (align) {
          case "left":
            clip.left = left;
            editObject({
              canvas,
              name: clip.id,
              key: "left",
              value: 0 - width / 2,
            });
            break;
          case "right":
            clip.left = left + width - clip.width * (clip.scaleX || 1);
            editObject({
              canvas,
              name: clip.id,
              key: "left",
              value: 0 + width / 2 - clip.width * (clip.scaleX || 1),
            });
            break;
          case "center":
            clip.left = center - (clip.width * (clip.scaleX || 1)) / 2;
            editObject({
              canvas,
              name: clip.id,
              key: "left",
              value: 0 - (clip.width * (clip.scaleX || 1)) / 2,
            });
            break;
          case "top":
            clip.top = top;
            editObject({
              canvas,
              name: clip.id,
              key: "top",
              value: 0 - height / 2,
            });
            break;
          case "bottom":
            clip.top = top + height - clip.height * (clip.scaleY || 1);
            editObject({
              canvas,
              name: clip.id,
              key: "top",
              value: 0 + height / 2 - clip.height * (clip.scaleY || 1),
            });
            break;
          case "middle":
            clip.top =
              top + height / 2 - (clip.height * (clip.scaleY || 1)) / 2;
            editObject({
              canvas,
              name: clip.id,
              key: "top",
              value: 0 - (clip.height * (clip.scaleY || 1)) / 2,
            });
            break;
          default:
            break;
        }
      });
    },
    objectsAngleChange: (state, action) => {
      const { dir } = action.payload;
      const { project, selectedSceneIdx } = state;
      const selectedClips = project?.scenes[selectedSceneIdx]?.clips.filter(
        ({ id }) => state.selectedObjectIdList?.includes(id)
      );
      // console.log("!@#!@#!@#", {dir})
      const { canvas } = canvasHolder;
      selectedClips?.forEach((clip) => {
        if (clip) {
          console.log("!@#!@#!@#", {
            clip,
            name: clip.id,
            key: "angle",
            value: clip.angle,
          });
          let angle = clip.angle;
          switch (dir) {
            case "right":
              angle = angle ? angle + 90 : 90;
              if (angle > 360) angle -= 360;
              // console.log("!@#!@#!@#", { angle })
              setData(clip, ["angle"], angle);
              editObject({ canvas, name: clip.id, key: "angle", value: angle });
              break;
            case "left":
              angle = angle ? angle - 90 : 270;
              if (angle < 0) angle += 360;
              // console.log("!@#!@#!@#", { angle })
              setData(clip, ["angle"], angle);
              editObject({ canvas, name: clip.id, key: "angle", value: angle });
              break;
            default:
              break;
          }
        }
      });
    },

    objectMouseOver: (state, action) => {
      const { canvas } = canvasHolder;
      const { id } = action.payload;
      // mouseOverObject({canvas, id})
    },
    objectMouseOut: (state, action) => {
      const { canvas } = canvasHolder;
      const { id } = action.payload;
      // mouseOutObject({canvas, id})
    },
    objectMoveByKeyIn: (state, action) => {
      const { selectedObjectIdList, selectedSceneIdx, project } = state;
      const { direction, weight = 1 } = action.payload;
      const { canvas } = canvasHolder;
      const clips = project?.scenes[selectedSceneIdx]?.clips.filter((item) =>
        selectedObjectIdList?.includes(item.id)
      );
      const path = ["ArrowLeft", "ArrowRight"].includes(direction)
        ? ["left"]
        : ["top"];
      const diff = ["ArrowLeft", "ArrowUp"].includes(direction)
        ? -weight
        : weight;
      const orientation = project.orientation;
      if (clips?.length) {
        clips.forEach((clip) => {
          if (clip.lock) return;
          if (direction === "ArrowUp" && clip.top <= 0) return;
          if (direction === "ArrowLeft" && clip.left <= 0) return;
          if (orientation === "landscape") {
            if (direction === "ArrowDown" && 1080 - clip.top < 50) return;
            if (direction === "ArrowRight" && 1920 - clip.left < 50) return;
          } else {
            if (direction === "ArrowDown" && 1920 - clip.top < 50) return;
            if (direction === "ArrowRight" && 1080 - clip.left < 50) return;
          }
          const value = ["ArrowLeft", "ArrowRight"].includes(direction)
            ? clip.left + diff
            : clip.top + diff;
          const object = canvas
            .getActiveObjects()
            .find((item) => item.name === clip.id);
          const objectValue = ["ArrowLeft", "ArrowRight"].includes(direction)
            ? object.left + diff
            : object.top + diff;
          // console.log("!@#!@#!@#", {clip, path, value})
          setData(clip, path, value);
          editObject({
            canvas,
            type: "",
            name: clip.id,
            key: path[0],
            value: objectValue,
          });
        });
      }
    },
    setCanvasContextMenu: (state, action) => {
      const isShow = action.payload;
      state.showCanvasContextMenu = isShow;
    },

    //clipBoard
    setClipBoards: (state, action) => {
      const { selectedObjectIdList, selectedSceneIdx } = state;
      state.clipBoard = {
        selectedObjectIdList: selectedObjectIdList,
        selectedSceneIdx: selectedSceneIdx,
        pasteCount: 0,
      };
    },
    cutAndSetClipBoards: (state, action) => {
      const { selectedObjectIdList, selectedSceneIdx } = state;
      const curScene: Scene = state.project?.scenes[selectedSceneIdx];
      const ids = [...selectedObjectIdList];
      if (ids) {
        //todo cut 하면 object 가 지워져 버려서 paste 가 안됨.
        //object 정보를 들고 있어야 하는데 cut/paste 는 move 와 동일하니 일단 배제
        state.clipBoard = {
          selectedObjectIdList: ids,
          selectedSceneIdx: selectedSceneIdx,
        };
        const { canvas } = canvasHolder;
        deleteObjects({ canvas, names: ids });

        ids?.forEach((id) => {
          const clipIndex = curScene.clips.findIndex((clip) => clip.id === id);
          curScene.clips.splice(clipIndex, 1);
        });
      }
    },
    pasteClipBoards: (state, action) => {
      const { clipBoard, selectedObjectIdList, selectedSceneIdx } = state;
      const { canvas } = canvasHolder;
      const curScene: Scene = state.project?.scenes[selectedSceneIdx];
      const clipScene: Scene =
        state.project?.scenes[clipBoard.selectedSceneIdx];

      const topLayer = [...curScene.clips].sort((a, b) => b.layer - a.layer)[0]
        .layer;
      const ids = clipBoard.selectedObjectIdList;
      if (curScene && clipScene && ids) {
        let i = 1;
        ids?.forEach((_id) => {
          if (clipBoard.selectedSceneIdx === null) return;
          const object = state.project?.scenes[
            clipBoard.selectedSceneIdx ?? 0
          ]?.clips.find((clip) => clip.id === _id);
          if (object) {
            const { type } = object;
            if (type === "aiModel") return;
            // const layer = curScene.clips.length;
            const id = genId(type, curScene.clips);
            const newObject = {
              ...object,
              left: object.left + 10 * (state.clipBoard.pasteCount + 1),
              top: object.top + 10 * (state.clipBoard.pasteCount + 1),
              lock: false,
              canvas,
              layer: topLayer + i,
              id,
              type,
              evented: true,
            };
            // console.log("!@#!@#!@#", {newObject})
            insertObject(newObject);
            delete newObject.canvas;
            curScene.clips.push(newObject);
            i++;
          }
        });
        state.clipBoard.pasteCount += 1;
      }
    },
    clearClipBoards: (state, action) => {
      //scene 이 추가되거나 삭제될때 호출(clipboard 안의 sceneIdx 정합성이 망가진다.)
      state.clipBoard = {
        selectedObjectIdList: null,
        selectedSceneIdx: null,
        pasteCount: 0,
      };
    },

    // left bar
    selectLeftSiderTab: (state, action) => {
      if (
        state.selectedLeftSubTab ||
        state.selectedLeftTab !== action.payload
      ) {
        state.selectedLeftTab = action.payload;
        state.LeftSiderCollapsed = false;
        state.selectedLeftSubTab = null;
      } else {
        state.LeftSiderCollapsed = true;
        state.selectedLeftTab = null;
        state.selectedLeftSubTab = null;
      }
    },
    selectLeftSiderSubTab: (state, action) => {
      const { selectedObjectTypeList } = state;
      state.selectedLeftTab = selectedObjectTypeList[0];
      state.LeftSiderCollapsed = false;
      state.selectedLeftSubTab = action.payload;
    },
    setLeftSiderCollapse: (state, action) => {
      state.LeftSiderCollapsed = action.payload;
    },
    setSelectedModelTab: (state, action) => {
      state.selectedModelTab = action.payload;
    },
    setSelectedMedia: (state, action) => {
      console.log("action.payload: ", action.payload);
      state.selectedMedia = action.payload;
    },
    setSelectedMediaAPI: (state, action) => {
      console.log("action.payload: ", action.payload);
      state.selectedMediaAPI = action.payload;
    },
    closeLeftSider: (state, action) => {
      state.selectedLeftTab = null;
      state.LeftSiderCollapsed = true;
      state.selectedLeftSubTab = null;
    },
    // scene
    addScene: (state, action) => {
      const { canvas } = canvasHolder;
      const { project, listenButtonAndAudioInfo } = state;
      const sceneIdx = action.payload;
      console.log("!#!@#!@#", { sceneIdx });
      const scene = project?.scenes[sceneIdx];
      if (scene) {
        const newScene = structuredClone<Scene>(current(scene) as Scene);

        /**
         * 씬 복제 시 캡션 생성을 위한 아이디를 제거한다.
         */
        delete newScene.sceneIdForGeneratingCaption

        /**
         * Duplicate new Scene -> remove previous script
         */
        const aiModel = newScene.clips.find((clip) => clip.type === "aiModel");
        const updateClipIndex = newScene.clips.findIndex(
          (clip) => clip.type === "aiModel"
        );

        const newModel = {
          ...aiModel,
          script: {
            ...aiModel?.script,
            org: "",
            tts: null,
          },
        };

        const newClips = newScene.clips.map((clip, index) =>
          index === updateClipIndex ? { ...newModel } : clip
        );
        const updateScene = {
          ...newScene,
          isListenButtonShow:true,
          clips: newClips,
          scripts: newScene.scripts.map((script) => {
            script.org = "";
            return script;
          }),
        };
        project?.scenes.splice(sceneIdx + 1, 0, updateScene as any);

        state.selectedSceneIdx = sceneIdx + 1;
        state.selectedObjectIdList = [];
        state.selectedObjectTypeList = [];
        state.selectedAvatarType = null;
        initCanvasScene({
          canvas,
          scene: current(project.scenes[sceneIdx]) as any,
          smooth: true,
        });

        state.listenButtonAndAudioInfo.splice(sceneIdx + 1, 0, {
          audio: {
            status: "idle",
            url: "",
            isFirstListen: false,
          },
          isListenButtonShow: true
        });
      }
    },
    /**
     * 씬에 캡션 생성용 아이디를 설정한다.
     */
    setSceneIdForGeneratingCaption: (state, action: PayloadAction<{
      sceneIndex: number
      sceneIdForGeneratingCaption: string
    }>) => {
      const { sceneIndex, sceneIdForGeneratingCaption } = action.payload
      const { project } = state
      const scene = project?.scenes[sceneIndex]

      if (!scene) {
        return
      }

      scene.sceneIdForGeneratingCaption = sceneIdForGeneratingCaption
    },
    /**
     * 씬에서 캡션 생성용 아이디를 제거한다.
     */
    removeSceneIdForGeneratingCaption: (state, action: PayloadAction<{
      sceneIdForGeneratingCaption: string
    }>) => {
      const { sceneIdForGeneratingCaption } = action.payload
      const { project } = state
      const foundScene = project?.scenes.find(scene => (
        scene.sceneIdForGeneratingCaption === sceneIdForGeneratingCaption
      ))

      if (!foundScene) {
        return
      }

      delete foundScene.sceneIdForGeneratingCaption
    },
    selectScene: (state, action) => {
      if (state.selectedSceneIdx !== action.payload) {
        state.selectedSceneIdx = action.payload;
        state.selectedObjectIdList = [];
        state.selectedObjectTypeList = [];
        state.selectedAvatarType = null;
        const { canvas } = canvasHolder;
        const { project, selectedSceneIdx } = state;
        const scene = project?.scenes[selectedSceneIdx];
        if (scene) {
          const scripts = scene.scripts;
          state.scriptEditorTab = scene.isDualAvatar
          ? "Conversation"
          : scripts[0].isTTS && scripts[0].tts.type === "audio" 
          ? "Voice Script"
          : "Text Script"
          initCanvasScene({
            canvas,
            scene: current(scene) as any,
            smooth: true,
          });
        }
      }
    },
    selectObject: (state, action) => {
      const { sceneIndex, clipId } = action.payload;

      const setActiveObject = (c: fabric.Canvas) => {
        const targetObject = c.getObjects().find((obj) => {
          return obj.name === clipId;
        });
        if (targetObject) {
          c.setActiveObject(targetObject);
        }
      }

      if (state.selectedSceneIdx !== sceneIndex) {
        state.selectedSceneIdx = sceneIndex;
        state.selectedObjectIdList = [];
        state.selectedObjectTypeList = [];
        state.selectedAvatarType = null;
        const { canvas } = canvasHolder;
        const { project, selectedSceneIdx } = state;
        const scene = project?.scenes[selectedSceneIdx];
        if (scene) {
          initCanvasScene({
            canvas,
            scene: current(scene) as any,
            callback: {
              onBeforeRenderAll: setActiveObject,
            },
          });
        }
      } else {
        const { canvas } = canvasHolder;
        if (canvas) {
          setTimeout(() => {
            setActiveObject(canvas);
            canvas.renderAll();
          }, 0);
        }
      }
    },
    removeScene: (state, action) => {
      const { canvas } = canvasHolder;
      const { project } = state;
      const sceneIdx = action.payload;
      const scene = project?.scenes[sceneIdx];
      if (scene) {
        if (project.scenes.length === 0) return;
        project?.scenes.splice(sceneIdx, 1);
        state.listenButtonAndAudioInfo.splice(sceneIdx, 1);

        const replaceReg = new RegExp(`<multiplesendtag[^>]*?>.*?<\/multiplesendtag>`, "gi");
        const existsMultipleSendTags = JSON.parse(JSON.stringify(project))?.scenes.some(scene => scene.scripts.some(script => script.org && script.org.match(replaceReg)));
        if (!existsMultipleSendTags) project.multipleSendTags = [];

        state.selectedSceneIdx = sceneIdx === 0 ? sceneIdx : sceneIdx - 1;
        state.selectedObjectIdList = [];
        state.selectedObjectTypeList = [];
        state.selectedAvatarType = null;
        initCanvasScene({
          canvas,
          scene: current(project.scenes[state.selectedSceneIdx]) as any,
          smooth: true,
        });
      }
    },
    moveScene: (state, action) => {
      const { canvas } = canvasHolder;
      const { project } = state;
      const { sceneIdx, direction } = action.payload;
      const movingScene = project?.scenes[sceneIdx];
      console.log("!@#!@#!@#", { sceneIdx, direction });
      if (movingScene) {
        if (project.scenes.length === 0) return;
        if (direction === "left") {
          if (sceneIdx === 0) return;
          [project.scenes[sceneIdx - 1], project.scenes[sceneIdx]] = [
            project?.scenes[sceneIdx],
            project?.scenes[sceneIdx - 1],
          ];

          [state.listenButtonAndAudioInfo[sceneIdx - 1], state.listenButtonAndAudioInfo[sceneIdx]] = [
            state.listenButtonAndAudioInfo[sceneIdx],
            state.listenButtonAndAudioInfo[sceneIdx - 1],
          ];

          state.selectedSceneIdx = sceneIdx - 1;
        } else {
          if (sceneIdx === project.scenes.length - 1) return;
          [project.scenes[sceneIdx], project.scenes[sceneIdx + 1]] = [
            project?.scenes[sceneIdx + 1],
            project?.scenes[sceneIdx],
          ];
          [state.listenButtonAndAudioInfo[sceneIdx], state.listenButtonAndAudioInfo[sceneIdx + 1]] = [
            state.listenButtonAndAudioInfo[sceneIdx + 1],
            state.listenButtonAndAudioInfo[sceneIdx],
          ];
          state.selectedSceneIdx = sceneIdx + 1;
        }
        state.selectedObjectIdList = [];
        state.selectedObjectTypeList = [];
        state.selectedAvatarType = null;
        initCanvasScene({
          canvas,
          scene: current(movingScene) as any,
          smooth: true,
        });
      }
    },
    thumbnailScene: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const {
        sceneIdx = selectedSceneIdx,
        thumbnailUrl,
        scene,
      } = action.payload;
      if (project?.scenes[sceneIdx]) {
        if (
          project.scenes[sceneIdx].thumbnailUrl
          && project.scenes[sceneIdx].thumbnailUrl.startsWith("blob:")
        ) {
          URL.revokeObjectURL(project.scenes[sceneIdx].thumbnailUrl);
        }
        project.scenes[sceneIdx].thumbnailUrl = thumbnailUrl;

        // Scene 있을 경우 해드 온리를 변경한다.
        if (scene) {
          scene.clips.map((c: Clip) => {
            const clip = project.scenes[sceneIdx].clips.find(
              (c2) => c2.id === c.id
            );
            if (clip) clip.headOnly = c.headOnly;
          });
        }
      }
    },

    /*
     * watermark remove -> when payment succeed
     */
    removeWatermark: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { scenes }: any = project;
      const { canvas } = canvasHolder;
      const { watermark = true } = action.payload;
      console.log(">>>> redux removeWatermark ", { watermark });
      if (scenes) {
        scenes.map((scene: any) => {
          if (scene) {
            scene.watermark = watermark;
          }
          return scene;
        });
        const selectedScene = scenes[selectedSceneIdx];
        initCanvasScene({
          canvas,
          scene: current(selectedScene) as any,
          smooth: true,
        });
      }
    },
    setImageCropMode: (state, action) => {
      //개발중 잠시 중단, 1차 런칭에서 크롭 제외
      const { project, selectedSceneIdx } = state;
      const { id } = action.payload;
      const { canvas } = canvasHolder;
      const activateObject = canvas?.getActiveObject();
    },
    changeBackground: (state, action) => {
      //1) type: color, value: source_color, 2) type: color, value: 'chromekey', 3) type: image, value: source_url
      const { type, value, isAll } = action.payload;
      const { project, selectedSceneIdx } = state;
      const { canvas } = canvasHolder;
      const activateObject = canvas?.getActiveObject();
      const scene = project?.scenes[selectedSceneIdx];
      const { clips } = scene;

      //색을 바꿀때를 제외하고, deselect
      if (!(type === "color" && value !== "chromekey")) {
        canvas?.discardActiveObject();
        state.selectedObjectTypeList = [];
        state.selectedLeftSubTab = null;
      }

      if (scene) {
        //1. redux 에 값추가
        setData(scene, ["background"], {
          ...scene.background,
          source_type: type,
          ...(type === "color"
            ? { source_color: value === "chromekey" ? "rgb(54,188,37)" : value }
            : { source_url: value }),
        });

        // if(type === 'color' && value === 'chromekey') { //이미지 백그라운드를 삭제하고 크로마키 백그라운드를 만드는 로직
        const preBackgroundObject = canvas
          ?.getObjects()
          .find(({ name }) => name === "background");
        addBackground({
          canvas,
          evented: true,
          ...scene.background,
        }).then(() => {
          if (preBackgroundObject) {
            canvas?.sendToBack(preBackgroundObject);
            canvas?.remove(preBackgroundObject);
          }
          setTimeout(() => {
            canvas?.requestRenderAll();
          });
        });

        if (type === "image") {
          if (state.selectedObjectIdList?.length === 1) {
            const name = state.selectedObjectIdList[0];
            setData(
              scene,
              ["clips"],
              [...clips.filter(({ id }: { id: string }) => id !== name)]
            );
            if (activateObject) {
              canvas?.remove(activateObject);
            }
          }
        }

        // All of background change image
        if (isAll) {
          const replaceList = project?.scenes.filter(
            (row, index) => index !== selectedSceneIdx
          );
          replaceList.forEach(async (row) => {
            setData(row, ["background"], {
              ...row.background,
              source_type: type,
              ...(type === "color"
                ? {
                    source_color:
                      value === "chromekey" ? "rgb(54,188,37)" : value,
                  }
                : { source_url: value }),
            });
          });
        }
      }
    },
    changeProjectName: (state, action) => {
      const { value } = action.payload;
      const { project } = state;
      // console.log({name, project})
      // setData(name, ["name"], value)
      if (project) project.name = value;
    },
    changeSceneTag: (state, action) => {
      const { value } = action.payload;
      const { project, selectedSceneIdx } = state;
      if (project) project.scenes[selectedSceneIdx].tag = value;
    },
    changeModelApplyAll: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { scenes }: any = project;
      const { newModel, key } = action.payload;
      const { canvas } = canvasHolder;

      const {scaleX, scaleY} = newModel[selectedSceneIdx];
      scenes.map((scene: any, index: number) => {
        const aiModel = scene.clips.find(
          (clip: any) => clip.type === "aiModel"
        );
        // const sceneModel = current(aiModel)
        if (!aiModel) return;

        if (key === "model") {
          // 마이아바타일 경우 setData 대신 전부 교체
          if (MyAvatarTypes.includes(aiModel.avatarType)) {
            scene.clips = scene.clips.map((clip: Clip) => {
              if (clip.id === aiModel.id) {
                return newModel[index];
              }
              return clip;
            });
          }
  
          const headOnly = newModel[index]?.headOnly
            ? newModel[index].headOnly
            : null;
          const beforHeadOnly = aiModel.headOnly;
          setData(
            aiModel,
            ["headOnly"],
            headOnly ? newModel[index]?.headOnly : null
          );
          const voiceOnly = newModel[index]?.voiceOnly
            ? newModel[index]?.voiceOnly
            : false;
          setData(aiModel, ["voiceOnly"], voiceOnly);
          setData(aiModel, ["model"], newModel[index].model);
          setData(aiModel, ["thumbnailUrl"], newModel[index].thumbnailUrl);
  
          if (index === selectedSceneIdx) {
            modelChangeIamge({
              canvas,
              name: aiModel.id,
              model: aiModel.model,
              beforHeadOnly,
              headOnly,
              voiceOnly,
            });
          }

          changeScriptByModelChange(scene.scripts[0], newModel[index]);
        }
  
        if (key === "position") {
          setData(aiModel, ["top"], newModel[index].top);
          setData(aiModel, ["left"], newModel[index].left);
          setData(aiModel, ['scaleX'], scaleX);
          setData(aiModel, ['scaleY'], scaleY);
        }
      });
    },
    changeModelHeadOnly: (state, action) => {
      const { project, selectedSceneIdx, selectedObjectIdList, selectedObjectTypeList } = state;
      const { scenes }: any = project;
      const { isAll, headOnlys, voiceOnly, isModelDelete } = action.payload;
      const { canvas } = canvasHolder;

      const modelId = scenes[selectedSceneIdx]?.clips?.find((clip: Clip) => {
        return clip.id === selectedObjectIdList?.[0]
      })?.model?.ai_name;

      if(!modelId) return;

      if (isAll) {
        scenes.map((scene: any, index: number) => {
          const aiModel = scene.clips.find(
            (clip: any) => clip.model?.ai_name === modelId
          );
          if (!aiModel) return;
          const headOnly = headOnlys ? headOnlys[index] : null;
          const beforHeadOnly = aiModel.headOnly;
          setData(aiModel, ["headOnly"], headOnlys ? headOnlys[index] : null);
          /**
           * voiceOnly -> boolean;
           */
          const voice = voiceOnly ? voiceOnly : false;
          setData(aiModel, ["voiceOnly"], voice);
          let point;
          if (index === selectedSceneIdx) {
            point = modelChangeIamge({
              canvas,
              name: aiModel.id,
              model: aiModel.model,
              beforHeadOnly,
              headOnly,
              voiceOnly: voice,
            });
          } else {
            point = convertHeadOnlyPoint({
              object: aiModel,
              beforHeadOnly,
              headOnly,
            });
          }
          setData(aiModel, ["left"], point.left);
          setData(aiModel, ["top"], point.top);
          setData(aiModel, ["width"], point.width);
          setData(aiModel, ["height"], point.height);
          setData(aiModel, ["scaleX"], point.scaleX);
          setData(aiModel, ["scaleY"], point.scaleY);
        });
      } else {
        const aiModel = project?.scenes[selectedSceneIdx]?.clips.find(
          (clip: any) => clip.model?.ai_name === modelId
        );
        if (!aiModel) return;
        const headOnly = headOnlys ? headOnlys[0] : null;
        const beforHeadOnly = aiModel.headOnly;
        setData(aiModel, ["headOnly"], headOnly);
        /**
         * voiceOnly ->  boolean;
         */
        const voice = voiceOnly ? voiceOnly : false;
        setData(aiModel, ["voiceOnly"], voice);

        /**
         * isDelete -> Model delete
         */
        const isDelete = isModelDelete ? isModelDelete : false;
        console.log(
          ">>>>>>> changeModelHeadOnly isDelete ",
          { isDelete },
          { isModelDelete }
        );
        setData(aiModel, ["isDelete"], isModelDelete);

        const point = modelChangeIamge({
          canvas,
          name: aiModel.id,
          model: aiModel.model,
          beforHeadOnly,
          headOnly,
          voiceOnly: voice,
          isDelete,
        });
        setData(aiModel, ["left"], point.left);
        setData(aiModel, ["top"], point.top);
        setData(aiModel, ["width"], point.width);
        setData(aiModel, ["height"], point.height);
        setData(aiModel, ["scaleX"], point.scaleX);
        setData(aiModel, ["scaleY"], point.scaleY);
      }
    },

    /**
     * Model Delete
     */
    deleteModel: (state, action) => {
      const { show } = action.payload;
      state.deleteModel = {
        ...state.deleteModel,
        show,
      };
    },

    checkClipExist: (state, action) => {
      const { show } = action.payload;
      state.checkClipExist = {
        ...state.checkClipExist,
        show,
      };
    },

    /**
     * about setBackgroundAudio
     */
    setBackgroundAudio: (state, action) => {
      const { type, audioObj } = action.payload;
      const { selectedSceneIdx, project, backgroundAudio } = state;
      const scene: Scene = project?.scenes[selectedSceneIdx];

      const newObj = { ...audioObj, type };
      if (type === "add") {
        const id = genId("audio", scene?.clips);
        newObj.id = id;

        // state.selectedObjectTypeList = ['media'];
        // state.selectedObjectIdList = [id]
      }
      project.backgroundAudio = newObj;
      backgroundAudio.audio = newObj;
      backgroundAudio.showModal = false;
    },
    setBackgroundAudioVolume: (state, action) => {
      const { volume } = action.payload;
      const { project } = state;

      if (project && project.backgroundAudio) {
        project.backgroundAudio = {
          ...project.backgroundAudio,
          volume,
        };
      }
    },

    showAudioModal: (state, action) => {
      const { showModal, audio } = action.payload;
      state.backgroundAudio = {
        ...state.backgroundAudio,
        showModal,
        audio,
      };
    },

    /**
     * trim audio
     * - show: modal 여부
     * - type: 어떤 동작 하고 있는지 'showModal', 'fetchingError', 'isLoading'
     */
    trimAudio: (state, action) => {
      const {
        show,
        type,
        asset,
        error,
        url,
        isLoading,
        from = "",
        originalDuration,
        newBackgroundObj,
      } = action.payload;
      const { project, backgroundAudio } = state;
      if (!project) return;
      switch (type) {
        case "showModal":
          state.loading = false;
          state.trimAudio = {
            ...state.trimAudio,
            show,
            asset,
            from,
            isLoading: false,
          };
          break;
        case "fetchingError":
          state.trimAudio = {
            ...state.trimAudio,
            trimAsset: {
              error,
              url,
            },
          };
          break;
        case "isLoading":
          state.trimAudio.isLoading = isLoading;
          break;
        case "getTrimAudio":
          state.trimAudio.trimAsset.url = url;
          state.trimAudio.trimAsset.error = "";
          state.trimAudio.trimAsset.originalDuration = originalDuration;
          break;
        case "addBackgroundAudio":
          project.backgroundAudio = newBackgroundObj;
          backgroundAudio.audio = newBackgroundObj;
          break;
        default:
          break;
      }
    },

    changeObject: (state, action) => {
      const {
        ids = state.selectedObjectIdList,
        path,
        key,
        value,
      } = action.payload;
      const {
        project,
        selectedSceneIdx,
        defaultFontFamily,
        reduxCustomFontList,
      } = state;

      const clips = project?.scenes[selectedSceneIdx]?.clips.filter((item) =>
        ids.includes(item.id)
      );

      if (clips?.length) {
        clips.forEach((clip) => {
          if (path[0] !== "lock" && clip.lock) return;
          setData(clip, path, value);
          if (key) {
            const { type } = clip;
            const { canvas } = canvasHolder;
            if (canvas) {
              if (type === "textImage" && key === "fontFamily") {
                console.log(
                  ">>>>> changeObject check textImage ",
                  { type },
                  { clip },
                  { key },
                  { value },
                  { defaultFontFamily: current(defaultFontFamily) as any }
                );
                if (!defaultFontFamily.includes(value)) {
                  // const findFont = reduxCustomFontList.find(
                  //   (item: ClipFonts) => item.fontFamily?.replace(' ', '') === value.replace(' ', '')
                  // );

                  let findFont;
                  const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
                  if(koreanRegex.test(value)) {
                    findFont = reduxCustomFontList.find(
                      (item: ClipFonts) => item.fontFamily?.replace(' ', '') === value.replace(' ', '')
                    )
                  } else {
                    findFont = reduxCustomFontList.find((item: ClipFonts) => {
                      const collator = new Intl.Collator('ko');
                      return collator.compare(item?.fontFamily?.replace(/\s/g, '') ?? "", value?.replace(/\s/g, '') ?? "") === 0;
                    });
                  }
                    
                  // const findFont = reduxCustomFontList.find((item: ClipFonts) => {
                  //   const collator = new Intl.Collator('ko');
                  //   return collator.compare(item?.fontFamily?.replace(/\s/g, '') ?? "", value?.replace(/\s/g, '') ?? "") === 0;
                  // });
                  if (!findFont) return;
                  const tempFindFont = current(findFont) as any;
                  console.log(">>>>> changeObject findFont init findFont ", {findFont: current(findFont)}, tempFindFont);
                  setData(clip, ["src"], findFont?.src);
                  setData(clip, ['savePath'], findFont?.savePath);
                } else {
                  delete clip?.src;
                  delete clip?.savePath;
                }
              }
            switch (key) {
                case "animation":
                  animateObject({ canvas, type, name: clip.id, value });
                  break;

                default:
                  editObject({ canvas, type, name: clip.id, key, value });
                  break;
              }
            }
          }
        });
      }
    },

    setCustomFontSrc: (state, action) => {
      const { findFont, clip } = action.payload;
      console.log('>>>>> changeObject findFont setCustomFontSrc', {findFont}, {clip})
    },

    changeTempo: (state, action) => {
      const { value } = action.payload;
      const { project, selectedSceneIdx } = state;

      const selectedScene = project?.scenes?.[selectedSceneIdx];
      const currentTempo = Number(selectedScene?.tempo || 1);
      const changeTempo = Number(value || 1);

      if (selectedScene) {
        selectedScene.clips = selectedScene.clips.map((clip) => {
          if (clip.type === "captions" && clip.captions) {
            return {
              ...clip,
              captions: clip.captions.map((caption) => {
                return {
                  ...caption,
                  start: Number((caption.start * currentTempo / changeTempo).toFixed(2)),
                  end: Number((caption.end * currentTempo / changeTempo).toFixed(2)),
                }
              })
            }
          }
          return clip;
        });
        selectedScene.tempo = value;
      }
    },
    deleteObjectById: (state, action) => {
      const payload = action.payload;
      const deleteIds = Array.isArray(payload) ? [...payload] : [payload];
      const { project, selectedSceneIdx } = state;
      const scene = project?.scenes[selectedSceneIdx];
      if (scene) {
        scene.clips = [...scene.clips.filter((clip) => !deleteIds.includes(clip.id))];
        const { canvas } = canvasHolder;
        if (canvas) {
          deleteObjects({ canvas, names: deleteIds });
        }
      }
    },
    deleteObject: (state, action) => {
      const { ids = state.selectedObjectIdList } = action.payload;
      const { project, selectedSceneIdx } = state;
      const scene = project?.scenes[selectedSceneIdx];

      if (scene) {
        const { clips } = scene;
        let removeClips = [];
        let keepingAiModel: Clip | null = null;

        const aiModelClips = clips.filter((clip) => clip.type === "aiModel");
        const aiModelSelects = clips
          .filter((clip) => ids.includes(clip.id))
          .filter((clip) => clip.type === "aiModel");

        if (aiModelClips.length <= aiModelSelects.length) {
          keepingAiModel =
            clips.find((clip) => clip.id === scene?.scripts?.[0]?.clipId)
            || clips.find((clip) => clip.type === "aiModel")
            || null;
          removeClips = clips.filter((item) => (
            ids.includes(item.id) && item.id !== keepingAiModel?.id
          ));
        } else {
          removeClips = clips.filter((item) => ids.includes(item.id));
        }

        const { canvas } = canvasHolder;

        if (canvas) {
          if (removeClips.length) {
            deleteObjects({ canvas, names: removeClips.map(({ id }) => id) });
          }
          if (keepingAiModel) {
            setData(keepingAiModel, ["isDelete"], true);
            modelChangeIamge({
              canvas,
              name: keepingAiModel.id,
              model: keepingAiModel.model,
              beforHeadOnly: keepingAiModel.headOnly,
              headOnly: keepingAiModel.headOnly,
              voiceOnly: keepingAiModel.voiceOnly,
              isDelete: true,
            });
          }

          const removeClipIds = removeClips.map((clip) => clip.id);
          const resultClips = clips
            .filter((clip) => !removeClipIds.includes(clip.id))
            .sort((a, b) => a.layer - b.layer)
            .map((clip, index) => ({ ...clip, layer: index + 1 }));
            
          scene.clips = resultClips;
  
          sortLayer({ canvas, scene: current(scene) as Scene });

          canvas?.discardActiveObject();
          canvas?.requestRenderAll();

          const resultAiModels = resultClips
            .filter((clip) => clip.type === "aiModel");
          const resultAiModelIds = resultAiModels
            .map((clip) => clip.id);

          scene.scripts = scene.scripts
            .filter((script, index, array) => {
              if (
                array.length > 1
                && index === array.length - 1
                && !resultAiModelIds.includes(script.clipId)
              ) {
                return false;
              }
              return true;
            })
            .map((script) => {
              if (!resultAiModelIds.includes(script.clipId)) {
                return {
                  ...script,
                  org: removeByTagName(script.org, "gesture"),
                  clipId: resultAiModels[0].id,
                  modelId: resultAiModels[0].model.ai_name,
                  clothId: resultAiModels[0].model.emotion,
                  gender: resultAiModels[0].model.gender,
                  modelSupportLanguage: resultAiModels[0].model.language,
                };
              }
              return script;
            });

          state.selectedObjectIdList = [];
          state.selectedObjectTypeList = [];
          state.selectedAvatarType = null;
          state.selectedLeftSubTab = null;
        }
      }
    },
    // audio object delete -> sceneIndex
    deleteAudioObjectById: (state, action) => {
      const { deleteId, sceneIdx } = action.payload;
      const { project } = state;
      const scene = project?.scenes[sceneIdx];
      if (scene) {
        const { clips } = scene;
        const clipIndex = clips.findIndex((row) => row.id === deleteId);
        clips.splice(clipIndex, 1);
      }
    },

    /**
     * Crop Image
     * 1. crop 위한 선택 영역 보여주기
     * 2. 사용자가 원하는 영역까지 드래그 가능하게
     * 3. 실제 cropBtn에서 crop 호출하기 전까지 기능 수행
     * 4. crop할 영역을 잡는 박스는 실제 canvas에서는 그려지지만 clips에는 가지고 있지 않다!!!!(주의!!!!)
     */
    cropImage: (state, action) => {
      const { selectedObjectIdList, selectedSceneIdx, project } = state;
      const { canvas } = canvasHolder;
      // const { imageId } = action.payload;
      const scene = project?.scenes[selectedSceneIdx];
      const id = genId("image", scene.clips);
      console.log(">>>>>>> cropImage init", { scene });

      if (!canvas) return;
      if (scene) {
        const { clips } = scene;
        const image =
          selectedObjectIdList &&
          selectedObjectIdList?.length > 0 &&
          clips.find((clip) => clip.id === selectedObjectIdList[0]);
        if (!image) return;

        const temp = current(image) as Clip;
        /**
         * Crop할 원래 이미지 정보를 가지고 있기 위한 부분 (Redux에서 원래 이미지 값을 항상 지니고 있다)
         */
        const tempSelectedObjectIdList = current(selectedObjectIdList) as any;
        const tempClips = current(clips) as any;
        console.log(
          ">>>>> cropImage redux",
          { tempSelectedObjectIdList },
          { temp },
          { tempClips }
        );

        state.cropImageInfo.targetImage = { ...temp };

        /**
         * Crop하기 위한 부분
         * 1. origin Image
         *    -> lock true
         *    -> opacity 0.5
         * 2. createMaskImage 호출
         *    -> canvas-utils.ts
         *
         * 3. crop, cancel 버튼 canvas그리는걸로 바뀜
         */
        editObject({ canvas, name: temp.id, key: "lock", value: true });
        editObject({ canvas, name: temp.id, key: "opacity", value: 90 });
        createMaskImage({ canvas, image: temp, id });

        /**
         * Crop 영역 SelectedObject 형태로 만들어야 -> TopToolBar 유지 가능
         * - selectedObjectTypeList = ['media']
         * - selectedObjectIdList = [object.name]
         */
        const cropObject = canvas
          ?.getObjects()
          .find((object) => object.name?.includes("crop"));
        if (cropObject) {
          state.selectedObjectIdList = [cropObject?.name];
          // state.selectedObjectTypeList = ['media'];
        }
      }
    },

    /**
     * CropBtn에서 실제 버튼을 눌러서 호출하는 시점
     * 1. crop
     * - crop 기능 호출 고
     * 2. cancel
     * - cancel 기능 호출 고
     */
    callCropImage: (state, action) => {
      const { key, dispatch } = action.payload;
      const { canvas } = canvasHolder;
      const { project, selectedSceneIdx, selectedObjectIdList } = state;
      const scene = project?.scenes[selectedSceneIdx];
      const targetImageClip = current(state.cropImageInfo.targetImage) as any;

      /**
       * crop 할 이미지 제일 앞으로 보내준다.
       */

      if (scene && selectedObjectIdList && selectedObjectIdList?.length > 0) {
        const updatedClips = updateLayerById(
          scene.clips as Clip[],
          selectedObjectIdList[0],
          "front"
        );
        updatedClips?.sort((a, b) => a.layer - b.layer);
        scene.clips = updatedClips as WritableDraft<Clip>[];
        if (canvas) sortLayer({ canvas, scene: current(scene) as Scene });
      }

      const result = setCropImage({
        canvas,
        imageName: targetImageClip?.name || targetImageClip?.id,
        source_url: targetImageClip.source_url,
        key,
      });

      if (key === "crop") {
        const deleteClipId = targetImageClip.id;
        if (scene) {
          const { clips } = scene;
          const clipIndex = clips.findIndex((row) => row.id === deleteClipId);
          const clipObject = clips.find((row) => row.id === deleteClipId);

          const originalUrl = clipObject?.original_url || "";

          if (clipIndex > -1) clips.splice(clipIndex, 1);

          result.then((params: any) => {
            if (params) {
              const { cropUrl, selectionRect, originImage } = params;
              const timestamp: number = Date.now();
              const random: number = Math.floor(Math.random() * 1000);
              const id = `image-${timestamp.toString(32)}${random.toString(32)}`;

              const newObj = {
                ...clipObject,
                type: "image",
                source_url: cropUrl,
                width: selectionRect.width,
                height: selectionRect.height,
                top: selectionRect.top,
                left: selectionRect.left,
                scaleX: originImage.scaleX,
                scaleY: originImage.scaleY,
                id,
                afterCrop: true,
                cropUrl,
              };
              if (originalUrl && originalUrl !== "") {
                newObj.original_url = originalUrl;
              }
              console.log(">>>>>> projectMiddleware state newObj ", { newObj });
              addNewCropImage(newObj, canvas);
              // dispatch(addObjectAsync(newObj));
            }
          });
        }
      }

      removeCropButton({ canvas });
      state.cropImageInfo = {
        ...state.cropImageInfo,
        targetImage: {},
      };
    },

    //canvas ratio
    changeCanvasRatio: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { orientation } = action.payload;

      if (project && project.orientation !== orientation) {
        const canvasSize = orientation === "portrait"
          ? { x: 1080, y: 1920 } : { x: 1920, y: 1080 };

        const newProject =  {
          ...project,
          orientation,
          scenes: project.scenes.map((scene) => ({
            ...scene,
            clips: scene.clips.map((clip) => ({
              ...clip,
              top: clip.top + (canvasSize.y - canvasSize.x) / 2,
              left: clip.left + (canvasSize.x - canvasSize.y) / 2,
            }))
          })),
        }
        state.project = newProject;
        const { canvas } = canvasHolder;
        initCanvasSize({
          canvas,
          orientation,
          width: canvasSize.x,
          height: canvasSize.y,
        });
        initCanvasScene({
          canvas,
          scene: newProject.scenes[selectedSceneIdx],
          smooth: true,
        });
      }
    },

    // script
    changeScript: (state, action) => {
      const { value, index } = action.payload;
      const { project, selectedSceneIdx } = state;
      const scene = project?.scenes[selectedSceneIdx];
      if (scene) {
        const scripts = scene.scripts;
        if (scripts && scripts[index]) {
          const script = scripts[index];
          const aiModelClip = scene?.clips.find(
            (c: Clip) => c.id === script.clipId
          );
          // console.log("!@#!@#!@# aiModelClip", current(aiModelClip));
          // console.log("!@#!@#!@# changeScript ", script, value);
          setData(script, ["org"], value);
          setData(script, ["gender"], aiModelClip?.model.gender);
        }
      }
    },
    changeMultipleSendTag: (state, action) => {
      const { tag: value, add } = action.payload;
      const { project, multipleSendInfo } = state;

      if (add) {
        if (!project?.multipleSendTags) project.multipleSendTags = [];
        if (!project.multipleSendTags.find((tag) => tag === value)) {
          project.multipleSendTags.push(value);
        }
      } else {
        delete multipleSendInfo?.values?.[value];
        if (project?.multipleSendTags) {
          project.multipleSendTags = project.multipleSendTags.filter((tag) => tag !== value);
        }
      }
    },
    changeScriptsByModelChange: (state, action) => {
      const { selectedModelId, newModelObj } = action.payload;
      const { project, selectedSceneIdx } = state;
      const scene = project?.scenes[selectedSceneIdx];
      if (scene) {
        const scripts = scene.scripts;
        if (scripts) {
          scripts
          .filter((obj) => obj.clipId === selectedModelId)
          .forEach((script) => {
            changeScriptByModelChange(script, newModelObj);
          });      
        }
      }
    },
    addConversationItem: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { random } = action.payload;
      const scene = project?.scenes[selectedSceneIdx];
      const { scripts } = scene;
      if (scripts) {
        // const lastScript = scripts[scripts.length - 1];
        // const newScript = {
        //   ...lastScript,
        //   org: "",
        //   pause: null
        // }
        const newScript: Scripts = {
          org: "",
          modelId: "",
          modelSupportLanguage: "",
          clipId: "",
          pause: null,
          clothId: "",
          gender: "",
          isTTS: false,
        };
        scripts.push(newScript);
      }
    },

    removeConversationItem: (state, action) => {
      const { index } = action.payload;
      const { project, selectedSceneIdx } = state;
      const { scripts } = project?.scenes[selectedSceneIdx];
      if (scripts) {
        scripts.splice(index, 1);
      }
    },

    changeConversationItemModel: (state, action) => {
      const { index, modelId, clipId, modelSupportLanguage, gender } = action.payload;
      const { project, selectedSceneIdx } = state;
      const { scripts } = project?.scenes[selectedSceneIdx];
      if (scripts) {
        const script = scripts[index];
        const modelClip = project?.scenes[selectedSceneIdx].clips.find((c: Clip) => c?.model?.ai_name === modelId)
        const targetScript = scripts.find((c: Scripts) => c.modelId === modelId);
        // console.log("!@#!@#!@# changeScript ", script, modelId, clipId, index);
        setData(script, ["org"], removeByTagName(script.org, "gesture"));
        setData(script, ["clipId"], clipId);
        setData(script, ["modelId"], modelId);
        setData(script, ["clothId"], modelClip?.model.emotion);
        setData(script, ["gender"], modelClip?.model.gender ?? gender);
        setData(script, ["modelSupportLanguage"], modelSupportLanguage);
        setData(script, ["isTTS"], false);
        setData(script, ["tts"], null);
      }
    },

    changeConversationItemPause: (state, action) => {
      const { index, pause } = action.payload;
      const { project, selectedSceneIdx } = state;
      const { scripts } = project?.scenes[selectedSceneIdx];
      if (scripts) {
        const script = scripts[index];
        setData(script, ["pause"], pause);
      }
    },

    initScripts: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const scene = project?.scenes[selectedSceneIdx];
      if (scene) {
        const { scripts } = scene;
        const { clips } = scene;
        const deepCopiedScripts = [...current(scripts)];
        // console.log("!@#!@#!@# deepCopiedScripts", deepCopiedScripts)
        // console.log("!@#!@#!@# deepCopiedScripts", deepCopiedScripts.shift())
        // console.log("!@#!@#!@# deepCopiedScripts", deepCopiedScripts);
        const firstScript = deepCopiedScripts.shift();
        setData(scene, ["scripts"], [firstScript]);
        scene.clips
          .filter((c: Clip) => c.type === "aiModel")
          .forEach((clip: Clip) => {
            if (clip.id === firstScript?.clipId) return;
            const clipIndex = clips.findIndex(
              (row: Clip) => row.id === clip.id
            );
            clips.splice(clipIndex, 1);
            const { canvas } = canvasHolder;
            if (canvas) {
              deleteObjects({ canvas, names: [clip.id] });
            }
          });
      }
    },

    changeEditorKey: (state) => {
      if (!Number.isInteger(state.editorKey)) {
        state.editorKey = 0
      } else {
        if (state.editorKey > 0) {
          state.editorKey--;
        } else {
          state.editorKey++;
        }
      };
    },

    setDetectedLanguage: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { scriptIndex, detectedLanguage } = action.payload;
      const scene = project?.scenes[selectedSceneIdx];
      if( scene ) {
        const script = scene.scripts[scriptIndex ?? 0];
        setData(script, ["detectedLanguage"], detectedLanguage);
      }
    },

    setDetectionMarked: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { scriptIndex, isMarked } = action.payload;
      const scene = project?.scenes[selectedSceneIdx];
      if( scene ) {
        const script = scene.scripts[scriptIndex ?? 0];
        setData(script, ["detectionMarked"], isMarked);
      }
    },

    changeAudioScriptFile: (state, action) => {
      const { name, path, duration } = action.payload;
      const { project, selectedSceneIdx } = state;

      const aiModel = state.project?.scenes[selectedSceneIdx]?.clips.find(
        ({ type }) => type === "aiModel"
      );

      const entry = {
        type: "audio",
        url: path,
        name,
        duration,
      };

      const scene = project?.scenes[selectedSceneIdx];
      if (aiModel) {
        if (path) {
          setData(aiModel, ["script", "tts"], entry);
          setData(scene?.scripts[0], ["tts"], entry);
          setData(scene?.scripts[0], ["isTTS"], true);
        } else {
          setData(aiModel, ["script", "tts"], null);
          setData(scene?.scripts[0], ["tts"], null);
          setData(scene?.scripts[0], ["isTTS"], false);
        }
      }
    },

    changeVoice: (state, action: PayloadAction<{
      value: any;
      /**
       * 모든 씬의 동일 모델에 적용할 때
       * @default false
       */
      everyScene?: boolean;
      modelIndex?: number;
      /**
       * 다화자인 경우 스크립트 인덱스
       * @default 0
       */
      scriptIndex?: number;
    }>) => {
      const { 
        value, 
        everyScene = false, 
        modelIndex, 
        scriptIndex = 0 
      } = action.payload;
      const { selectedSceneIdx } = state;
      console.log("!@#!@#!@# changeVoice ", value);
      const newValue = {...value, clothes: []}
      const scenes = state.project?.scenes ?? []
      const scene = scenes[selectedSceneIdx]
      if( scene ) {
        const script = scene.scripts[scriptIndex];

        if (!script) {
          return
        }

        const {
          avatarType: modelType,
          model: {
            ai_name: modelId,
          },
        } = scene.clips.find((clip) => (
          clip.type === 'aiModel' && clip.model.ai_name === script.modelId
        )) || {}

        const hasOriginalVoice = (() => {
          if (typeof modelType !== "undefined" && modelType !== "original") {
            return false;
          }

          return script.modelId === value.model;
        })();

        const tts = hasOriginalVoice ? null : newValue
        const isTTS = !hasOriginalVoice

        if (everyScene) {
          scenes.forEach(({
            scripts,
          }) => {
            scripts.forEach((_script) => {
              if (_script.modelId !== modelId) {
                return
              }

              /**
               * 오디오 스크립트인 경우에는 변경하지 않음
               */
              if (_script.tts?.type === 'audio') {
                return
              }

              _script.tts = tts
              _script.isTTS = isTTS
            })
          })
        }
        else {
          script.tts = tts
          script.isTTS = isTTS
        }
      }

      // if(everyScene) {
      //   state.project?.scenes.forEach((s)=>{
      //     const aiModel = s.clips.filter(
      //       ({ type }) => type === "aiModel"
      //     )[modelIndex];
          
      //     if (aiModel) {
      //       const hasOriginalVoice = aiModel.model.ai_name === value.id;
      //       setData(aiModel, ["script", "tts"], newValue);
      //       setData(aiModel, ["script", "isTTS"], !hasOriginalVoice);
      //       if(s?.scripts && s.scripts?.length > 0) {
      //         s.scripts.forEach((script) => {
      //           if(script.modelId === aiModel.model.ai_name) {
      //             setData(script, ["tts"], newValue);
      //             setData(script, ["isTTS"], !hasOriginalVoice);
      //           }
      //         })
      //       }

      //     }
      //   })
      // } else {
      //   const scene = state.project?.scenes[selectedSceneIdx]
      //   const aiModel = scene?.clips.filter(
      //     ({ type }) => type === "aiModel"
      //   )[modelIndex];
  
      //   if (aiModel) {
      //     const hasOriginalVoice = aiModel.model.ai_name === value.id;
      //     setData(aiModel, ["script", "tts"], newValue);
      //     setData(aiModel, ["script", "isTTS"], !hasOriginalVoice);
      //     if(scene?.scripts && scene.scripts?.length > 0) {
      //       scene.scripts.forEach((script) => {
      //         if(script.modelId === aiModel.model.ai_name) {
      //           setData(script, ["tts"], newValue);
      //           setData(script, ["isTTS"], !hasOriginalVoice);
      //         }
      //       })
      //     }

      //   }
      // }
    },
    replaceDeletedVoice: (state, action) => {
      state.project = action.payload;
    },
    clickGestureButton: (state, action) => {
      const { gestureId, elementId, position, focusedScriptIndex } =
        action.payload;
      if (state.gestureInfo) {
        state.gestureInfo = {
          ...action.payload,
          open: !state.gestureInfo.open,
        };
      } else {
        setData(state, ["gestureInfo"], { ...action.payload, open: true });
      }
    },

    removeGestureMenu: (state, action) => {
      setData(state, ["gestureInfo"], null);
    },

    resetMultipleSendTag: (state, action) => {
      setData(state, ["multipleSendInfo"], null);
    },

    clickMultipleSendTagButton: (state, action) => {
      const { target } = action.payload;

      if (state.multipleSendInfo) {
        const { multipleSendInfo } = state;
        const values = { ...multipleSendInfo.values };
        if (!values?.[target]) values.target = [];

        state.multipleSendInfo = {
          ...action.payload,
          open: !multipleSendInfo.open,
          values,
        };
      } else {        
        setData(state, ["multipleSendInfo"], { ...action.payload, open: true, values: { [target]: [] } });
      }      
    },

    /**
     * 멀티플 센드 태그 입력 모달을 아무 작업 없이 닫기만 하는 기능
     */
    closeMultipleSendTagInputModal: (state) => {
      const { multipleSendInfo } = state

      /**
       * undefined인 경우에는 이미 닫혀있는 상태임
       */
      if (!multipleSendInfo) {
        return
      }

      multipleSendInfo.open = false
    },

    saveMultipleSendTagValues: (state, action) => {
      const { values: newValues } = action.payload;
      const { multipleSendInfo } = state;

      if (newValues.length <= 0) delete multipleSendInfo?.values?.[multipleSendInfo?.target];
      else multipleSendInfo.values[multipleSendInfo.target] = newValues;
      multipleSendInfo.open = false;
    },

    setOpenedModal: (state, action) => {
      state.openedModal = action.payload;
    },

    setAutomationPreventAction: (state, action) =>{
      const { okText, cancelText, alertText} = action.payload;
      state.automationPrevention = {
        ...state.automationPrevention,
        okText,
        cancelText,
        alertText
      }
    },
    setOpenCheckConfirmModal: (state, action) => {
      const { open } = action.payload
      state.automationPrevention.openCheckModal = open;
    },
    setOpenCheckConfirmModalFromKeyDown: (state, action) => {
      const { open } = action.payload
      state.automationPrevention.alertModal = open;
    },
    setOpenExitPreventModal: (state, action) => {
      const { open } = action.payload
      state.openExitPreventModal = open;
    },

    openLoginModal: (state, action) => {
      state.openedModal = MODAL_NAME.LOGIN;
      state.callbackAfterLogin = action.payload;
    },

    openLoginModalMobile: (state, action) => {
      state.openedModal = MODAL_NAME.LOGIN;
      state.callbackAfterLogin = action.payload;
    },

    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
      const steps = getStepFromCurrentPlan(action.payload);
      state.planStep.personalPlanStep = +steps[0];
      state.planStep.teamPlanStep = +steps[1];
    },

    setAutomationInfo: (state, action) =>{
      const { type, content } = action.payload;
      state.automationInfo = {...state.automationInfo, type, content}
    },
    setNoticeStrings: (state, action) => {
      const { noticeTitle, noticeExplains } = action.payload;
      state.noticeTitle = noticeTitle;
      state.noticeExplains = noticeExplains;
    },

    setNoticeModalCredit: (state, action) => {
      const { currentPlan, openedModal, noticeTitle, noticeExplains, modalTitle, modalLogoUrl,
        decryptParams,
        buttonText
       } =
        action.payload;
      state.currentPlan = currentPlan;
      const steps = getStepFromCurrentPlan(currentPlan);
      state.planStep.personalPlanStep = steps[0];
      state.planStep.teamPlanStep = steps[1];
      state.openedModal = openedModal;
      state.modalTitle = modalTitle;
      state.modalLogoUrl = modalLogoUrl
      state.noticeTitle = noticeTitle;
      state.noticeExplains = noticeExplains;

      if(decryptParams) state.decryptParams = decryptParams;
      if(buttonText) state.buttonText = buttonText;
    },

    setCurrentPlanAndOpenedModal: (state, action) => {
      const { currentPlan, openedModal } = action.payload;
      state.currentPlan = currentPlan;
      const steps = getStepFromCurrentPlan(currentPlan);
      state.planStep.personalPlanStep = steps[0];
      state.planStep.teamPlanStep = steps[1];
      state.openedModal = openedModal;
    },

    setClientSecret: (state, action) => {
      state.clientSecret = action.payload;
    },

    setCheckoutData: (state, action) => {
      state.checkoutData = action.payload;
    },

    setPlanStep: (state, action) => {
      const { type, step } = action.payload;
      state.planStep[type] = step;
    },
    setPlanRecommend: (state, action) => {
      state.planStep.reco = action.payload;
    },

    setOpenDictionaryPopup: (state, action) => {
      const { word, value } = action.payload;
      state.openDictionaryPopup = value;

      if (word) {
        state.currentDictionaryWordVal = state.project?.dictionary[word];
      }
    },

    setOpenPausePopupId: (state, action) => {
      const { value } = action.payload;
      state.openPausePopupId = value;
    },

    setLastSelection: (state, action) => {
      const { value } = action.payload;
      state.lastSelection = value;
    },

    addDictionaryEntry: (state, action) => {
      const { word, value } = action.payload;
      const { selectedSceneIdx, project } = state;
      state.project.dictionary[word] = value;

      const aiModel = state.project?.scenes[selectedSceneIdx]?.clips.find(
        ({ type }) => type === "aiModel"
      );

      let script = aiModel?.script?.org || "";

      if (aiModel) {
        // setData(aiModel, ["script", "org"], newScript);

        setData(aiModel, ["script", "org"], script);
      }
    },

    removeDictionaryEntry: (state, action) => {
      const { word } = action.payload;
      const { selectedSceneIdx, project } = state;

      delete state.project.dictionary[word];

      state.lastSelection = "";

      const aiModel = state.project?.scenes[selectedSceneIdx]?.clips.find(
        ({ type }) => type === "aiModel"
      );

      let script = aiModel?.script?.org || "";

      if (aiModel) {
        setData(aiModel, ["script", "org"], script);
      }
    },

    setCurrentDictionaryWordVal: (state, action) => {
      const { value } = action.payload;

      state.currentDictionaryWordVal = value;
    },

    // selected template thumbnail url add
    selectedTemplates: (state, action) => {
      const { sceneIdx, temp } = action.payload;
      state.selectedTemplates[sceneIdx] = temp;
    },

    clearSelectedTemplates: (state) => {
      state.selectedTemplates = [];
    },

    // selected template all into the scene
    addAllTemplateScene: (state, action) => {
      const { templates } = action.payload;
      const { canvas } = canvasHolder;
      const { project, selectedSceneIdx } = state;

      project?.scenes.splice(selectedSceneIdx + 1, 0, ...templates);
      state.listenButtonAndAudioInfo.splice(selectedSceneIdx + 1, 0, ...templates.map(() => ({
        audio: {
          status: "idle",
          url: "",
          isFirstListen: false,
        },
        isListenButtonShow: true
      })));
      state.selectedSceneIdx = selectedSceneIdx + 1;
      state.selectedObjectIdList = [];
      state.selectedObjectTypeList = [];
      state.selectedAvatarType = null;

      const scene = project?.scenes[selectedSceneIdx + 1];
      initCanvasScene({ canvas, scene, smooth: true });
    },

    // selected template into scene only one
    addSelectTemplateScene: (state, action) => {
      const { template } = action.payload;
      const { canvas } = canvasHolder;
      const { project, selectedSceneIdx } = state;

      project?.scenes.splice(selectedSceneIdx + 1, 0, template as Scene);
      state.listenButtonAndAudioInfo.splice(selectedSceneIdx + 1, 0, {
        audio: {
          status: "idle",
          url: "",
          isFirstListen: false,
        },
        isListenButtonShow: true
      });
      state.selectedSceneIdx = selectedSceneIdx + 1;
      state.selectedObjectIdList = [];
      state.selectedObjectTypeList = [];
      state.selectedAvatarType = null;

      if (project && template?.multipleSendTags) project.multipleSendTags = template.multipleSendTags;

      const temp = project?.scenes[selectedSceneIdx + 1];
      initCanvasScene({ canvas, scene: temp, smooth: true });
    },
    redo: (state, action) => {
      const { project, selectedSceneIdx, undoStack, redoStack } = state;
      if (redoStack.length <= 0) return;
      if (project) {
        const newProject = {
          ...project,
          scenes: project.scenes.map((_scene: any) => ({
            ..._scene,
            thumbnailUrl: null,
          })),
          thumbnailUrl: null,
        };
        const newStack = [...undoStack, newProject];
        state.undoStack = newStack;

        const preProject = state.redoStack.pop();
        state.project = { ...preProject };
        state.selectedObjectIdList = [];
        state.selectedObjectTypeList = [];
        state.selectedAvatarType = null;

        const { canvas } = canvasHolder;
        const scene = preProject.scenes[selectedSceneIdx];
        if (scene) {
          initCanvasScene({
            canvas,
            scene: current(scene) as any,
            smooth: true,
          });
        }

        state.listenButtonAndAudioInfo = (preProject?.scenes || []).map(() => ({
          audio: {
            status: "idle",
            url: "",
            isFirstListen: false,
          },
          isListenButtonShow: true
        })) 

      }
    },
    undo: (state, action) => {
      const { project, selectedSceneIdx, undoStack, redoStack } = state;
      if (undoStack.length <= 0) return;
      if (project) {
        const newProject = {
          ...project,
          scenes: project.scenes.map((_scene: any) => ({
            ..._scene,
            thumbnailUrl: null,
          })),
          thumbnailUrl: null,
        };
        const newStack = [...redoStack, newProject];
        state.redoStack = newStack;

        const preProject = state.undoStack.pop();
        state.project = { ...preProject };
        state.selectedObjectIdList = [];
        state.selectedObjectTypeList = [];
        state.selectedAvatarType = null;

        const { canvas } = canvasHolder;
        const scene = preProject.scenes[selectedSceneIdx];
        if (scene) {
          initCanvasScene({
            canvas,
            scene: current(scene) as any,
            smooth: true,
          });
        }

        state.listenButtonAndAudioInfo = (preProject?.scenes || []).map(() => ({
          audio: {
            status: "idle",
            url: "",
            isFirstListen: false,
          },
          isListenButtonShow: true
        })) 


      }
    },
    saveCurrentProjectState: (state, action) => {
      const { project, undoStack } = state;
      const STACK_MAX_SIZE = 50;
      const saveProject = action.payload;

      if (project) {
        const newProject = {
          ...saveProject,
          scenes: saveProject.scenes.map((_scene: any) => ({
            ..._scene,
            thumbnailUrl: null,
          })),
          thumbnailUrl: null,
        };
      
        let newStack = [...undoStack, newProject];
        if (newStack.length > STACK_MAX_SIZE) newStack.shift();
        state.undoStack = newStack;
        state.redoStack = [];
      }
    },
    setSubscriptionDataLayer: (state, action) => {
      state.subscriptionDataLayer = action.payload;
    },

    /**
     * 처음 프로젝트 진입했을 때 자막 있는지 없는지 여부 확인
     * - 자막 있으면 state.captionActions.originalCaptions Setting
     */
    initCaptions: (state) => {
      const { project, selectedSceneIdx } = state;
      const scene = project?.scenes?.[selectedSceneIdx];
      const { canvas } = canvasHolder;
      if (!scene) return;

      const captions = scene.clips.find((clip) => clip.type === "captions");
      const previewCaptions = canvas
        ?.getObjects()
        .find((obj) => obj.type === "captions-preview");
      if (!captions) {
        state.captionActions.originalCaptions = [];
        return;
      }
      if (previewCaptions) {
        deleteObjects({ canvas, names: previewCaptions?.name });
      }
      state.captionActions.originalCaptions = captions.captions;
    },
    /**
     * 자막 관련 작업
     * - 오디오 프리뷰를 위한 작업
     */
    initCaptionAudio: (state, action) => {
      const { type, downloadUrl } = action.payload;
      switch (type) {
        case "loading":
          state.captionActions.audio.status = "loading";
          break;
        case "initAudio":
          state.captionActions.audio.status = "idle";
          state.captionActions.audio.url = "";
          // state.captionActions.originalCaptions = [];
          break;
        case "getURL":
          state.captionActions.audio.status = "loading";
          state.captionActions.audio.url = downloadUrl;
          //state.captionActions.audio.status = 'idle';
          break;
        default:
          break;
      }
    },

    setPreviewButtonDisable : (state, action) =>{
      const { isPreviewButtonDisable } = action.payload;
      state.previewButton.isPreviewButtonDisable = isPreviewButtonDisable;
    },
    /**
     * 자막 관련 작업
     * - 클릭했을 때 해당 자막 텍스트 맞게 캔버스에 보여주기 위함.
     */
    clickCaption: (state, action) => {
      const { text, dispatch } = action.payload;
      const { canvas } = canvasHolder;

      const captionObject = canvas
        ?.getObjects()
        .find((object) => object.name?.startsWith("captions"));
      if (!captionObject) return;
      editObject({
        canvas,
        name: captionObject.name,
        key: "text",
        value: text,
      });
    },
    /**
     * 자막 관련 작업
     * - 자막 텍스트 수정 작업.
     */
    setCaptionText: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const { text, index, type, time } = action.payload;
      const { canvas } = canvasHolder;

      const scene = project?.scenes[selectedSceneIdx];
      if (!scene) return;
      const caption = scene.clips.find((clip) => clip.type === "captions");
      let checkCaptionChange = false;
      console.log(">>>>>> setCaptionText caption ", {
        caption: current(caption) as any,
      });

      switch (type) {
        case "text":
          caption.text = text;
          caption.captions[index].caption = text;
          for (const val of state.captionActions.originalCaptions) {
            if (val.caption !== text) {
              checkCaptionChange = true;
            } else {
              checkCaptionChange = false;
            }
          }

          // state.captionActions.index = index;
          state.captionActions.checkCaptionChange = checkCaptionChange;

          const captionObject = canvas
            ?.getObjects()
            .find((object) => object.name?.startsWith("captions"));
          if (!captionObject) return;
          editObject({
            canvas,
            name: captionObject.name,
            key: "text",
            value: text,
          });
          break;
        case "start":
          caption.captions[index].start = time;
          for (const val of state.captionActions.originalCaptions) {
            if (val.start !== time) {
              checkCaptionChange = true;
            } else {
              checkCaptionChange = false;
            }
          }
          state.captionActions.checkCaptionChange = checkCaptionChange;
          break;
        case "end":
          caption.captions[index].end = time;
          for (const val of state.captionActions.originalCaptions) {
            if (val.end !== time) {
              checkCaptionChange = true;
            } else {
              checkCaptionChange = false;
            }
          }
          state.captionActions.checkCaptionChange = checkCaptionChange;
          break;
        default:
          break;
      }
    },

    /**
     * 자막 관련 작업
     * - 동작 관련하여 작업
     * 1. MouseOver -> 삭제 아이콘
     * 2. addSubtitle -> 자막 추가 작업
     * 3. generateSubtitle -> subtitle 최초 생성 했을 때 자막 텍스트 저장하기 위함
     * 4. removeAllSubtitle -> subtitle 전체 지우기
     * 5. clearAllChangeSubtitle -> 하나라도 바뀐 자막 텍스트 있을 때 해당 액션은 바뀐거에서 원래 있던 최초의 자막 값으로 변경
     */
    setCaptionActions: (state, action) => {
      const {
        mouseOver = false,
        index = 0,
        type = "",
        originalCaptions,
        closeSubtitleAlert = false,
        hideCaptions = false,
        isLoading = false,
        data = "",
        selectedCaptionIdx = 0,
        isPlaying = "idle",
        status = "idle",
        end,
        start,
      } = action.payload;
      const { project, selectedSceneIdx } = state;
      const { canvas } = canvasHolder;

      const scene = project?.scenes[selectedSceneIdx];
      if (!scene) return;
      const caption = scene.clips.find((clip) => clip.type === "captions");
      const captionIndex = scene.clips.findIndex(
        (clip) => clip.type === "captions"
      );

      switch (type) {
        case "loading":
          state.captionActions.isLoading = isLoading;
          break;
        case "mouseOver":
          state.captionActions.mouseOver = mouseOver;
          if (mouseOver) {
            state.captionActions.index = index;
            state.captionActions.type = "";
          } else {
            state.captionActions.index = 0;
            state.captionActions.type = "";
          }
          state.captionActions.checkCaptionChange = false;
          state.captionActions.isLoading = false;
          break;
        case "addSubtitle":
          const newCaption = { caption: "", start, end };
          if(index === caption?.captions?.length -1) caption.captions?.push(newCaption);
          else caption.captions.splice(index + 1, 0, newCaption);

          state.captionActions.mouseOver = false;
          state.captionActions.index = 0;
          state.captionActions.type = "";
          state.captionActions.checkCaptionChange = true;
          state.captionActions.isLoading = false;
          break;
        case "removeSubtitle":
          caption.captions.splice(index, 1);

          state.captionActions.mouseOver = false;
          state.captionActions.index = 0;
          state.captionActions.type = "";
          state.captionActions.checkCaptionChange = true;
          state.captionActions.isLoading = false;
          state.captionActions.originalCaptions = caption.captions;
          editObject({
            canvas,
            name: caption?.id,
            key: "captions",
            value: current(caption.captions) as any,
          });
          break;
        case "hideSubtitle":
          state.captionActions.hideCaptions = hideCaptions;
          editObject({
            canvas,
            name: caption?.id,
            key: "opacity",
            value: hideCaptions ? 0 : 100,
          });
          break;
        case "generateSubtitle":
          // original subtitle 저장 하기 -> 최초 생성 되었을 때 텍스트 저장하고 가지고 있기
          state.captionActions.originalCaptions = originalCaptions;
          state.captionActions.mouseOver = false;
          state.captionActions.index = 0;
          state.captionActions.type = "";
          state.captionActions.checkCaptionChange = false;
          state.captionActions.isLoading = false;
          break;
        case "removeAllSubtitle":
          if (canvas) {
            deleteObjects({ canvas, names: caption.id });
            const previewCaption = canvas
              .getObjects()
              .find((obj) => obj.type === "captions-preview");
            if (previewCaption)
              deleteObjects({ canvas, names: previewCaption.name });
          }
          scene.clips.splice(captionIndex, 1);

          state.captionActions.mouseOver = false;
          state.captionActions.index = 0;
          state.captionActions.type = "";
          state.captionActions.originalCaptions = [];
          state.captionActions.checkCaptionChange = false;
          state.captionActions.isLoading = false;
          break;
        case "clearAllChangeSubtitle":
          caption.captions = state.captionActions.originalCaptions[0].captions;
          caption.captions = state.captionActions.originalCaptions;

          state.captionActions.mouseOver = false;
          state.captionActions.index = 0;
          state.captionActions.type = "";
          state.captionActions.checkCaptionChange = false;
          state.captionActions.isLoading = false;
          break;
        case "closeSubtitleAlert":
          state.captionActions.closeSubtitleAlert = closeSubtitleAlert;
          state.captionActions.mouseOver = false;
          state.captionActions.index = 0;
          state.captionActions.type = "";
          state.captionActions.checkCaptionChange = false;
          state.captionActions.isLoading = false;
          break;
        case "listenSubtitle":
          console.log(">>>>>>> listenSubtitle data ", { data });
          state.captionActions.audio.url = data;
          state.captionActions.audio.status = status;

          state.captionActions.mouseOver = false;
          state.captionActions.index = 0;
          state.captionActions.type = "";
          state.captionActions.checkCaptionChange = false;
          state.captionActions.isLoading = false;
          //state.captionActions.audio.firstListen = false;
          break;
        case "palySubtitleStatus":
          console.log(">>>>>> playSubtitleStatue isPlaying ", { isPlaying });
          state.captionActions.audio.status = isPlaying;
         // state.captionActions.audio.firstListen = false;
          break;
        case "selectedCaptionIdx":
          state.captionActions.preview.selectedCaptionIdx = selectedCaptionIdx;
          break;
        default:
          break;
      }
    },
    deleteProject: (state) => {
      state.project = null;
    },
    updateProjectUserId: (state, action) => {
      const { value } = action.payload;
      const { project } = state;
      if (project && project.userId === "guest") {
        project.userId = value;
      }
    },

    setGenerateResource: (state, action) => {
      const { type, key, data } = action.payload;
      console.log("type, key, data: ", type, key, data);

      if (type === "none") {
        state.generate[key] = data;
      } else {
        console.log("state.generate[type][key]: ", state.generate[type][key]);
        state.generate[type][key] = data;
      }
    },
    changeTransition: (state, action) => {
      const { project } = state;
      const { sceneIdx, options } = action.payload;
      const currentScene = project?.scenes[sceneIdx];
      const { canvas } = canvasHolder;

      if (currentScene) {
        currentScene.transition = { ...options };
        // scenes: project.scenes.map((_scene: any) => ({ ..._scene, thumbnailUrl: null })),
        const nextScene = project?.scenes[sceneIdx + 1];
        previewSceneTransition({
          canvas,
          sceneIdx,
          type: options.type,
          duration: options.duration,
          orientation: project?.orientation,
          currentScene: { ...current(currentScene), thumbnailUrl: null },
          nextScene: nextScene
            ? { ...current(nextScene), thumbnailUrl: null }
            : null,
        });
      }
    },
    replaceAutomationProject: (state, action) => {
      const { project } = action.payload;
      const { canvas } = canvasHolder;

      if (project) {
        const newProject = {
          ...state.project,
          ...project,
        };
        // delete newProject.automation;
        newProject.automation = {
          ...newProject.automation,
          isFinish: true,
        }
        delete newProject.isFinish;

        newProject.scenes = project.scenes.map((scene: Scene) => {
          const newScene = { ...scene };
          delete newScene.isFinish;
          return newScene;
        });

        state.project = newProject;

        const scene = newProject.scenes[0];
        if (scene) {
          initCanvasScene({
            canvas,
            scene: scene as any,
            smooth: true,
          });
        }
      }
    },
    changeLogoImage: (state, action) => {
      const { canvas } = canvasHolder;
      const { selectedSceneIdx, project } = state;
      const { asset, key, isAll } = action.payload;
      // logoImageChange({ canvas, url: asset.src, asset });
      console.log(
        ">>>>>> changeLogoImage asset ",
        { asset },
        { key },
        { isAll }
      );
      const targetWidth = asset.width || 0;
      const targetHeight = asset.height || 0;

      if (!canvas) return;
      if (key === "single") {
        const scene = project?.scenes[selectedSceneIdx];
        if (scene) {
          const { clips } = scene;
          const logoClip = clips.find(
            (clip) => clip.type === "image" && clip?.tag === "logo"
          );
          if (!logoClip) return;

          
          const defaultWidth = logoClip.width;
          const defaultHeight = logoClip.height;
  
          if(targetWidth < defaultWidth && targetHeight < defaultHeight) {
            const sourceWidth = logoClip.width;
            const sourceHeight = logoClip.height;
            const scaleFactor = (targetWidth / sourceWidth) && (targetHeight /sourceHeight) < 1
            ? Math.min(
              sourceHeight / targetHeight,
              sourceWidth / targetWidth
            )
            : Math.min(
              targetWidth / sourceWidth,
              targetHeight / sourceHeight
            );
            
            setData(logoClip, ['scaleX'], scaleFactor);
            setData(logoClip, ['scaleY'], scaleFactor);
            setData(logoClip, ['source_url'], asset.src);
            // setData(logoClip, ['width'], logoClip.width);
            // setData(logoClip, ['height'], logoClip.height);

            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'src', value: asset.src })
            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleX', value: scaleFactor });
            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleY', value: scaleFactor });
          // scene.clips.splice(logoClipIndex, 1);
          // deleteObjects({canvas, names: logoClip?.id || logoClip?.name});
          // dispatch(addObjectAsync(newObj));
          } else {
          const scaleX = defaultWidth / targetWidth as number;
          const scaleY = defaultHeight / targetHeight as number;

          const scale = Math.min(scaleX, scaleY);
          
          const newWidth = targetWidth * scale;
          const newHeight = targetHeight * scale;
          const newLeft = logoClip.left + (defaultWidth - newWidth) / 2;
          const newTop = logoClip.top + (defaultHeight / newHeight) / 2;

          console.log('>>>>>> changeLogoImage  newScale ', {newWidth}, {newHeight}, {scaleX}, {scaleY}, {scale});

          setData(logoClip, ['scaleX'], scaleX);
          setData(logoClip, ['scaleY'], scaleY);
          setData(logoClip, ['source_url'], asset.src);

          editObject({ canvas, name: logoClip.id || logoClip.name, key: 'src', value: asset.src })
          editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleX', value: scaleX });
          editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleY', value: scaleY });
          }
        }
      }
      else {
        project?.scenes.map((scene: any) => {
          const logoClip = scene.clips.find(
            (clip) => clip.type === "image" && clip?.tag === "logo"
          );
          if (!logoClip) return;

          const defaultWidth = logoClip.width;
          const defaultHeight = logoClip.height;

          if(targetWidth < defaultWidth && targetHeight < defaultHeight) {
            const sourceWidth = logoClip.width;
            const sourceHeight = logoClip.height;
            const scaleFactor = (targetWidth / sourceWidth) && (targetHeight /sourceHeight) < 1
            ? Math.min(
              sourceHeight / targetHeight,
              sourceWidth / targetWidth
            )
            : Math.min(
              targetWidth / sourceWidth,
              targetHeight / sourceHeight
            )
            setData(logoClip, ['scaleX'], scaleFactor);
            setData(logoClip, ['scaleY'], scaleFactor);
            setData(logoClip, ['source_url'], asset.src);
            // setData(logoClip, ['width'], logoClip.width);
            // setData(logoClip, ['height'], logoClip.height);
  
            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'src', value: asset.src })
            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleX', value: scaleFactor });
            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleY', value: scaleFactor });

          } else {
            const scaleX = defaultWidth / targetWidth as number;
            const scaleY = defaultHeight / targetHeight as number;

            const scale = Math.min(scaleX, scaleY);
            
            const newWidth = targetWidth * scale;
            const newHeight = targetHeight * scale;
            const newLeft = logoClip.left + (defaultWidth - newWidth) / 2;
            const newTop = logoClip.top + (defaultHeight / newHeight) / 2;

            console.log('>>>>>> changeLogoImage  newScale ', {newWidth}, {newHeight}, {scaleX}, {scaleY}, {scale});

            setData(logoClip, ['scaleX'], scaleX);
            setData(logoClip, ['scaleY'], scaleY);
            setData(logoClip, ['source_url'], asset.src);

            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'src', value: asset.src })
            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleX', value: scaleX });
            editObject({ canvas, name: logoClip.id || logoClip.name, key: 'scaleY', value: scaleY });
          }
        });
      }
    },
    setDefaultFontFamily: (state, action) => {
      const { data } = action.payload;
      state.defaultFontFamily = data;
    },

    initListenButtonAndAudioInfo:(state)=>{
      const { project } = state;

      state.listenButtonAndAudioInfo = (project?.scenes || []).map(() => ({
        audio: {
          status: "idle",
          url: "",
          isFirstListen: false,
        },
        isListenButtonShow: true
      })) 
    },

    addListenButtonAndAudioInfo:(state)=>{
      const { project, selectedSceneIdx, listenButtonAndAudioInfo } = state;
      // const currentScene = project?.scenes[selectedSceneIdx];
      // const listenObj = currentScene?.listenButtonAndAudioInfo || {};
      // listenObj.audio = listenObj.audio || {};

      state.listenButtonAndAudioInfo = (project?.scenes || []).map((_, index) => {
        if (listenButtonAndAudioInfo[index]) {
          return listenButtonAndAudioInfo[index];
        }
        return {
          audio: {
            status: "idle",
            url: "",
            isFirstListen: false,
          },
          isListenButtonShow: true
        }
      }) 
    },

    setListenButtonAndAudioInfoChangeByAction:(state, action:PayloadAction<string>)=>{
      const  audioUrl  = action.payload;
      const { selectedSceneIdx, listenButtonAndAudioInfo } = state;
      // const currentScene = project?.scenes[selectedSceneIdx];
      // const listenObj = currentScene?.listenButtonAndAudioInfo || {};
      // listenObj.audio = listenObj.audio || {};

      state.listenButtonAndAudioInfo = listenButtonAndAudioInfo.map((item, index) => {
        if (index !== selectedSceneIdx) {
          return item;
        }
        return {
          audio: {
            status: "play",
            url: audioUrl,
            isFirstListen: true,
          },
          isListenButtonShow: false
        }
      })
    },

    setOriginSceneAudioPlay:(state)=>{
      const { selectedSceneIdx, listenButtonAndAudioInfo } = state;

      state.listenButtonAndAudioInfo = listenButtonAndAudioInfo.map((item, index) => {
        if (index !== selectedSceneIdx) {
          return item;
        }
        return {
          audio: {
            status: "play",
            url: item.audio?.url || "",
            isFirstListen: false,
          },
          isListenButtonShow: item.isListenButtonShow || false
        }
      })
    },

    setIsListenButtonShow:(state)=>{
      const { selectedSceneIdx, listenButtonAndAudioInfo } = state;

      state.listenButtonAndAudioInfo = listenButtonAndAudioInfo.map((item, index) => {
        if (index !== selectedSceneIdx) {
          return item;
        }
        return {
          audio: {
            status: item.audio?.status || "idle",
            url: item.audio?.url || "",
            isFirstListen: false,
          },
          isListenButtonShow: true
        }
      })
    },

    setCustomFontList: (state, action) => {
      const { clipFonts = [] } = action.payload;
      const newClipFonts: ClipFonts[] = [];
      clipFonts?.forEach((clipFont: ClipFonts) => {
        if (
          !state.reduxCustomFontList?.some(
            (item) => item?.fontFamily === clipFont?.fontFamily
          )
        ) {
          newClipFonts.push(clipFont);
        }
      });
      state.reduxCustomFontList = [
        ...state.reduxCustomFontList,
        ...newClipFonts,
      ];
    },
    /**
     * checkGenerateBtnState
     * - generateBtn 눌렀을 때 PreviewAudio 상태 멈추기 위함
     */
    setCheckGenerateBtnState: (state, action) => {
      const { btnState = 'idle' } = action.payload;
      state.checkGenerateBtnState = {
        btnState
      }
    },
    setModelClipsThumbnail: (state, action) => {
      const thumbnailUrls = action.payload;
      const { project } = state;

      if (project) {
        project.scenes = project.scenes.map((scene) => {
          return {
            ...scene,
            clips: scene.clips.map((clip) => {
              if (
                clip.type === "aiModel"
                && Object.keys(thumbnailUrls)
                  .includes(`${clip?.model.ai_name}/${clip?.model.emotion}`)
              ) {
                clip.thumbnailUrl = 
                  thumbnailUrls[`${clip?.model.ai_name}/${clip?.model.emotion}`];
              }
              return clip;
            })
          }
        })
      }
    },
    setScriptEditorTab: (state, action) => {
      const { project, selectedSceneIdx } = state;
      const currentScene = project?.scenes[selectedSceneIdx];
      const tab = action.payload;
      
      state.scriptEditorTab = tab;
      if (currentScene) {
        if (tab !== "Conversation") {
          currentScene.isDualAvatar = false;
        } else {
          currentScene.isDualAvatar = true;
        }
      }
    },
    toggleModelBackground: (state, action) => {
      const {
        project,
        selectedSceneIdx,
        selectedObjectIdList
      } = state;
      const { canvas } = canvasHolder;
      const currentScene = project?.scenes[selectedSceneIdx];
      const type = action.payload;

      if (canvas && currentScene) {
        const targetClips = currentScene.clips
          .filter((clip) => (
            selectedObjectIdList?.includes(clip.id)
          ))
          .map((clip) => {
            clip.model.current_url = type;
            clip.model.source_url = clip.model.toggle_urls[type];
            clip.model.origin = clip.model.toggle_origins[type];
            clip.width = clip.model.toggle_origins[type].width;
            clip.height = clip.model.toggle_origins[type].height;
            return clip;
          });

        currentScene.clips = currentScene.clips.map((clip) => {
          const targetClip = targetClips.find((c) => c.id === clip.id);
          if (targetClip) {
            return targetClip;
          }
          return clip;
        });
        toggleModelBackground({
          canvas,
          clips: targetClips,
        });
      }
    },
    setClipSize: (state, action) => {
      const {
        project,
        selectedSceneIdx,
        selectedObjectIdList
      } = state;
      const { canvas } = canvasHolder;
      const currentScene = project?.scenes[selectedSceneIdx];
      const { align, mode } = action.payload;
  
      if (canvas && currentScene) {
        const targetClips = currentScene.clips
          .filter((clip) => (
            selectedObjectIdList?.includes(clip.id)
          ));

        const result = setClipSize({ canvas, clips: targetClips, align, mode });
  
        currentScene.clips = currentScene.clips.map((clip) => {
          const targetResult = result[clip.id];
          if (targetResult) {
            return Object.assign(clip, targetResult);
          }
          return clip;
        });
      }
    },
    setBrandKitList: (state, action) => {
      const { logoList, type = '', fontList, uploadPath } = action.payload;
      const { reduxCustomFontList } = state;

      if(type === 'logo') {
        state.brandKitList.logo = [...logoList];
      }

      if(type === 'font') {
        state.brandKitList.font = [...fontList];
        const clipFonts: ClipFonts[] = [];
        const newClipFonts: ClipFonts[] = [];
        
        fontList.forEach((font: FontType) => {
          if(!font) return;
          const tempSrc = font.src.split('/');
          const fileName = tempSrc[tempSrc.length - 1];
          const savePath = `${uploadPath}/${fileName}`;

          clipFonts.push({
            fontFamily: font.orgFileName.normalize('NFC').split('.')[0],
            src: font.src,
            savePath,
          });
        })

        if(reduxCustomFontList.length === 0) {
          clipFonts?.forEach((clipFont: ClipFonts) => {
            if (!state.reduxCustomFontList?.some((val) => val?.fontFamily === clipFont?.fontFamily)) {
              newClipFonts.push(clipFont);
            }
          });
          state.reduxCustomFontList = [
            ...state.reduxCustomFontList,
            ...newClipFonts,
          ];
        }
      }
    },
    normalizeClipId: (state) => {
      const { project } = state;

      if (project) {
        project.scenes = normalizeClipId(current(project.scenes));
      }
    },
    normalizeClipLayer: (state) => {
      const { project } = state;

      if (project) {
        project.scenes = normalizeClipLayer(current(project.scenes));
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreprocessText.fulfilled, (state, action) => {
        const { sceneIdx, clipId, data } = action.payload;
        const aiModel = state.project?.scenes[sceneIdx]?.clips.find(
          (item) => item.id === clipId
        );
        if (aiModel) {
          aiModel.script = { ...data, org: aiModel.script?.org };
        }
      })
      .addCase(addCaptionAsync.fulfilled, (state, action) => {
        const { project } = state

        if (!action.payload) {
          return
        }

        const {
          sceneIndex,
          captionClip,
        } = action.payload
        
        const scene = project?.scenes[sceneIndex]
        
        if (!scene) {
          return
        }

        scene.clips.push(captionClip)
      })
      .addCase(addObjectAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(addObjectAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addObjectAsync.fulfilled, (state, action) => {
        const { project } = state;
        const { sceneIdx, ...props } = action.payload;
        const scene = project?.scenes[sceneIdx];
        if (scene) {
          // console.log("!@#!@#!@# ", props);
          scene.clips.push({
            ...props,
          });
          if (!Number.isInteger(state.editorKey)) {
            state.editorKey = 0
          } else {
            if (state.editorKey > 0) {
              state.editorKey--;
            } else {
              state.editorKey++;
            }
          };
        }
        state.loading = false;
      })
      .addCase(uploadThumbnail.fulfilled, (state) => {
        state.loading = false;
      })
      .addMatcher(
        (action) => {
          return action.type === HYDRATE;
        },
        (state, action) => {
          const projectApiQueryValues = Object.entries(
            action.payload.projectApi.queries
          )
            .filter(([key]) => key.startsWith("getProject"))
            .map(([, value]) => value);

          if (projectApiQueryValues.length > 0) {
            const project = (projectApiQueryValues[0] as any).data;
            state.project = project;
            // state.selectedSceneIdx = state.selectedSceneIdx || 0;
            state.selectedSceneIdx = 0;
            state.selectedObjectIdList = [];
            state.selectedObjectTypeList = [];
            state.selectedAvatarType = null;
            state.LeftSiderCollapsed = true;
            state.selectedLeftTab = null;
            state.selectedLeftSubTab = null;
            console.log("HYDRATE project");
          }
        }
      );
    // .addMatcher(
    //   projectApi.endpoints.getProject.matchFulfilled,
    //   (state, { payload }) => {
    //     state.project = payload;
    //     // state.selectedSceneIdx = state.selectedSceneIdx || 0;
    //     // 다시 불러왔을 경우 0번 씬을 선택하도록한다.
    //     state.selectedSceneIdx = 0;
    //     state.selectedObjectIdList = [];
    //     state.selectedObjectTypeList = [];

    //     console.log("init project", {payload});
    //   }
    // );
  },


});

export default projectSlice.reducer;

export const projectActions = {
  fetchPreprocessText,
  fetchUpdateProject,
  fetchDeleteProject,
  addObjectAsync,
  objectDuplicateAsync,
  objectMoveForRatioAsync,
  addCaptionAsync,
  uploadThumbnail,
  shareBrandKitItem,
  ...projectSlice.actions,
};

