import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RestApiResponse } from "@/types/common.api";

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin/",
  }),
  tagTypes: ["Projects"],
  endpoints: (builder) => ({
    getProjects: builder.query<any, any>({
      query: (params) => {
        console.log('Projects params: ', params)
        return { url: `projects?${new URLSearchParams(params)}` };
      },
      providesTags: ["Projects"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const { useGetProjectsQuery } = projectsApi;
