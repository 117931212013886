import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cubeApi = createApi({
  reducerPath: "cubeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin/",
  }),
  tagTypes: ["Cube"],
  endpoints: (builder) => ({
    getCube: builder.query<any, any>({
      query: (params) => `cube?${new URLSearchParams(params)}`,
    }),
  }),
});

export const { useGetCubeQuery } = cubeApi;
