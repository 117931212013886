import { createContext, useContext } from "react";

export interface AmplitudeType {
  track: (event: string, props?: {}, properties?: {}) => void;
  revenue: (payment: any) => void;
}

const AmplitudeContext = createContext<AmplitudeType | null>(null);
export default AmplitudeContext;

export const useAmplitudeContext = () =>
  useContext(AmplitudeContext) || {
    track: () => {},
    revenue: () => {},
  }