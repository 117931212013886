import { useCallback, useEffect } from "react";
import amplitudeClient from "@/lib/amplitude.client";
import axios from "axios";

export const useAmplitude = ({
  user_id,
  AMPLITUDE_API_KEY,
}: {
  user_id: string;
  AMPLITUDE_API_KEY: string;
}) => {
  const initAmplitude = useCallback(async () => {
    try {
      const response = await axios.get(
        "/api/common/env/EVENT_TRACKING_ENABLED"
      );
      const EVENT_TRACKING_ENABLED = response.data.data.value === "true" && typeof AMPLITUDE_API_KEY === 'string' && AMPLITUDE_API_KEY !== '';
      
      if (EVENT_TRACKING_ENABLED ) {
        amplitudeClient.init(AMPLITUDE_API_KEY);
      }
    } catch (error) {
      console.log("EVENT_TRACKING_ENABLED error: ", error);
    }
  }, [AMPLITUDE_API_KEY]);

  useEffect(() => {
    initAmplitude();
  }, [initAmplitude]);


  const track = (event: string, props = {}, properties = {}) => {
    
    amplitudeClient.track(event, props, {
      ...properties,
      user_id,
    });
  };

  const revenue = (properties = {}) => {
    amplitudeClient.revenue({ ...properties, user_id });
  };

  return {
    track,
    revenue,
  };
};
