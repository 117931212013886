/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  darkMode: "",
  systemColor: "",
  alert: {
    isAlert: false,
    code: "",
    msg: "",
  }
};

export const system = createSlice({
  name: "system",
  initialState,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  reducers: {
    reset: () => initialState,
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
      Cookies.set("dark-mode", action.payload, { path: "/" });
    },
    setSystemColor: (state, action) => {
      state.systemColor = action.payload;
      Cookies.set("system-color", action.payload, { path: "/" });
    },
  },
});

export const { setDarkMode, setSystemColor, reset } = system.actions;

export default system.reducer;
