import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const unsubscribeApi = createApi({
  reducerPath: "unsubscribeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin",
  }),
  tagTypes: ["Unsubscribe"],
  endpoints: (builder) => ({
    getUnsubscribe: builder.query<RestApiResponse<any>, any>({
      query: (params) => {
        return { url: `/unsubscribe?${new URLSearchParams(params)}` };
      },
      providesTags: ["Unsubscribe"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const { useGetUnsubscribeQuery } = unsubscribeApi;
