import { runtimeEnv } from "@/lib/runtime-utils";
import {
  createApi,
  fetchBaseQuery,
  defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { VoiceCloneProvider } from "@/constants/constants";
import type { RestApiResponse } from "@/types/common.api";
import type { ProjectType } from "@/types/type";
import type { AvatarShareGroup, AvatarShareMember, MyAvatarAsset, NotSharedAvatar, VoiceCloneAsset } from "@/types/models";
import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import type { QueryArgs } from "@/types/common.api";

export interface CreateAlphaRes {
  alphaUrl: string;
  originUrl: string;
  thumbnailUrl: string;
  success: boolean;
  taskId: string;
  destPath: string;
}

type CreateFromVideoRes = {
  destPath: string;
  image: {
    alphaUrl: string;
    originUrl: string;
    thumbnailUrl: string;
    originWidth: number;
    originHeight: number;
    alphaWidth: number;
    alphaHeight: number;
    thumbnailWidth: number;
    thumbnailHeight: number;
  };
  voice: {
    id: string;
    type: typeof VoiceCloneProvider[number];
    language: string;
    model: string;
    resource_url: string;
  };
};

type GetProgressRes = {
  taskState: "ready" | "process" | "complete" | "error";
  code?: string;
  message?: string;
};

export type DreamAvatarTemplate = {
  _id: string;
  name: string;
  thumbnailUrl: string;
  language: string | null;
  screenType: "mobile" | "web";
  project?: ProjectType;
  myAvatarToggle?: "origin" | "alpha";
  myAvatarThumbnailUrl?: string;
  myAvatarTemplateIndex?: number;
};

type WorkspaceArgs = {
  workspaceId?: string;
  projectWorkspaceId?: string;
}

export const myavatarApi = createApi({
  reducerPath: "myavatarApi",
  // refetchOnFocus: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/myavatar`,
  }),
  tagTypes: ["PhotoAvatars", "CustomAvatars", "VoiceClone"],
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  endpoints: (builder) => ({
    getMyAvatars: builder.query<MyAvatarAsset[], (QueryArgs & WorkspaceArgs | void)>({
      // query: () => "/",
      query: (args?: QueryArgs & WorkspaceArgs) => {
        const query: FetchArgs = {
          url: "/",
          method: "GET",
        };
        if (args?.headers) {
          query.headers = args.headers;
        }

        if(args?.workspaceId) {
          query.url = `${query.url}?${new URLSearchParams({
            workspaceId: args.workspaceId,
          })}`
        }
        
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition,
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition,
        });
      },
      providesTags: ["PhotoAvatars", "CustomAvatars"],
      transformResponse: async (res: RestApiResponse<MyAvatarAsset[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    getPhotoAvatars: builder.query<MyAvatarAsset[], QueryArgs | void>({
      // query: () => "/?avatarType=photoAvatar",
      query: (args?: QueryArgs) => {
        const query: FetchArgs = {
          url: "/?avatarType=photoAvatar",
          method: "GET",
        };
        if (args?.headers) {
          query.headers = args.headers;
        }
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition,
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition,
        });
      },
      providesTags: ["PhotoAvatars"],
      transformResponse: async (res: RestApiResponse<MyAvatarAsset[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    getCustomAvatars: builder.query<MyAvatarAsset[], (QueryArgs & WorkspaceArgs | void)>({
      // query: () => "/?avatarType=customAvatar",
      query: (args?: QueryArgs & WorkspaceArgs) => {
        const origin = "/";
        const search = new URLSearchParams({ avatarType: "customAvatar" });

        const query: FetchArgs = {
          url: `${origin}?${search.toString()}`,
          method: "GET",
        };

        if (args?.headers) {
          query.headers = args.headers;
        }

        if (args?.workspaceId) {
          search.append("workspaceId", args.workspaceId);
          query.url = `${origin}?${search.toString()}`
        }
        
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition,
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition,
        });
      },
      providesTags: ["CustomAvatars"],
      transformResponse: async (res: RestApiResponse<MyAvatarAsset[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    getCustomAvatarCount: builder.query<number, QueryArgs | void>({
      // query: () => "/?avatarType=customAvatar",
      query: (args?: QueryArgs) => {
        const query: FetchArgs = {
          url: "/?avatarType=customAvatar",
          method: "GET",
        };
        if (args?.headers) {
          query.headers = args.headers;
        }
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition,
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition,
        });
      },
      providesTags: ["CustomAvatars"],
      transformResponse: async (res: RestApiResponse<MyAvatarAsset[]>) => {
        if (!res.success) {
          return 0;
        }
        return res.data.filter((avatar) => !avatar.isSample).length;
      },
    }),
    createDreamAvatar: builder.mutation<
      RestApiResponse<{ avatarId: string }>,
      {
        avatar: Omit<MyAvatarAsset, "id" | "createdAt">;
        perFrame: false;
        uploadPath: string;
      }
    >({
      query: (body) => ({
        url: "/",
        method: "POST",
        body: {
          ...body,
          avatarType: "dreamAvatar",
        },
      }),
      invalidatesTags: ["PhotoAvatars"],
    }),
    createCustomAvatar: builder.mutation<
      RestApiResponse<{ avatarId: string }>,
      {
        avatar: Omit<MyAvatarAsset, "id" | "createdAt">;
        voice: Omit<VoiceCloneAsset, "createdAt">;
        uploadPath: string;
        gpuGroup: string;
        perFrame: boolean;
        isWebcam: boolean;
      }
    >({
      query: (body) => ({
        url: "/",
        method: "POST",
        body: {
          ...body,
          avatarType: "customAvatar",
        },
      }),
      invalidatesTags: ["CustomAvatars"],
    }),
    deleteMyAvatar: builder.mutation<RestApiResponse<void>, string>({
      query: (query) => ({
        url: `/?avatarId=${query}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PhotoAvatars", "CustomAvatars"],
    }),
    editMyAvatar: builder.mutation<
      RestApiResponse<void>,
      {
        id: string;
      } & Partial<Omit<MyAvatarAsset, "id">>
    >({
      query: (body) => ({
        url: `/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["PhotoAvatars", "CustomAvatars"],
    }),
    getProgress: builder.mutation<GetProgressRes, string>({
      query: (taskId) => `/process/progress?taskId=${taskId}`,
      transformResponse: async (res: RestApiResponse<GetProgressRes>) => {
        if (!res.success) {
          return {
            taskState: "error",
          };
        }
        return res.data;
      },
    }),
    // template 목록을 조회한다.
    getTemplates: builder.query<DreamAvatarTemplate[], void>({
      query: () => "/templates",
      transformResponse: async (
        res: RestApiResponse<{
          templates: DreamAvatarTemplate[];
        }>
      ) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data.templates;
      },
    }),
    // default template 목록을 조회한다.
    getDefaultTemplates: builder.query<DreamAvatarTemplate[], void>({
      query: () => "/templates?type=default",
      transformResponse: async (
        res: RestApiResponse<{
          templates: DreamAvatarTemplate[];
        }>
      ) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data.templates;
      },
    }),
    //NOTE - 보이스 리스트 불러오기
    getVoices: builder.query<VoiceCloneAsset[], (QueryArgs & WorkspaceArgs | void)>({
      query: (args?: QueryArgs & WorkspaceArgs) => {
        const query: FetchArgs = {
          url: "/voice",
          method: "GET",
        };
        if (args?.headers) {
          query.headers = args.headers;
        }

        if(args?.workspaceId) {
          query.url=`${query.url}?${new URLSearchParams({
            workspaceId: args.workspaceId
          })}`
        }
        return query;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (queryArgs) {
          const { headers, ...args } = queryArgs;
          if (Object.keys(args).length > 0) {
            return defaultSerializeQueryArgs({
              endpointName,
              queryArgs: args,
              endpointDefinition,
            });
          }
        }
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: undefined,
          endpointDefinition,
        });
      },
      providesTags: ["VoiceClone"],
      transformResponse: async (res: RestApiResponse<VoiceCloneAsset[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    createVoice: builder.mutation<
      RestApiResponse<Tts>,
      {
        base64Audio: string;
        displayName: string;
        extname: string | null;
        locale: string;
        gender: "MALE" | "FEMALE";
      }
    >({
      query: (body) => ({
        url: "/voice",
        method: "POST",
        body,
      }),
      invalidatesTags: ["VoiceClone"],
    }),
    deleteVoice: builder.mutation<RestApiResponse<void>, string>({
      query: (query) => ({
        url: `/voice/?voiceId=${query.replaceAll("/", "---")}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VoiceClone"],
    }),
    editVoice: builder.mutation<
      RestApiResponse<void>,
      {
        id: string;
      } & Partial<Omit<VoiceCloneAsset, "id">>
    >({
      query: (body) => ({
        url: "/voice",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["VoiceClone"],
    }),
    //NOTE - 배경없애는 함수
    createAlphaImage: builder.mutation<
      RestApiResponse<CreateAlphaRes>,
      { originFile: string }
    >({
      query: (body) => ({
        url: "/process/transparent",
        method: "POST",
        body,
      }),
    }),
    skipAlphaImage: builder.mutation<CreateAlphaRes, MyAvatarAsset>({
      queryFn: async (body) => {
        return {
          data: {
            success: true,
            alphaUrl: body.alphaUrl,
            originUrl: body.originUrl,
            thumbnailUrl: body.thumbnailUrl,
            taskId: "",
            originWidth: body.originWidth,
            originHeight: body.originHeight,
            alphaWidth: body.alphaWidth,
            alphaHeight: body.alphaHeight,
            thumbnailWidth: body.thumbnailWidth,
            thumbnailHeight: body.thumbnailHeight,
            destPath: "",
          },
        };
      },
    }),
    createFromVideo: builder.mutation<
      RestApiResponse<CreateFromVideoRes>,
      { language: "en" | "ko" | "zh"; uploadPath: string }
    >({
      query: (body) => ({
        url: "/process/fromVideo",
        method: "POST",
        body,
      }),
    }),
    uploadImage: builder.mutation<
      RestApiResponse<{
        uploadPath: string;
      }>,
      FormData
    >({
      query: (body) => ({
        url: "/process/upload?type=dreamavatar",
        method: "POST",
        body,
        formData: true,
      }),
    }),
    uploadVideo: builder.mutation<
      RestApiResponse<{
        uploadPath: string;
      }>,
      FormData
    >({
      query: (body) => ({
        url: "/process/upload?type=customavatar",
        method: "POST",
        body,
        formData: true,
      }),
    }),
    getAvatarShareMembers: builder.query<AvatarShareGroup[], {workspaceSeats: string[]}>({
      query: ({ workspaceSeats = [] }) => {
        const query: FetchArgs = {
          url: '/share',
          method: 'GET'
        }

        const params = new URLSearchParams();
        workspaceSeats.forEach((seats, index) => {
          params.append(`seats[${index}]`, seats);
        })

        query.url = `${query.url}?${new URLSearchParams(params)}`
        return query;
      },
      transformResponse: async (res: RestApiResponse<AvatarShareGroup[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    updateAvatarShareMembers: builder.mutation<RestApiResponse<void>, 
    { 
      shareMemberList: AvatarShareMember[],
      workspaceId: string,
      shareOwnerId: string,
    }>({
      query: ({ shareMemberList = [], workspaceId = '', shareOwnerId = '' }) => ({
        url: '/share',
        method: 'POST',
        body: { shareMemberList, workspaceId, shareOwnerId }
      })  
    }),
    checkAvatarPermission: builder.mutation<NotSharedAvatar[], {
      avatarList: string[] | []
    }>({
      query: ({ avatarList= []}) => ({
        url: '/share/permission',
        method: 'POST',
        body: { avatarList }
      }),
      transformResponse: async (res: RestApiResponse<NotSharedAvatar[]>) => {
        if (!res.success) {
          return [];
        }
        return res.data;
      },
    }),
    uploadTranslateProcess: builder.mutation<RestApiResponse<{
      uploadPath: string;
    }>, FormData>({
      query: (body) => ({
        url: "/process/uploadTranslateProcess?type=customavatar",
        method: "POST",
        body,
        formData: true,
      }),
    }),
    checkAvatarName: builder.mutation<
      RestApiResponse<boolean>,
      { name: string; id?: string }
    >({
      query: (body) => ({
        url: `/process/checkName`,
        method: "POST",
        body: {
          ...body,
          type: "avatar",
        }
      }),
    }),
    checkVoiceName: builder.mutation<
      RestApiResponse<boolean>,
      { name: string; id?: string }
    >({
      query: (body) => ({
        url: `/process/checkName`,
        method: "POST",
        body: {
          ...body,
          type: "voice",
        }
      }),
    }),
    checkAssetName: builder.mutation<
      RestApiResponse<boolean>,
      { name: string; id?: string }
    >({
      query: (body) => ({
        url: `/process/checkName`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetMyAvatarsQuery,
  useLazyGetMyAvatarsQuery,
  useGetPhotoAvatarsQuery,
  useGetCustomAvatarsQuery,
  useGetCustomAvatarCountQuery,
  useCreateDreamAvatarMutation,
  useCreateCustomAvatarMutation,
  useDeleteMyAvatarMutation,
  useEditMyAvatarMutation,
  useGetProgressMutation,
  useGetTemplatesQuery,
  useGetDefaultTemplatesQuery,
  useGetVoicesQuery,
  useLazyGetVoicesQuery,
  useCreateVoiceMutation,
  useDeleteVoiceMutation,
  useEditVoiceMutation,
  useCreateAlphaImageMutation,
  useSkipAlphaImageMutation,
  useCreateFromVideoMutation,
  useUploadImageMutation,
  useUploadVideoMutation,
  useGetAvatarShareMembersQuery,
  useUpdateAvatarShareMembersMutation,
  useCheckAvatarPermissionMutation,
  useCheckAvatarNameMutation,
  useCheckVoiceNameMutation,
  useCheckAssetNameMutation,
  util: { getRunningQueriesThunk },
  useUploadTranslateProcessMutation,
} = myavatarApi;

export const {
  getMyAvatars,
  getPhotoAvatars,
  getCustomAvatars,
  getCustomAvatarCount,
  getVoices,
} = myavatarApi.endpoints;
