import { CustomEnv } from "../types/env";

declare var process: {
  env: {
    [key: string]: string | undefined;
  };
  runtimeEnv: {
    [key: string]: string | undefined;
  };
  argv: string[];
  exit: (arg0: Number) => {};

};

export const runtimeEnv: NodeJS.ProcessEnv & CustomEnv = new Proxy<
  NodeJS.ProcessEnv & CustomEnv
>(
  // @ts-ignore
  {},
  {
    get(target, key) {
      return (
        process.runtimeEnv?.[key as string] || process.env[key as string] || ""
      );
    },
  }
);
