"use client";

import { useServerInsertedHTML } from "next/navigation";
import { PropsWithChildren, useState } from "react";
import {
  ServerStyleSheet,
  StyleSheetManager,
  ThemeProvider,
} from "styled-components";

import { theme } from "antd";
import { useSelector } from "react-redux";
import { ConfigProviderExtended } from "@/components/ConfigProviderExtended";
import { AntdStyleRegistryProps } from "@/types/propTypes";
import { getSystemState } from "@/redux/store";

export function StyledComponentsRegistry({ children }: PropsWithChildren) {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());

  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return <>{styles}</>;
  });

  if (typeof window !== "undefined") return <>{children}</>;

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>
      {children}
    </StyleSheetManager>
  );
}

export function AntdStyleRegistry({
  children,
  darkMode: darkModeCookey,
  systemColor,
}: AntdStyleRegistryProps) {
  const darkMode = useSelector(
    (store: any) => {
      const state = getSystemState(store);
      return state.darkMode || state.systemColor || darkModeCookey || systemColor;
  });


  return (
    <ConfigProviderExtended
      theme={{
        algorithm: [
          darkMode === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
          theme.compactAlgorithm,
        ],
        token: {
          darkMode: darkMode,
          colorPrimary: '#3075f6',
        },
      }}
      space="small">
      {children}
    </ConfigProviderExtended>
  );
}

export function ThemeRegistry({ children }: PropsWithChildren) {
  const { token } = theme.useToken();
  return <ThemeProvider theme={token}>{children}</ThemeProvider>;
}
