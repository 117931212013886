import { runtimeEnv } from "@/lib/runtime-utils";
import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import type { ProjectType } from "@/types/type";
import type { ProjectCreateParam, ProjectCreateGPTParam } from "@/types/project";

export const projectApi = createApi({
  reducerPath: "projectApi",
  refetchOnFocus: true,

  baseQuery: fetchBaseQuery({
    baseUrl: `${runtimeEnv.BK_STUDIO_URL}/api/project`,
  }),
  tagTypes: ["Project", "object:default", "object:font", "Share"],

  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },

  endpoints: (builder) => ({
    getProject: builder.query({
      query: ({ projectId, userId }) => `${projectId}?userId=${userId}`,
    }),

    // object
    getObjectDefault: builder.query({
      query: () => "/objects/default",
      providesTags: ["object:default"],
    }),

    getFontFamily: builder.query({
      query: () => "/objects/fontFamily",
      providesTags: ["object:font"],
    }),

    getDefaultFontFamily: builder.query({
      query: () => '/objects/defaultFontFamily',
    }),

    // 프로젝트 생성
    createProject: builder.mutation<RestApiResponse<{
      projectId: string
    }>, ProjectCreateParam>({
      query: (body) => ({
        url: "/create",
        method: "POST",
        body,
      }),
    }),

    // GPT 프로젝트 생성
    createGPTProject: builder.mutation<
      RestApiResponse<any>,
      ProjectCreateGPTParam
    >({
      query: (body) => ({
        url: "/chatgpt",
        method: "POST",
        body,
      }),
    }),

    exportProject: builder.mutation({
      query: (data) => {
        return {
          url: `${data._id}/export`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),

    getAudioInfo: builder.mutation({
      query: (projectId) => {
        return {
          url: `${projectId}/getAudioInfo`,
          method: "GET"
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),

    getPreview: builder.query({
      query: (projectId: string) => {
        return {
          url: `${projectId}/preview`,
          method: "GET",
        };
      },
    }),

    previewProject: builder.mutation({
      query: (data) => {
        return {
          url: `${data._id}/preview`,
          method: "POST",
          body: data,
        };
      },
    }),

    // Scene 미리듣기
    getPreviewAudio: builder.mutation({
      query: (data: {
        project: Pick<ProjectType, "_id" | "scenes" | "userId">;
        index: number;
        retry?: number;
      }) => ({
        url: "/previewAudio",
        method: "POST",
        body: data,
      })
    }),

    exportAudio: builder.mutation({
      query: (data: {
        project: Pick<ProjectType, "_id" | "scenes" | "userId" | "dictionary">;
        isConcat?: boolean, 
        sceneId?: number,
      }) => ({
        url: "/exportAudio",
        method: "POST",
        body: data,
      })
    }),
    
    getExportAudio: builder.mutation({
      query: (data: { projectId: string, sceneId: number | string }) => {
        return {
          url: `/exportAudio?projectId=${data.projectId}&sceneId=${data.sceneId}`,
          method: "GET",
        };
      },
    }),

    updateProject: builder.mutation({
      query: ({ id, body }) => ({
        url: `${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "Project" }],
    }),

    deleteProject: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Project" }],
    }),

    /**
     * 템플릿을 저장한다.
     */
    createTemplate: builder.mutation({
      query: (body) => ({
        url: "/templates",
        method: "POST",
        body,
      }),
    }),
    /**
     * 다국어 템플릿을 생성한다.
     */
    createMultiTemplate: builder.mutation({
      query: (body) => ({
        url: "/templates",
        method: "PATCH",
        body,
      }),
    }),
    /**
     * 템플릿 업데이트한다.
     */
    updateTemplate: builder.mutation({
      query: (body) => ({
        url: "/templates",
        method: "PUT",
        body,
      }),
    }),
    getTemplate: builder.query({
      query: (templateId: string) => {
        return {
          url: `templates?templateId=${templateId}`,
          method: "GET",
        };
      },
    }),
    mobileThumbnail: builder.mutation({
      query: ({ projectId }) => ({
        url: "/mobile/getThumbnail",
        method: "POST",
        body: { projectId },
      }),
    }),
    llmWordTimeStamp: builder.mutation({
      query: ({ url, script, langCode, splitNum }) => ({
        url: "/subtitle/wordTimestamp",
        method: "POST",
        body: { url, script, langCode, splitNum },
      }),
    }),
    generateImage: builder.mutation({
      query: (body) => ({
        url: "/generate/image",
        method: "POST",
        body,
      }),
    }),
    generateImageHint: builder.mutation({
      query: (body) => ({
        url: "/generate/hint",
        method: "POST",
        body,
      }),
    }),
    getGenerateImageHint: builder.query({
      query: (automationJobId: string) => ({
        url: `/generate/hint?automationJobId=${automationJobId}`,
        method: "GET",
      }),
    }),
    
    getGenerateJobId: builder.query({
      query: (automationJobId: string) => {
        return {
          url: `/generate/job?automationJobId=${automationJobId}`,
          method: "GET",
        };
      },
    }),

    getExpiredModels: builder.query({
      query: () => "/models/expired",
      transformResponse: (res: RestApiResponse<string[]>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),

    getComment: builder.query<any, { projectId: string | undefined }>({
      query: ({ projectId }) => ({
        url: `${projectId}/share/comment`,
        method: "GET",
      }),
      providesTags: ["Share"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    createComment: builder.mutation({
      query: (body) => ({
        url: `${body.projectId}/share/comment`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetProjectQuery,
  // sider content

  // object
  useGetObjectDefaultQuery,
  useGetFontFamilyQuery,
  useGetPreviewQuery,
  useGetExportAudioMutation,
  
  useCreateProjectMutation,
  useCreateGPTProjectMutation,
  useCreateTemplateMutation,
  useCreateMultiTemplateMutation,
  useUpdateTemplateMutation,
  useGetTemplateQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useExportProjectMutation,
  useGetAudioInfoMutation,
  usePreviewProjectMutation,
  useExportAudioMutation,
  useGetPreviewAudioMutation,
  useMobileThumbnailMutation,
  useLlmWordTimeStampMutation,
  useGenerateImageMutation,
  useGenerateImageHintMutation,
  useGetGenerateImageHintQuery,
  useGetGenerateJobIdQuery,
  useGetDefaultFontFamilyQuery,
  useGetExpiredModelsQuery,
  useGetCommentQuery,
  useCreateCommentMutation,
  util: { getRunningQueriesThunk },
} = projectApi;

export const { getProject, getObjectDefault, getFontFamily } = projectApi.endpoints;

export default projectApi;
