type Base64ToBlob = {
  base64: string,
  exportType?: "objectUrl" | "blob"
}

export function base64ToBlob<T extends (string | Blob)>(props: Base64ToBlob): T {
  const { base64, exportType } = props;

  const [contentType, decodeData] = base64
    .split(",")
    .map((splits: string, index: number) => {
      if (index === 0) {
        return splits.split(":")[1].split(";")[0];
      }
      if (index === 1) {
        return atob(splits);
      }
    }) as [string, string];
  
  const arraybuffer = new ArrayBuffer(decodeData.length);
  const view = new Uint8Array(arraybuffer);
  
  for (let i = 0; i < decodeData.length; i++) {
    view[i] = decodeData.charCodeAt(i) & 0xff;
  }
  
  const blob = new Blob([arraybuffer], { type: contentType });

  if (exportType === "blob") {
    return<T> blob;
  }

  const url = URL.createObjectURL(blob);
  return<T> url;
}