import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RestApiResponse } from '@/types/common.api'
import {
  MediaConverterConvertState,
  VideoOutputOptions,
} from '@/constants/tools/media-converter'

type VideoConverterConvertedJob = {
  jobId: string
  convertState: MediaConverterConvertState
  videoTitle: string
  publicUrl: string
  originalFilename: string
}

export type VideoConverterJob = {
  jobId: string
  convertState: MediaConverterConvertState
  convertedFilename?: string
  thumbnailUrl?: string
  /**
   * units: seconds
   */
  duration?: number
  uploadedFilename?: string
  uploadedFileVolume?: number
  /**
   * 0 ~ 100
   */
  progress?: number
  convertOptions?: VideoOutputOptions
}

export const videoConverterApi = createApi({
  reducerPath: 'tools/videoConverter',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/tools/video-converter',
  }),
  endpoints: (build) => ({
    getConvertedJob: build.query({
      query: (jobId: string) => `/jobs/${jobId}/converted`,
      transformResponse: (res: RestApiResponse<VideoConverterConvertedJob>) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch video converter job')
        }

        return res.data
      },
    }),
    getJob: build.query({
      query: (jobId: string) => `/jobs/${jobId}`,
      transformResponse: (res: RestApiResponse<VideoConverterJob>) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to fetch video converter job')
        }

        return res.data
      },
    }),
    createJob: build.mutation<
      VideoConverterJob,
      {
        file: File
        options: VideoOutputOptions
      }
    >({
      query: ({ file, options }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('options', JSON.stringify(options))

        return {
          url: '/jobs',
          method: 'POST',
          body: formData,
        }
      },
      transformResponse: (res: RestApiResponse<VideoConverterJob>) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to create video converter job')
        }

        return res.data
      },
    }),
    convertJob: build.mutation<
      VideoConverterJob,
      { jobId: string; options?: VideoOutputOptions }
    >({
      query: ({ jobId, options }) => ({
        url: `/jobs/${jobId}/convert`,
        method: 'PUT',
        body: {
          options,
        },
      }),
      transformResponse: (res: RestApiResponse<VideoConverterJob>) => {
        if (!res.success || !res.data) {
          throw new Error('Failed to convert video')
        }

        return res.data
      },
    }),
  }),
})
