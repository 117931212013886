// import { runtimeEnv } from "@/lib/runtime-utils";
import { runtimeEnv } from "@/lib/runtime-utils";
import { CSSProperties } from "react";

export const liveStarterProducts = [
  {label: "10min", monthly: "price_1MvEkwAXU1gC1pUSWzoBt9u2", yearly: "price_1MvEkwAXU1gC1pUSO1s2WHVq", perTime: '10', totalTime: '10', totalScene: '6', monthlyPrice: '30', yearlyPrice: '288', productName: 'starter10'},
  {label: "20min", monthly: "price_1MvEgdAXU1gC1pUSTKkqxS56", yearly: "price_1MvEgdAXU1gC1pUSKMM1U5FD", perTime: '20', totalTime: '10', totalScene: '6', monthlyPrice: '60', yearlyPrice: '576', productName: 'starter20'},
  {label: "40min", monthly: "price_1MvEguAXU1gC1pUSwu0nqTFz", yearly: "price_1MvEguAXU1gC1pUSS7oQa3kX", perTime: '40', totalTime: '10', totalScene: '6', monthlyPrice: '120', yearlyPrice: '1152', productName: 'starter40'},
  {label: "60min", monthly: "price_1MvEjUAXU1gC1pUSzDei2cNr", yearly: "price_1MvEjUAXU1gC1pUSgmmSgc07", perTime: '60', totalTime: '10', totalScene: '6', monthlyPrice: '180', yearlyPrice: '1728', productName: 'starter60'},
];

export const liveProProducts = [
  {label: "90min", monthly: "price_1MvEluAXU1gC1pUS9MLBpFKc", yearly: "price_1MvEluAXU1gC1pUS2dZslNP0", perTime: '90', totalTime: '20', totalScene: '25', monthlyPrice: '225', yearlyPrice: '2160', productName: 'pro90'},
  {label: "120min", monthly: "price_1MvEmlAXU1gC1pUSpcZAANGB", yearly: "price_1MvEmlAXU1gC1pUSMDkVNsly", perTime: '120', totalTime: '20', totalScene: '25', monthlyPrice: '300', yearlyPrice: '2880', productName: 'pro120'},
  {label: "180min", monthly: "price_1MvEnTAXU1gC1pUSqbNNrJxa", yearly: "price_1MvEnTAXU1gC1pUStaJjmtlF", perTime: '180', totalTime: '20', totalScene: '25', monthlyPrice: '450', yearlyPrice: '4320', productName: 'pro180'},
  {label: "240min", monthly: "price_1MvEoNAXU1gC1pUSlbfmRIoO", yearly: "price_1MvEoNAXU1gC1pUSWty5lfva", perTime: '240', totalTime: '20', totalScene: '25', monthlyPrice: '600', yearlyPrice: '5760', productName: 'pro240'},
];

export const devStarterProducts = [
  {label: "10min", monthly: "price_1MojWaAXU1gC1pUSOJKs3paf", yearly: "price_1Mojr0AXU1gC1pUS6R7SnMUr", perTime: '10', totalTime: '10', totalScene: '6', monthlyPrice: '30', yearlyPrice: '288', productName: 'starter10'},
  {label: "20min", monthly: "price_1Mok38AXU1gC1pUS3MjzE9Iq", yearly: "price_1Mok38AXU1gC1pUSfHEuJzFk", perTime: '20', totalTime: '10', totalScene: '6', monthlyPrice: '60', yearlyPrice: '576', productName: 'starter20'},
  {label: "40min", monthly: "price_1Mok6hAXU1gC1pUSZAD0ZGl3", yearly: "price_1Mok6hAXU1gC1pUSPp732FT3", perTime: '40', totalTime: '10', totalScene: '6', monthlyPrice: '120', yearlyPrice: '1152', productName: 'starter40'},
  {label: "60min", monthly: "price_1MokLbAXU1gC1pUS3aUAHD0T", yearly: "price_1MokLbAXU1gC1pUSjLD825Yz", perTime: '60', totalTime: '10', totalScene: '6', monthlyPrice: '180', yearlyPrice: '1728', productName: 'starter60'},
];

export const devProProducts = [
  {label: "90min", monthly: "price_1MojucAXU1gC1pUSV946MkgM", yearly: "price_1MojucAXU1gC1pUSVICLeI1G", perTime: '90', totalTime: '20', totalScene: '25', monthlyPrice: '225', yearlyPrice: '2160', productName: 'pro90'},
  {label: "120min", monthly: "price_1MokOyAXU1gC1pUSlKsKV7jt", yearly: "price_1MokOyAXU1gC1pUSfkVFTr5Q", perTime: '120', totalTime: '20', totalScene: '25', monthlyPrice: '300', yearlyPrice: '2880', productName: 'pro120'},
  {label: "180min", monthly: "price_1MokR7AXU1gC1pUSqDSwNsSY", yearly: "price_1MokR7AXU1gC1pUSFfjQNMBW", perTime: '180', totalTime: '20', totalScene: '25', monthlyPrice: '450', yearlyPrice: '4320', productName: 'pro180'},
  {label: "240min", monthly: "price_1MokT4AXU1gC1pUSqVReRyor", yearly: "price_1MokT4AXU1gC1pUSfaKdPBTg", perTime: '240', totalTime: '20', totalScene: '25', monthlyPrice: '600', yearlyPrice: '5760', productName: 'pro240'},
];

export const starterProducts = ["dev", "development", "local"].includes(runtimeEnv.NODE_ENV) ? devStarterProducts : liveStarterProducts;
export const proProducts = ["dev", "development", "local"].includes(runtimeEnv.NODE_ENV) ? devProProducts : liveProProducts;


export const productsDetailStrings = [
  { frontText: "Total", backText: "credits / Month" },
  { frontText: "Up to", backText: "minutes per video" },
  { frontText: "Up to", backText: "scenes per video" },
  { frontText: "", backText: "AI Avatars" },
  { frontText: "", backText: "Languages & Voices" },
  { frontText: "", backText: "No Watermark" },
  { frontText: "", backText: "Priority Video Processing" },
  { frontText: "", backText: "API Access" },
];

export const enterPriseStrings = [
  { frontText: "", boldText: "", backText: "Includes all Pro features" },
  { frontText: "Up to", boldText: "50", backText: "scenes per video" },
  { frontText: "", boldText: "", backText: "Custom AI Avatar" },
  { frontText: "", boldText: "", backText: "24/7 Priority Support" },
];
const markStyle = {
  fontSize: "12px",
  marginTop: "5px",
};

type MarkType = {
  [key: number]: { style: CSSProperties; label: string };
  0: { style: CSSProperties; label: string };
  1: { style: CSSProperties; label: string };
  2: { style: CSSProperties; label: string };
  3: { style: CSSProperties; label: string };
};

export const starterMarks: MarkType = {
  0: {
    style: markStyle,
    label: starterProducts[0].label,
  },
  1: {
    style: markStyle,
    label: starterProducts[1].label,
  },
  2: {
    style: markStyle,
    label: starterProducts[2].label,
  },
  3: {
    style: markStyle,
    label: starterProducts[3].label,
  },
};

export const proMarks = {
  0: {
    style: markStyle,
    label: proProducts[0].label,
  },
  1: {
    style: markStyle,
    label: proProducts[1].label,
  },
  2: {
    style: markStyle,
    label: proProducts[2].label,
  },
  3: {
    style: markStyle,
    label: proProducts[3].label,
  },
};

export const MODAL_NAME = {
  NONE: "none",
  NOTICE: "notice",
  SUBSCRIPTION_LIST: "subscription_list",
  CHECKOUT: "checkout",
  LOGIN: "login",
  SURVEY: "survey",
  AUTOMATION_LOGIN: "automation_login"
};
