/**
 * 공통 기능을 제공하는 함수
 * 
 * @author Steven
 * @date   2023.06.15
 */
import { RestApiResponse, RestApiResponseError } from "../types/common.api";
import { ERR_CODE_9999, ERR_MSG } from "../lib/api-constants";
import { ApiError } from "../lib/api-exceptions";
import { runtimeEnv } from "./runtime-utils";

const fs = require('fs');
const spawn = require("child_process").spawn;

// Error에서 응답 메시지를 가져온다.
function getErrorMessage(error: unknown): string {
  if (typeof error === "object" && error !== null && "message" in error) {
    return `${error.message}`;
  } else {
    return `${error}`
  }
}

// 벡엔드에 응답 오류
export function genResBKWithError(e:unknown): any {
  if (e instanceof ApiError) {
    return {
      head: { code: e.code, message: ERR_CODE_9999 },
      body: {}
    };
  } else {
    return {
      head: { code: ERR_CODE_9999, message: getErrorMessage(e) },
      body: {}
    };
  }
}

// Error를 응답 JSON으로 반환한다.
export function genResWithError(e:unknown): RestApiResponseError {
  if (e instanceof ApiError) {
    return {
      success: false,
      error: { code: e.code || ERR_CODE_9999, msg: e.message }
    };
  } else {
    return {
      success: false,
      error: { code: ERR_CODE_9999, msg: getErrorMessage(e) }
    };
  }
}

// Error 코드를 입력하여 응답 JSON을 반환한다.
type LocalType = "ko" | "en" | "zh";
export function genResWithCode(code:string, locale:LocalType = "ko", data : any = {}): RestApiResponse<any> {
  return {
    success: false,
    error: { code, msg: `${ERR_MSG[code][locale]}`},
    data,
  }
}

// 백엔드에 보낼 응답
export function genResBKWithCode(code:string, locale:LocalType = "ko", data : any = {}): any {
  return {
    head : { code, message: `${ERR_MSG[code][locale]}` },
    body : data,
  };
}

// Error 코드 및 메시지 입력
export function genResWithCodeMsg(code:string, msg:string): RestApiResponse<any> {
  return {
    success: false,
    error: { code, msg: msg },
  };
}

// Error 코드 및 메시지 입력
export function genResBKWithCodeMsg(code:string, message:string): any {
  return {
    head : { code, message },
    body : {},
  };
}

// 정상 응답  JSON을 반환한다.
export function genResWithOK<T>(data?:T): RestApiResponse<T> {
  return<{
    success: true;
    data: T;
  }> {
    success: true,
    data: data
  }
}

// Error 코드 및 메시지 입력
export function genResBKWithOK(body: any): any {
  return {
    head : { code : "0000", message : "complete" },
    body,
  };
}

// 에러메시지를 가져온다.
export function getErrMsg(code: string, locale: LocalType = "ko"): string {
  return `${ERR_MSG[code][locale]}`
}

// convert system file into blob
export function fileToBlob(path:string, {bufferSize=64*1024}={}) {
	return new Promise<Blob>((resolve, reject) => {
		const stream = fs.createReadStream(path, {highWaterMark:bufferSize});

		var blob = new Blob([]);

		stream.on('data', (buffer:Blob) => {
			blob = new Blob([blob, buffer]);
		});
		stream.on('close', () => {
			resolve(blob);
		});
	});
}

// convert base64 to blob
export const b64toBlob = (b64Data:string, contentType:string='', sliceSize:number=512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

/**
 * 커멘드 실행 할경우
 * @param cmd 커멘드
 * @returns 
 */
export async function runCmd(cmd: string, isLog: boolean = false): Promise<{ success: boolean }> {
  return new Promise<{ success: boolean }>((resolve) => {
    const childPrcess = spawn("sh", ["-c", cmd]);
    childPrcess.stdout.on("data", (data: any) => {
      if (isLog) console.log(data);
    });
    childPrcess.stderr.on("data", (data: any) => {
      if (isLog) console.error(data);
    });
    childPrcess.on("exit", (code: number) => {
      if (code == 0) {
        // 성공
        resolve({ success: true });
      } else {
        // 실패\        resolve({ success: false });
      }
    });
  });
}

/**
 * 로그를 예쁘게 찍게하기 위해서
 */
export function printPrettyLog(title:string, subTitle:string, data:any) {
  const LINE_WIDTH = 80;
  const LINE_STR = "-";

  let head = ` [${title}] `;
  let bottom = "";
  let idx = 0;
  while (head.length < LINE_WIDTH) {
    if (idx % 2 === 0) {
      head = `${LINE_STR}${head}`;
    } else {
      head += LINE_STR;
    }
    idx++;
  }
  while (bottom.length < LINE_WIDTH) bottom += LINE_STR;
  
  console.log(head);
  console.log(` >> ${subTitle}: `, data);
  console.log(bottom);
}

export function getUploadPath() {
  return runtimeEnv.UPLOAD_TEST && runtimeEnv.UPLOAD_TEST === "true" ? `${process.cwd()}/public/uploads` : runtimeEnv.UPLOAD_PATH;
}


export function getDreamAvatarImagePath() {
  return runtimeEnv.UPLOAD_TEST && runtimeEnv.UPLOAD_TEST === "true"
    // ? `${process.cwd()}/public/test`
    ? `${process.env.HOME}${runtimeEnv.DREAMAVATAR_IMG_PATH}`
    : runtimeEnv.DREAMAVATAR_IMG_PATH as string;
}

export function getCustomAvatarVideoPath() {
  return runtimeEnv.UPLOAD_TEST && runtimeEnv.UPLOAD_TEST === "true"
    ? `${process.env.HOME}${runtimeEnv.CUSTOMAVATAR_VIDEO_PATH}`
    : runtimeEnv.CUSTOMAVATAR_VIDEO_PATH as string;
}

export function getCacheUploadPath() {
  return runtimeEnv.UPLOAD_TEST === "true" ? `${process.cwd()}/public/cache` : runtimeEnv.CACHE_UPLOAD_PATH;
}

export function getNASPath() {
  return runtimeEnv.LOCAL_EXPORT && runtimeEnv.LOCAL_EXPORT === "true" ? `${process.cwd()}` : `${runtimeEnv.AI_NAS_PATH}/gpu_studio`;
}

export function getMaskingPath() {
  return runtimeEnv.LOCAL_EXPORT && runtimeEnv.LOCAL_EXPORT === "true" ? `${process.cwd()}/openshotAssets` : runtimeEnv.MASK_VIDEO_PATH;
}

export function getWebdataPath() {
  return runtimeEnv.LOCAL_EXPORT && runtimeEnv.LOCAL_EXPORT === "true" ? `${process.cwd()}/public` : runtimeEnv.WEBDATA_PATH;
}

export function getCookie(name: string, cookieString: string) {
  const cookies = cookieString.split('; ');
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
          return decodeURIComponent(cookieValue);
      }
  }
  return null;
}