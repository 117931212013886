import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RestApiResponse } from "@/types/common.api";

type VideoDownloadJob = {
  jobId: string;
  videoTitle: string;
  publicUrl: string;
  originalFilename: string;
};

type VideoDownloadResult = {
  jobId: string;
  videoTitle: string;
  thumbnailUrl: string;
  /**
   * units: seconds
   */
  duration: number;
  filepath: string;
  publicUrl: string;
};

export const creatorChallengeApi = createApi({
  reducerPath: "creatorChallenge",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/creator-challenge",
  }),
  tagTypes: ["Challenge", "Vote"],
  endpoints: (build) => ({
    getJob: build.query({
      query: (jobId: string) => `/jobs/${jobId}`,
      transformResponse: (res: RestApiResponse<VideoDownloadJob>) => {
        if (!res.success || !res.data) {
          throw new Error("Failed to fetch video download job");
        }

        return res.data;
      },
    }),
    requestDownload: build.mutation<
      VideoDownloadResult,
      { url: string; title: string }
    >({
      query: ({ url, title }) => ({
        url: "/download",
        method: "POST",
        body: {
          url,
          title,
        },
      }),
      transformResponse: (res: RestApiResponse<VideoDownloadResult>) => {
        if (!res.success || !res.data) {
          throw new Error("Failed to download video");
        }

        return res.data;
      },
    }),
    postVideo: build.mutation<VideoDownloadResult, { url: string }>({
      query: ({ url }) => ({
        url: "/video",
        method: "POST",
        body: {
          url,
        },
      }),
      transformResponse: (res: RestApiResponse<VideoDownloadResult>) => {
        if (!res.success || !res.data) {
          throw new Error("Failed to download video");
        }

        return res.data;
      },
    }),
    getComment: build.query<any, { projectId: string | undefined }>({
      query: ({ projectId }) => ({
        url: `${projectId}/comment`,
        method: "GET",
      }),
      providesTags: ["Challenge"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    createComment: build.mutation({
      query: (body) => ({
        url: `${body.projectId}/comment`,
        method: "POST",
        body,
      }),
    }),
    getVotes: build.query<any, { projectId: string | undefined; ip: string }>({
      query: ({ projectId, ip }) => ({
        url: `${projectId}/votes?ip=${ip}`,
        method: "GET",
      }),
      providesTags: ["Vote"],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
    postVote: build.mutation({
      query: (body) => ({
        url: `${body.projectId}/votes`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Vote"],
    }),
    deleteVote: build.mutation({
      query: (body) => ({
        url: `${body.projectId}/votes?ip=${body.ip}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Vote"],
    }),
  }),
});
