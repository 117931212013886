import { RestApiResponse } from "@/types/common.api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const creativeTemplatesApi = createApi({
  reducerPath: "creativeTemplatesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin/",
  }),
  tagTypes: ["CreativeTemplates"],
  endpoints: (builder) => ({
    getCreativeTemplates: builder.query<any, any>({
      query: (params) => `creativeTemplates?${new URLSearchParams(params)}`,
      providesTags: [{ type: "CreativeTemplates" }],
    }),
    postCreativeTemplates: builder.mutation<
      RestApiResponse<any>,
      { data: object }
    >({
      query: (data) => ({
        url: "creativeTemplates",
        method: "POST",
        body: { data },
      }),
      invalidatesTags: [{ type: "CreativeTemplates" }],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success) {
          return { success: false, message: res?.data?.message };
        }
        return { success: true };
      },
    }),
    deleteCreativeTemplates: builder.mutation<
      RestApiResponse<any>,
      { deleteId: string }
    >({
      query: ({ deleteId }) => ({
        url: `creativeTemplates?deleteId=${deleteId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CreativeTemplates" }],
      transformResponse: async (res: RestApiResponse<any>) => {
        if (!res.success || !res.data) {
          return [];
        }
        return res.data;
      },
    }),
  }),
});

export const {
  useGetCreativeTemplatesQuery,
  usePostCreativeTemplatesMutation,
  useDeleteCreativeTemplatesMutation,
} = creativeTemplatesApi;
