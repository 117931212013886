import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const synthesisModelApi = createApi({
  reducerPath: "synthesisModelApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/admin/analysis",
  }),
  tagTypes: ["synthesisModel"],
  endpoints: (builder) => ({
    getSynthesisModel: builder.query<any, any>({
      query: (params) => `model?${new URLSearchParams(params)}`,
    }),
  }),
});

export const { useGetSynthesisModelQuery } = synthesisModelApi;
