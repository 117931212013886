/* eslint-disable import/prefer-default-export */
import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import backendApi from "./api/backendApi";
import systemReducer from "@/redux/stores/systemStore";
import mainMenuReducer from "@/redux/stores/mainMenuStore";
import dashboardApi from "@/redux/api/dashboardApi";
import projectReducer from "@/redux/stores/projectStore";
import adminMenuReducer from "@/redux/stores/adminMenuStore";
import { usersApi } from "./api/admin/usersApi";
import { modelsApi } from "./api/admin/modelsApi";
import { projectsApi } from "./api/admin/projectsApi";
import { templatesApi } from "./api/admin/templatesApi";
import { npsApi } from "./api/admin/npsApi";
import { cubeApi } from "./api/admin/cubeApi";
import { creditApi } from "./api/admin/creditApi";
import projectMiddleware from "@/redux/middleware/projectMiddleware";
import { creativeTemplatesApi } from "./api/admin/creativeTemplatesApi";
import { periodApi } from "./api/admin/periodApi";
import { synthesisModelApi } from "./api/admin/synthesisModelApi";
import dashboardReducer from "./stores/dashboardStore";
import projectApi from "./api/projectApi";
import objectApi from "@/redux/api/objectApi";
// import { runtimeEnv } from "@/lib/runtime-utils";
import { exportApi } from "./api/admin/exportApi";
import { modelApi } from "./api/modelApi";
import commonApi from "./api/commonApi";
import userApi from "./api/userApi";
import { automationApi } from "./api/automationApi";
import { paymentApi } from "./api/paymentApi";
import { paymentsApi } from "./api/admin/paymentsApi";
import { myavatarApi } from "./api/myavatarApi";
import { videomessagesApi } from "./api/videoMessagesApi";
// import startTemplates from "@/pages/api/dashboard/startTemplates";
import { unsubscribeApi } from "./api/admin/unsubscribe";
import { statisticsApi } from "./api/admin/statisticsApi";
import workspaceApi from "./api/workspaceApi";
import { creatorChallengeApi } from "./api/creatorChallengeApi";
import { videoDownloaderApi } from "./api/tools/videoDownloaderApi";
import { videoConverterApi } from "./api/tools/videoConverterApi";
import { audioConverterApi } from "./api/tools/audioConverterApi";
import { aiScriptGeneratorApi } from "./api/tools/aiScriptGeneratorApi";
import { aiImageGeneratorApi } from "./api/tools/aiImageGeneratorApi";
import { aiVideoGeneratorApi } from "./api/tools/aiVideoGeneratorApi";

export const store = () => {
  const innerStore = configureStore({
    reducer: {
      systemReducer,
      projectReducer,
      mainMenuReducer,
      adminMenuReducer,
      [usersApi.reducerPath]: usersApi.reducer,
      [projectsApi.reducerPath]: projectsApi.reducer,
      [modelsApi.reducerPath]: modelsApi.reducer,
      [dashboardApi.reducerPath]: dashboardApi.reducer,
      [automationApi.reducerPath]: automationApi.reducer,
      [backendApi.reducerPath]: backendApi.reducer,
      [templatesApi.reducerPath]: templatesApi.reducer,
      [npsApi.reducerPath]: npsApi.reducer,
      [paymentsApi.reducerPath]: paymentsApi.reducer,
      [cubeApi.reducerPath]: cubeApi.reducer,
      [creditApi.reducerPath]: creditApi.reducer,
      [periodApi.reducerPath]: periodApi.reducer,
      [creativeTemplatesApi.reducerPath]: creativeTemplatesApi.reducer,
      [synthesisModelApi.reducerPath]: synthesisModelApi.reducer,
      [modelApi.reducerPath]: modelApi.reducer,
      dashboardReducer,
      [projectApi.reducerPath]: projectApi.reducer,
      [objectApi.reducerPath]: objectApi.reducer,
      [exportApi.reducerPath]: exportApi.reducer,
      [commonApi.reducerPath]: commonApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [paymentApi.reducerPath]: paymentApi.reducer,
      [myavatarApi.reducerPath]: myavatarApi.reducer,
      [videomessagesApi.reducerPath]: videomessagesApi.reducer,
      [unsubscribeApi.reducerPath] : unsubscribeApi.reducer,
      [statisticsApi.reducerPath] : statisticsApi.reducer,
      [workspaceApi.reducerPath] : workspaceApi.reducer,
      [creatorChallengeApi.reducerPath] : creatorChallengeApi.reducer,
      [videoDownloaderApi.reducerPath]: videoDownloaderApi.reducer,
      [videoConverterApi.reducerPath]: videoConverterApi.reducer,
      [audioConverterApi.reducerPath]: audioConverterApi.reducer,
      [aiScriptGeneratorApi.reducerPath]: aiScriptGeneratorApi.reducer,
      [aiImageGeneratorApi.reducerPath]: aiImageGeneratorApi.reducer,
      [aiVideoGeneratorApi.reducerPath]: aiVideoGeneratorApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        projectApi.middleware,
        projectsApi.middleware,
        dashboardApi.middleware,
        automationApi.middleware,
        backendApi.middleware,
        usersApi.middleware,
        modelsApi.middleware,
        templatesApi.middleware,
        npsApi.middleware,
        paymentsApi.middleware,
        paymentApi.middleware,
        cubeApi.middleware,
        creditApi.middleware,
        creativeTemplatesApi.middleware,
        periodApi.middleware,
        synthesisModelApi.middleware,
        objectApi.middleware,
        exportApi.middleware,
        modelApi.middleware,
        commonApi.middleware,
        userApi.middleware,
        myavatarApi.middleware,
        videomessagesApi.middleware,
        unsubscribeApi.middleware,
        statisticsApi.middleware,
        workspaceApi.middleware,
        creatorChallengeApi.middleware,
        videoDownloaderApi.middleware,
        videoConverterApi.middleware,
        audioConverterApi.middleware,
        aiScriptGeneratorApi.middleware,
        aiImageGeneratorApi.middleware,
        aiVideoGeneratorApi.middleware,
        /**
         * 이 미들웨어가 가장 아래에 있어야 함\
         * 새로운 미들웨어를 추가할 때 이 위에 추가할 것
         */
        projectMiddleware,
      ]),
    devTools:
      typeof window !== "undefined" &&
      (window.location.host.startsWith("localhost") ||
        window.location.host.startsWith("dev"))
        ? {
            actionsDenylist: [
              "project/setCanvas",
              "project/setSubCanvas",
              "project/objectMouseOver",
              "project/objectMouseOut",
            ],
          }
        : false,
  });

  setupListeners(innerStore.dispatch);
  return innerStore;
};

export type AppStore = ReturnType<typeof store>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const wrapper = createWrapper<AppStore>(store);

export const getSystemState = (state: RootState) => state.systemReducer;
export const getProjectState = (state: RootState) => state.projectReducer;
export const getMainMenuState = (state: RootState) => state.mainMenuReducer;
export const getDashBoardState = (state: RootState) => state.dashboardReducer;
