"use client";

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
      :root {


      html,
      body {
        padding: 0;
        margin: 0;
        max-width: 100vw;
        overflow-x: hidden;
      }
      *[data-flex] {
        flex: 1;
      }
      .ant-tour{
        width: 300px !important;
      }
      .ant-tour-content{
    width: 300px !important;
    line-height: 1 !important;
  }
    }
    `;
